import * as React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useThunkDispatch, useGettext, maybeSelectLoggedInUser, selectIsImpersonating, Authentication } from 'data-store';
import { Button, Card, Tx, Dropdown, Icon, Menu, IMenuItem, Tooltip } from 'ui-components';

import UserRoleLabel from '@/Pages/Customers/Users/Components/UserRoleLabel';

export default function UserDropdown(props: { className?: string; userNameInDropdown?: boolean }) {
    const dispatch = useThunkDispatch();
    const navigate = useNavigate();
    const isImpersonating = useSelector(selectIsImpersonating);
    const user = useSelector(maybeSelectLoggedInUser);
    const dropdown = React.createRef<Dropdown>();
    const { gettext } = useGettext();
    const { className, userNameInDropdown } = props;

    if (!user) {
        return null;
    }

    const menuItems = [
        user.role === 'platformAdministrator'
            ? {
                  label: gettext('Webhooks'),
                  value: 'webhooks',
                  action: () => {
                      navigate('/webhooks', { replace: true });
                  },
              }
            : false,
        {
            label: gettext('Invoices'),
            value: 'invoices',
            action: () => {
                navigate('/invoices', { replace: true });
            },
        },
        {
            label: gettext('Subscribed notifications'),
            value: 'subscribed-notifications',
            action: () => {
                navigate('/subscribed-notifications', { replace: true });
            },
        },
        {
            label: gettext('Sign out'),
            value: 'sign-out',
            action: () => {
                navigate('/', { replace: true });
                dispatch(Authentication.SignOut());
            },
        },
        isImpersonating
            ? {
                  label: gettext('End impersonation'),
                  value: 'end-impersonation',
                  action: () => {
                      navigate('/', { replace: true });
                      setTimeout(() => {
                          dispatch(Authentication.EndImpersonation());
                      }, 100);
                  },
              }
            : false,
    ].filter(Boolean) as IMenuItem[];

    const userName =
        isImpersonating && user.impersonator ? (
            <Tx sx={{ color: '--content-error' }}>
                <Tooltip
                    text={
                        <>
                            {user.impersonator.username}&nbsp;
                            {gettext('impersonating')}&nbsp;
                            {user.username}&nbsp;
                            {gettext('with role')}&nbsp;
                            <UserRoleLabel
                                showOnlyText={true}
                                role={user.role}
                            />
                        </>
                    }
                    position="left"
                >
                    {user.username}&nbsp;({gettext('impersonated')})
                </Tooltip>
            </Tx>
        ) : (
            <span>{user.username}</span>
        );

    if (userNameInDropdown) {
        menuItems.unshift({
            label: userName,
            value: 'username',
        });
    }

    return (
        <Dropdown
            ref={dropdown}
            className={className}
            anchorRight={true}
            inheritDropdownWidthFromHeader={false}
            headerRenderer={() => (
                <Button
                    variant="secondary"
                    variantSize="xs"
                    endIcon={<Icon type="action_user" />}
                    data-test-id="header-user-menu"
                >
                    {!userNameInDropdown ? userName : null}
                </Button>
            )}
            body={
                <Card
                    hSpacing="none"
                    vSpacing="none"
                    elevated={true}
                    className="mt-7"
                >
                    <Menu
                        items={menuItems as Menu['props']['items']}
                        onItemClick={() => {
                            if (dropdown.current) {
                                dropdown.current.close(true);
                            }
                        }}
                    />
                </Card>
            }
        />
    );
}
