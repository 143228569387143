import * as React from 'react';

import { Route, isPlatformAdministrator, CmsItem } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/cms/items',
        main: () => import('./CmsItemsOverview'),
        isAuthorized: isPlatformAdministrator,
        preloadData: CmsItem.overviewState.actions.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/cms/items/new',
        main: () => import('./CreateCmsItem'),
        isAuthorized: isPlatformAdministrator,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
    {
        path: '/cms/items/edit/:cmsItemId',
        main: () => import('./UpdateCmsItem'),
        isAuthorized: isPlatformAdministrator,
        preloadData: CmsItem.updateState.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
];

export default routes;
