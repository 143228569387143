import * as React from 'react';

import { Button, Icon, color } from 'ui-components';

import useVideoCallTrigger from './useVideoCallTrigger';

import styles from './VideoCall.scss';

export function VideoCallTrigger({ className, onClick }: { className?: string; onClick?: () => void }) {
    const trigger = useVideoCallTrigger();

    if (!trigger.isEnabled) {
        return null;
    }

    return (
        <div
            className={`${className} DropdownHeader`}
            ref={element => trigger.context.setTriggerElement(element || undefined)}
        >
            <Button
                variant="secondary"
                variantSize="xs"
                onClick={event => {
                    trigger.handleClickInsidePanel(event);
                    trigger.context.setPanelIsOpened(!trigger.context.panelIsOpened);
                    if (onClick) {
                        onClick();
                    }
                }}
                startIcon={
                    <span className={styles.VideoCallTriggerIconWrap}>
                        <Icon
                            type="action_phone"
                            color={color.Black}
                        />
                        <span
                            className={`${styles.RegistrationStatus} ${trigger.context.registrationStatus === 'registered' ? styles.Registered : styles.Registering}`}
                        />
                    </span>
                }
            />
        </div>
    );
}
