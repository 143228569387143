import * as React from 'react';

import { Route, isSuperUser, isCompanyManager, isBranchManager, isSignedIn, ProductWall } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/presentations/product-walls',
        main: () => import('./ProductWallsOverview'),
        isAuthorized: [isSuperUser, isCompanyManager, isBranchManager],
        preloadData: ProductWall.overviewState.actions.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/presentations/product-walls/new',
        main: () => import('./CreateProductWall'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloading: () => <FullPage bodyType="form" />,
    },
    {
        path: '/presentations/product-walls/:productWallId',
        main: () => import('./UpdateProductWall'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: ProductWall.updateState.load,
        preloading: () => <FullPage bodyType="form" />,
    },
    {
        path: '/presentations/product-walls/:productWallId/:step',
        main: () => import('./UpdateProductWall'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: ProductWall.updateState.load,
        preloading: () => <FullPage bodyType="form" />,
    },
    {
        path: '/preview/product-wall/:productWallId',
        main: () => import('./PreviewProductWall'),
        preloadData: ProductWall.previewState.actions.load,
        isAuthorized: isSignedIn,
        isBrowsershot: true,
    },
];

export default routes;
