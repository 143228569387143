import * as React from 'react';

import { Route, isBranchManager, isCompanyManager, isSuperUser, Integration } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/integrations',
        main: () => import('./IntegrationsOverview'),
        isAuthorized: [isSuperUser, isCompanyManager, isBranchManager],
        preloadData: Integration.overviewState.actions.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/integrations/:configuredIntegrationId',
        main: () => import('./IntegrationDetail'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: Integration.detailsState.actions.load,
        preloading: () => <FullPage bodyType="tabs-and-boxes" />,
    },
    {
        path: '/integrations/add',
        main: () => import('./AssignIntegration'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloading: () => <FullPage bodyType="form" />,
    },
    {
        path: '/integrations/edit/:configuredIntegrationId',
        main: () => import('./UpdateIntegration'),
        preloadData: Integration.updateState.actions.load,
        isAuthorized: [isCompanyManager, isBranchManager],
        preloading: () => <FullPage bodyType="form" />,
    },
];

export default routes;
