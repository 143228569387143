import * as React from 'react';

import { Card } from '@/card';
import { Portal } from '@/portal';

import styles from './Modal.scss';

export default function Modal({
    onClose,
    children,
    className,
    style,
    onMouseDown,
}: {
    onClose?: () => void;
    children?: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
    onMouseDown?: (event: React.MouseEvent) => void;
}) {
    React.useEffect(() => {
        const isOverflowing = document.documentElement.scrollHeight > document.documentElement.clientHeight;

        if (!isOverflowing) {
            return;
        }

        const documentWidth = document.documentElement.clientWidth;
        const scrollbarWidth = window.innerWidth - documentWidth;

        const originalOverflow = document.documentElement.style.overflow;
        document.documentElement.style.overflow = 'hidden';
        const originalPadding = document.body.style.paddingRight;
        document.body.style.paddingRight = scrollbarWidth + 'px';

        return () => {
            document.documentElement.style.overflow = originalOverflow;
            document.body.style.paddingRight = originalPadding;
        };
    }, []);

    const handleClose = (event: React.MouseEvent) => {
        event.preventDefault();
        if (onClose) {
            onClose();
        }
    };

    return (
        <Portal aboveSidebar>
            <div
                className={`${styles.Modal} ${className || ''}`}
                onClick={handleClose}
                onMouseDown={onMouseDown}
            >
                <Card
                    className={styles.ModalWrap}
                    style={style}
                    elevated={true}
                    onClick={event => {
                        event.stopPropagation();
                        event.nativeEvent.stopImmediatePropagation();
                    }}
                >
                    {children}
                </Card>
            </div>
        </Portal>
    );
}
