import * as React from 'react';

import { Route, isCompanyManager, isBranchManager, WebshopShippingMethod, Integration } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/webshops/shipping-methods',
        main: () => import('./WebshopShippingMethodsOverview'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopShippingMethod.overviewState.actions.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/webshops/shipping-methods/new/:shippingMethodType',
        main: () => import('./CreateShippingMethod'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: Integration.configuredIntegrationsTypeState.loadConfiguredIntegrationsCountByType,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
    {
        path: '/webshops/shipping-methods/edit/:shippingMethodType',
        main: () => import('./UpdateShippingMethod'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopShippingMethod.updateState.actions.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
];

export default routes;
