import { Domain } from 'api';
import { ISearchProvider } from 'utils';

import { appointmentTypeApi } from '@/api';

interface AppointmentSearchProviderProviderItem {
    value: Domain.AppointmentTypes['appointmentTypeId'];
    label: React.ReactNode;
}
export type AppointmentTypeItem = {
    value: Domain.AppointmentTypes['appointmentTypeId'];
    label: React.ReactNode;
};
type LabelRenderer = (value: Domain.AppointmentTypes) => React.ReactNode;

export class AppointmentTypesSearchProvider implements ISearchProvider<AppointmentSearchProviderProviderItem> {
    private hasMoreResults = false;
    private labelRenderer: LabelRenderer;

    private searchCache: {
        [key: string]: Promise<Domain.AppointmentTypesPage>;
    } = {};

    private byValueCache: {
        [key: string]: Promise<Domain.AppointmentTypes>;
    } = {};

    resetCache() {
        this.searchCache = {};
        this.byValueCache = {};
    }

    reset() {
        this.resetCache();
    }

    getHasMoreResults() {
        return this.hasMoreResults;
    }
    setLabelRenderer(labelRenderer: LabelRenderer) {
        this.labelRenderer = labelRenderer;
    }

    async search(query: string) {
        if (!this.searchCache[query]) {
            this.searchCache[query] = appointmentTypeApi.GetAppointmentTypes(
                { page: 1, size: 20 },
                { field: 'type', direction: 'ascending' },
                query,
            );

            this.searchCache[query].then(cachedAppointments => {
                cachedAppointments.items.forEach(appointmentType => {
                    if (!this.byValueCache[appointmentType.appointmentTypeId]) {
                        this.byValueCache[appointmentType.appointmentTypeId] = Promise.resolve(appointmentType);
                    }
                });
            });
        }
        const appointmentsPage = await this.searchCache[query];

        return appointmentsPage.items.map(this.mapItem);
    }

    async loadMoreResults() {
        return [];
    }
    async byValue(value: string) {
        if (!value) {
            return;
        }
        if (!this.byValueCache[value]) {
            this.byValueCache[value] = appointmentTypeApi
                .GetAppointmentTypes({ page: 1, size: 10 }, { field: 'type', direction: 'ascending' }, value)
                .then(page => {
                    const item = page.items.find(item => item.appointmentTypeId === value);

                    if (!item) {
                        throw new Error('Active ingredient not found');
                    }

                    return item;
                });
        }
        const item = await this.byValueCache[value];
        return {
            value: item.appointmentTypeId,
            label: this.labelRenderer(item),
        };
    }

    async byValues(values: string[]) {
        return await Promise.all(values.filter(Boolean).map(value => this.byValue(value) as Promise<AppointmentTypeItem>));
    }
    private mapItem = (item: Domain.AppointmentTypes): AppointmentTypeItem => {
        return {
            ...item,
            value: item.appointmentTypeId,
            label: this.labelRenderer(item),
        };
    };
}
