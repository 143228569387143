import * as React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useThunkDispatch, useGettext, PlatformNotification, maybeSelectLoggedInUser } from 'data-store';
import { HeroBanner as MakeHeroBanner } from 'hero-banner';
import { Button, Card, Dropdown, Icon, Tx, Pill } from 'ui-components';

import NotificationRow from './NotificationRow';

import styles from './NotificationsDropdown.scss';

export default function NotificationsDropdown(props: { className?: string }) {
    const dispatch = useThunkDispatch();
    const navigate = useNavigate();
    const { gettext } = useGettext();
    const dropdown = React.createRef<Dropdown>();
    const [activeFilter, setActiveFilter] = React.useState<'unread' | 'all'>('unread');

    const user = useSelector(maybeSelectLoggedInUser);
    const unreadCount = useSelector(PlatformNotification.selectors.selectUserUnreadNotificationsCount);
    const notifications = useSelector(PlatformNotification.selectors.selectAllUserNotifications);
    const [isLoading, setIsLoading] = React.useState(false);
    const filteredNotifications = React.useMemo(() => {
        return activeFilter === 'unread' ? notifications.items.filter(n => !n.readOn) : notifications.items;
    }, [notifications, activeFilter]);

    if (!user) {
        return null;
    }

    return (
        <Dropdown
            ref={dropdown}
            className={props.className}
            anchorRight={true}
            headerRenderer={() => (
                <Button
                    variant="secondary"
                    variantSize="xs"
                    startIcon={<Icon type={unreadCount > 0 ? 'action_bell_on' : 'action_bell'} />}
                />
            )}
            onOpen={async () => {
                setIsLoading(true);
                setActiveFilter('unread');
                await Promise.all([
                    dispatch(PlatformNotification.actions.LoadUserAllNotifications()),
                    dispatch(PlatformNotification.actions.LoadUserNotificationsCount()),
                ]);
                setIsLoading(false);
            }}
            body={
                <Card
                    elevated={true}
                    className={`${styles.NotificationsDropdown} mt-7`}
                >
                    {isLoading ? (
                        <div className={styles.NotificationRow}>
                            <div
                                ref={element => {
                                    if (element) {
                                        MakeHeroBanner(element, {
                                            originalWidth: 218,
                                            originalHeight: 134,
                                            layers: [
                                                {
                                                    src: '/static/images/homeDemo/wind.json',
                                                    width: 180,
                                                    height: 100,
                                                    left: 0,
                                                    top: 0,
                                                    autoplay: true,
                                                    loop: true,
                                                },
                                            ],
                                        });
                                    }
                                }}
                            />
                        </div>
                    ) : null}

                    {!isLoading && (
                        <div className={styles.NotificationFilters}>
                            <Pill
                                size="md"
                                radius="rounded"
                                color={activeFilter === 'unread' ? 'tertiary' : 'plain'}
                                className="mr-12"
                                onClick={() => setActiveFilter('unread')}
                            >
                                {gettext('Unread')}
                            </Pill>
                            <Pill
                                size="md"
                                radius="rounded"
                                color={activeFilter === 'all' ? 'tertiary' : 'plain'}
                                onClick={() => setActiveFilter('all')}
                            >
                                {gettext('All')}
                            </Pill>
                            <Tx
                                as="a"
                                href="#"
                                level="body-md"
                                className={styles.SeeAllAction}
                                onClick={async e => {
                                    e.preventDefault();

                                    if (dropdown.current) {
                                        await dropdown.current.close(true);
                                    }

                                    navigate('/dashboard/all-notifications');
                                }}
                            >
                                {gettext('See all')}
                            </Tx>
                        </div>
                    )}

                    {filteredNotifications.length === 0 && !isLoading ? (
                        <div className={`${styles.NotificationRow} ${styles.NoNotificationsRow}`}>
                            <div
                                ref={element => {
                                    if (element) {
                                        MakeHeroBanner(element, {
                                            originalWidth: 218,
                                            originalHeight: 134,
                                            layers: [
                                                {
                                                    src: '/static/images/homeDemo/sun.json',
                                                    width: 218,
                                                    height: 134,
                                                    left: 50,
                                                    top: 0,
                                                    autoplay: true,
                                                    loop: true,
                                                },
                                            ],
                                        });
                                    }
                                }}
                            />

                            {activeFilter === 'unread' ? gettext('You have no unread notifications') : gettext('You have no notifications')}
                        </div>
                    ) : null}
                    {filteredNotifications.length > 0 && !isLoading ? (
                        <div className={styles.NotificationsList}>
                            {filteredNotifications.map(notification => (
                                <NotificationRow
                                    key={notification.notificationId}
                                    notification={notification}
                                    dropdown={dropdown}
                                />
                            ))}
                        </div>
                    ) : null}
                </Card>
            }
        />
    );
}
