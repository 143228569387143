import moment, { Moment } from 'moment-timezone';

export const formatApiDate = ({
    date,
    format,
    formatToApi,
}: {
    date: string | Moment | null;
    format: string;
    formatToApi?: string;
}): string => {
    if (typeof date === 'string') {
        return moment(date, format).format(formatToApi || 'YYYY-MM-DDTHH:mm:ssZ');
    } else if (moment.isMoment(date)) {
        return date.format(formatToApi || 'YYYY-MM-DDTHH:mm:ssZ');
    }
    throw new Error('Invalid date type');
};
