import * as React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { config } from 'config';
import { useThunkDispatch, I18n } from 'data-store';
import { Container, Row, Col } from 'ui-components';
import { VideoCallTrigger } from 'video-call';

import HelpDropdown from './HelpDropdown';
import LanguageSwitcher from './LanguageSwitcher';
import NotificationsDropdown from './PlatformNotifications/NotificationsDropdown';
import SteamerTrigger from './SteamerTrigger';
import TimezoneDropdown from './TimezoneDropdown';
import UserDropdown from './UserDropdown';

import styles from './HeaderMenu.scss';

export default function HeaderMenu(props: { breadcrumbs?: React.ReactNode }) {
    const navigate = useNavigate();
    const dispatch = useThunkDispatch();
    const selectedLocale = useSelector(I18n.selectCurrentLocale);
    return (
        <>
            <Container
                fullWidth={true}
                className={styles.HeaderMenu}
            >
                <Row
                    vAlign="center"
                    style={{
                        height: '100%',
                    }}
                >
                    <Col hAlign="start">{props.breadcrumbs ? props.breadcrumbs : null}</Col>

                    <Col
                        hAlign="end"
                        className="hidden-sm"
                    >
                        <UserDropdown />
                    </Col>
                    <Col
                        hAlign="end"
                        className="hidden-sm"
                    >
                        <HelpDropdown />
                    </Col>
                    <Col
                        hAlign="end"
                        className="hidden-sm"
                    >
                        <TimezoneDropdown />
                    </Col>
                    <Col
                        hAlign="end"
                        className="hidden-sm"
                    >
                        <LanguageSwitcher
                            className="mr--4"
                            selectedLocale={selectedLocale}
                            onPick={locale => {
                                dispatch(I18n.SetLocale(locale));
                            }}
                        />
                    </Col>

                    <Col
                        hAlign="end"
                        className="hidden-sm"
                    >
                        <NotificationsDropdown />
                    </Col>

                    <Col
                        hAlign="end"
                        className="hidden-sm"
                    >
                        <SteamerTrigger />
                    </Col>

                    <Col
                        hAlign="end"
                        className="hidden-sm"
                    >
                        <VideoCallTrigger
                            onClick={() =>
                                navigate(
                                    config.featureFlags.enableNightHatchV2
                                        ? '/vending-machines/night-hatch-v2'
                                        : '/vending-machines/night-hatch',
                                )
                            }
                        />
                    </Col>
                </Row>
            </Container>

            <div className={`${styles.HeaderMenu} ${styles.HeaderMenuMobile} hidden-md`}>
                <UserDropdown userNameInDropdown={true} />
                <HelpDropdown />
                <LanguageSwitcher
                    selectedLocale={selectedLocale}
                    onPick={locale => {
                        dispatch(I18n.SetLocale(locale));
                    }}
                />
            </div>
        </>
    );
}
