import * as React from 'react';

import { Route, anyAuthorization, I18n, Dashboard, URLParams, ThunkAction, URLQuery, selectIsLoggedIn, isSignedIn } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const preloadData =
    (params: { urlParams: URLParams; urlQuery: URLQuery }): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const isLoggedIn = selectIsLoggedIn(state);

        if (!isLoggedIn) {
            await dispatch(I18n.initLocale(params));
        } else {
            await Promise.all([dispatch(I18n.initLocale(params)), dispatch(Dashboard.loadDashboard())]);
        }
    };

const routes: Route[] = [
    {
        path: '/',
        main: () => import('./Landing'),
        isAuthorized: anyAuthorization,
        preloadData,
        preloading: () => (
            <FullPage
                bodyType="data-table"
                hideDataTableToggleColumns={true}
                hideMainSearch={true}
            />
        ),
    },
    {
        path: '/contact',
        main: () => import('./Contact'),
        isAuthorized: isSignedIn,
    },
    {
        path: '/request-new-password',
        main: () => import('./RequestNewPassword'),
        isAuthorized: anyAuthorization,
    },
    {
        path: '/password-reset/:passwordResetRequestId',
        main: () => import('./SetNewPassword'),
        isAuthorized: anyAuthorization,
    },
    {
        path: '/accept-group-invitation/:sharingGroupInvitationCode',
        main: () => import('./AcceptSharingGroupInvitation'),
        isAuthorized: anyAuthorization,
    },
    {
        path: '/appointment/cancel',
        main: () => import('./CancelAppointment'),
        preloadData,
        isAuthorized: anyAuthorization,
    },
];

export default routes;
