import * as React from 'react';

import { Route, isPlatformAdministrator, CmsTag } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/cms/tags',
        main: () => import('./CmsTagsOverview'),
        isAuthorized: isPlatformAdministrator,
        preloadData: CmsTag.overviewState.actions.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/cms/tags/new',
        main: () => import('./CreateCmsTag'),
        isAuthorized: isPlatformAdministrator,
        preloadData: CmsTag.createState.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
    {
        path: '/cms/tags/edit/:cmsTagId',
        main: () => import('./UpdateCmsTag'),
        isAuthorized: isPlatformAdministrator,
        preloadData: CmsTag.updateState.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
];

export default routes;
