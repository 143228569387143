import { Domain } from 'api';
import { ISearchProvider } from 'utils';

import { deviceApi } from '@/api';

interface DeviceSearchProviderItem {
    value: Domain.Device['deviceId'];
    label: string;
    groupName: Domain.Device['groupName'];
    screenResolution: Domain.Device['screenResolution'];
    branchId: Domain.Device['branchId'];
    device?: Domain.Device;
}

interface Filters {
    companyId?: string;
    branchId?: string;
    type?: Domain.Device['type'];
}

export class DeviceSearchProvider implements ISearchProvider<DeviceSearchProviderItem> {
    private ownership: Domain.Ownership;
    private filters: Filters;
    private collectOnly = false;
    private hasMoreResults = false;

    private baseByValueCache: {
        [key: string]: Domain.Device;
    } = {};

    private searchCache: {
        [key: string]: Promise<Domain.DevicesPage>;
    } = {};

    private byValueCache: {
        [key: string]: Promise<Domain.Device>;
    } = {};

    private allDevicesOption = false;
    private allDevicesOptionLabel = '';

    resetCache() {
        this.searchCache = {};
    }

    reset() {
        this.resetCache();
    }

    setBaseByValueCache(cache: Domain.Device[]) {
        this.baseByValueCache = {};

        for (const item of cache) {
            if (item) {
                this.baseByValueCache[item.deviceId] = item;
            }
        }
    }

    setOwnership(ownership: Domain.Ownership) {
        if (JSON.stringify(ownership) === JSON.stringify(this.ownership)) {
            return;
        }
        this.ownership = ownership;
        this.resetCache();
    }

    setCollectOnly(collectOnly: boolean) {
        if (collectOnly === this.collectOnly) {
            return;
        }
        this.collectOnly = collectOnly;
        this.resetCache();
    }

    setFilters(filters: Filters) {
        if (JSON.stringify(filters) === JSON.stringify(this.filters)) {
            return;
        }
        this.filters = filters;
        this.resetCache();
    }

    setAllDevicesOption(visibility: boolean) {
        this.allDevicesOption = visibility;
    }

    setAllDevicesOptionLabel(label: string) {
        this.allDevicesOptionLabel = label;
    }

    async search(query: string) {
        const cacheKey = query + '-' + JSON.stringify(this.filters);
        if (!this.searchCache[cacheKey]) {
            this.searchCache[cacheKey] = deviceApi.GetDevices(
                this.ownership,
                { page: 1, size: 20 },
                { field: 'name', direction: 'ascending' },
                query,
                this.filters,
            );

            this.searchCache[cacheKey].then(cachedDevicesPage => {
                cachedDevicesPage.items.forEach(device => {
                    if (!this.byValueCache[device.deviceId]) {
                        this.byValueCache[device.deviceId] = Promise.resolve(device);
                    }
                });
            });
        }
        const devicesPage = await this.searchCache[cacheKey];

        if (this.allDevicesOption) {
            return [
                {
                    label: this.allDevicesOptionLabel,
                    value: 'all',
                    groupName: 'all',
                    screenResolution: '1080x1920' as Domain.DeviceScreenResolution,
                    branchId: 'all',
                },
                ...devicesPage.items.map(this.mapDevice),
            ];
        }

        return devicesPage.items
            .filter(item => {
                if (this.collectOnly && !item.connectedToLockers) {
                    return false;
                }
                return item;
            })
            .map(this.mapDevice);
    }

    // we do not support loading more results
    async loadMoreResults() {
        return [];
    }

    // We do not have this implemented yet in this search provider
    getHasMoreResults() {
        return this.hasMoreResults;
    }

    async byValue(value: string) {
        if (!value) {
            return;
        }

        if (value === 'all') {
            return {
                label: this.allDevicesOptionLabel,
                value: 'all',
                groupName: 'all',
                screenResolution: '1080x1920' as Domain.DeviceScreenResolution,
                branchId: 'all',
            };
        }

        if (this.baseByValueCache[value]) {
            return this.mapDevice(this.baseByValueCache[value]);
        }

        if (!this.byValueCache[value]) {
            this.byValueCache[value] = deviceApi.GetDeviceDetails(value);
        }

        try {
            const device = await this.byValueCache[value];
            return this.mapDevice(device);
        } catch (e) {
            console.error(e);
            return;
        }
    }

    async byValues(values: string[]) {
        return await Promise.all(values.filter(Boolean).map(value => this.byValue(value) as Promise<DeviceSearchProviderItem>));
    }

    private mapDevice = (device: Domain.Device): DeviceSearchProviderItem => {
        return {
            value: device.deviceId,
            label: device.name + (device.label ? ' ' + device.label : ''),
            groupName: device.groupName,
            screenResolution: device.screenResolution,
            branchId: device.branchId,
            device,
        };
    };
}
