import * as React from 'react';
import { useSelector } from 'react-redux';

import { Domain } from 'api';
import { config } from 'config';
import { useLocalize, I18n } from 'data-store';
import { localizeWithFallback } from 'util-components';

import styles from '@/Pages/Cms/Pages/CmsPage.scss';

const TableItem = ({
    block,
    locale,
}: {
    block: {
        blockId: string;
        type: 'table';
        content: Domain.LocalizedValue<Domain.CmsItemBlockTable>;
    };
    locale: Domain.Locale;
}) => {
    const localize = useLocalize();
    const content = localizeWithFallback({
        value: block.content as unknown as Domain.LocalizedValue,
        localize,
        toLocale: locale,
        fallback: true,
        addFallbackLangPrefix: false,
        preferMainValueStructure: true,
    }) as unknown as Domain.CmsItemBlockTable;

    return (
        <table className={styles.CmsPageTable}>
            <tbody>
                {content
                    ? content.rows.map((row, rowIndex) => {
                          if (rowIndex >= content.numRows) {
                              return null;
                          }

                          return (
                              <tr key={rowIndex}>
                                  {row.map((col, colIndex) => {
                                      if (colIndex >= content.numCols) {
                                          return null;
                                      }

                                      return (
                                          <td
                                              key={colIndex}
                                              style={{
                                                  width: 100 / content.numCols + '%',
                                              }}
                                          >
                                              <div
                                                  dangerouslySetInnerHTML={{ __html: col.content }}
                                                  className={styles.CmsPageRichText}
                                              />
                                          </td>
                                      );
                                  })}
                              </tr>
                          );
                      })
                    : null}
            </tbody>
        </table>
    );
};

export default function LocalizedCmsBlockRenderer(props: { blocks: Domain.LocalizedCmsItemBlock[] }) {
    const localize = useLocalize();
    const locale = useSelector(I18n.selectCurrentLocale);
    return (
        <div className={styles.CmsContentWrapper}>
            {props.blocks.map(block => {
                return (
                    <div
                        key={block.blockId}
                        className={styles.CmsPageBlock}
                    >
                        {block.type === 'html' ? (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html:
                                        (localizeWithFallback({
                                            value: block.content,
                                            localize,
                                            toLocale: locale,
                                            fallback: true,
                                            addFallbackLangPrefix: false,
                                            plaintext: true,
                                        }) as string) || '',
                                }}
                                className={styles.CmsPageRichText}
                            />
                        ) : block.type === 'image' ? (
                            <img
                                src={`${config.apiBaseUrl}/media-item/${block.content?.mediaItemId}/preview`}
                                alt=""
                                className={styles.CmsPageImage}
                            />
                        ) : block.type === 'table' ? (
                            <TableItem
                                block={block}
                                locale={locale}
                            />
                        ) : null}
                    </div>
                );
            })}
        </div>
    );
}
