import { Domain } from 'api';
import { ISearchProvider } from 'utils';

import { branchApi } from '@/api';

interface BranchSearchProviderItem {
    value: Domain.Branch['branchId'];
    label: Domain.Branch['branchName'];
}

export class BranchSearchProvider implements ISearchProvider<BranchSearchProviderItem> {
    private ownership: Domain.Ownership;
    private companyIdFilter: string | undefined;
    private query: string;
    private perPage = 10;
    private pageSize = 10;
    private hasMoreResults = false;

    private searchCache: {
        [key: string]: Promise<Domain.BranchesPage>;
    } = {};

    private byValueCache: {
        [key: string]: Promise<Domain.Branch>;
    } = {};

    resetCache() {
        this.searchCache = {};
        this.byValueCache = {};
    }

    reset() {
        this.companyIdFilter = undefined;
        this.resetCache();
    }

    setOwnership(ownership: Domain.Ownership) {
        if (JSON.stringify(ownership) === JSON.stringify(this.ownership)) {
            return;
        }
        this.ownership = ownership;
        this.resetCache();
    }

    setCompanyIdFilter(companyId: string | undefined) {
        if (this.companyIdFilter === companyId) {
            return;
        }
        this.companyIdFilter = companyId;
        this.resetCache();
    }

    getCompanyIdFilter() {
        return this.companyIdFilter;
    }

    getHasMoreResults() {
        return this.hasMoreResults;
    }

    async search(query: string) {
        if (this.query !== query) {
            this.pageSize = this.perPage;
            this.hasMoreResults = true;
        }

        const cacheKey = query + '-' + this.companyIdFilter + '-' + this.pageSize;

        if (!this.searchCache[cacheKey]) {
            this.searchCache[cacheKey] = branchApi.GetBranches(
                this.ownership,
                { page: 1, size: this.pageSize },
                { field: 'branchName', direction: 'ascending' },
                query,
                this.companyIdFilter,
            );

            this.searchCache[cacheKey].then(cachedBranchesPage => {
                cachedBranchesPage.items.forEach(branch => {
                    if (!this.byValueCache[branch.branchId]) {
                        this.byValueCache[branch.branchId] = Promise.resolve(branch);
                    }
                });
                this.hasMoreResults = cachedBranchesPage.total > cachedBranchesPage.items.length;
            });
        }
        const branchesPage = await this.searchCache[cacheKey];

        return branchesPage.items.map(branch => {
            return {
                value: branch.branchId,
                label: branch.companyName + ' > ' + branch.branchName,
                country: branch.country,
                locales: branch.locales,
            };
        });
    }

    async loadMoreResults() {
        if (this.hasMoreResults) {
            this.pageSize += this.perPage;
        }

        return await this.search(this.query);
    }

    async byValue(value: string) {
        if (!value) {
            return;
        }

        if (!this.byValueCache[value]) {
            this.byValueCache[value] = branchApi.GetBranchDetails(value);
        }

        const branch = await this.byValueCache[value];
        return {
            value: branch.branchId,
            label: branch.companyName + ' > ' + branch.branchName,
            country: branch.country,
            locales: branch.locales,
        };
    }

    // we do not support multi-selects
    // @ts-ignore
    async byValues() {
        return [];
    }
}
