import * as React from 'react';

import { Route, isSuperUser, isCompanyManager, anyAuthorization, User } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/subscribed-notifications',
        main: () => import('./SubscribedNotifications'),
        isAuthorized: anyAuthorization,
        preloadData: User.subscribedNotificationsState.actions.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/customers/users',
        main: () => import('./UsersOverview'),
        isAuthorized: isSuperUser,
        preloadData: User.overviewState.actions.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/company/users',
        main: () => import('./UsersOverview'),
        isAuthorized: isCompanyManager,
        preloadData: User.overviewState.actions.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/customers/users/new',
        main: () => import('./CreateUser'),
        isAuthorized: [isSuperUser, isCompanyManager],
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
    {
        path: '/customers/users/edit/:userId',
        main: () => import('./UpdateUser'),
        isAuthorized: [isSuperUser, isCompanyManager],
        preloadData: User.updateState.actions.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
    {
        path: '/customers/users/:userId',
        main: () => import('./ViewUser'),
        isAuthorized: [isSuperUser, isCompanyManager],
        preloadData: User.detailsState.actions.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
];

export default routes;
