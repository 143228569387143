import * as React from 'react';

import { Button, Icon } from 'ui-components';

import { useVideoCallContext } from './VideoCallContext';

export function VideoCallVideo({
    style,
    visible,
    setVisible,
    pipOnly = false,
}: {
    style?: React.CSSProperties;
    visible: boolean;
    setVisible?: (visible: boolean) => void;
    pipOnly?: boolean;
}) {
    const { setPIPVideoElement, callStatus, setPIPOnly } = useVideoCallContext();

    React.useEffect(() => {
        setPIPOnly(pipOnly);

        return () => {
            if (pipOnly) {
                setPIPOnly(false);
            }
        };
    }, []);

    return callStatus === 'in-progress' ? (
        <div style={style}>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                }}
            >
                {visible ? (
                    <video
                        ref={element => setPIPVideoElement(element || undefined)}
                        autoPlay
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                    ></video>
                ) : null}

                {setVisible !== undefined ? (
                    <Button
                        onClick={() => setVisible(!visible)}
                        startIcon={<Icon type={!visible ? 'action_video_off_outline' : 'action_video'} />}
                        variant={!visible ? 'danger' : 'secondary'}
                        variantSize="m"
                        rounded
                        style={{
                            position: 'absolute',
                            top: '8px',
                            left: '8px',
                        }}
                    />
                ) : null}
            </div>
        </div>
    ) : null;
}
