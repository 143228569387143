import { Domain } from 'api';

export function selectedCategoriesTreeBuilder(
    root: Domain.AvailableProductCategory,
    selection: Domain.ProductSelection,
): Domain.AvailableProductCategory | undefined {
    let allSelectionCategoryIds: Domain.AvailableProductCategory['categoryId'][] = [];

    for (const rootCategory of root.subCategories) {
        if (rootCategory.subCategories.length === 0) {
            allSelectionCategoryIds.push(rootCategory.categoryId);
        } else {
            for (const subCategory of rootCategory.subCategories) {
                if (subCategory.subCategories.length === 0) {
                    allSelectionCategoryIds.push(subCategory.categoryId);
                } else {
                    for (const subSubCategory of subCategory.subCategories) {
                        if (subSubCategory.subCategories.length === 0) {
                            allSelectionCategoryIds.push(subSubCategory.categoryId);
                        } else {
                            for (const subSubSubCategory of subSubCategory.subCategories) {
                                allSelectionCategoryIds.push(subSubSubCategory.categoryId);
                            }
                        }
                    }
                }
            }
        }
    }

    for (const categoryIdToExclude of selection.categoryIdsToBeExcluded) {
        allSelectionCategoryIds = allSelectionCategoryIds.filter(categoryId => categoryId !== categoryIdToExclude);
    }

    return buildBranch(root, allSelectionCategoryIds);
}

function buildBranch(
    category: Domain.AvailableProductCategory,
    allSelectionCategoryIds: Domain.AvailableProductCategory['categoryId'][],
): Domain.AvailableProductCategory | undefined {
    if (isSelectedOrHasSelectedChildren(category, allSelectionCategoryIds)) {
        return {
            ...category,
            subCategories: category.subCategories
                .map(subcategory => buildBranch(subcategory, allSelectionCategoryIds))
                .filter(Boolean) as Domain.AvailableProductCategory[],
        };
    }
}

function isSelectedOrHasSelectedChildren(
    category: Domain.AvailableProductCategory,
    allSelectionCategoryIds: Domain.AvailableProductCategory['categoryId'][],
): boolean {
    const anySelectedSubcategories = !!category.subCategories.find(subcategory =>
        isSelectedOrHasSelectedChildren(subcategory, allSelectionCategoryIds),
    );

    return (
        allSelectionCategoryIds.includes(category.categoryId) ||
        (category.parentCategoryId && allSelectionCategoryIds.includes(category.parentCategoryId) && !anySelectedSubcategories) ||
        anySelectedSubcategories
    );
}
