import * as React from 'react';

import { Route, isCompanyManager, isBranchManager, Device } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/vending-machines/home-screen-products',
        main: () => import('./UpdateVendingMachineHomeScreenProducts'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: Device.vendingMachineUpdateHomeScreenProductsState.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
];

export default routes;
