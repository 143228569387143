import { Route } from 'data-store';

import countriesAndLanguagesRoutes from './CountriesAndLanguages/routes';
import productSourcesRoutes from './ProductSources/routes';
import qualityLabelsRoutes from './QualityLabels/routes';
import searchActionsExportRoutes from './SearchActionsExport/routes';
import searchSynonymsRoutes from './SearchSynonyms/routes';
import translationRoutes from './Translations/routes';

const routes: Route[] = [
    ...countriesAndLanguagesRoutes,
    ...productSourcesRoutes,
    ...translationRoutes,
    ...qualityLabelsRoutes,
    ...searchSynonymsRoutes,
    ...searchActionsExportRoutes,
];

export default routes;
