import * as React from 'react';

import { Dropdown } from '@/core';

export default function ActionWithDialog(props: {
    children: React.ReactNode;
    dialogRenderer: (close: () => void) => React.ReactNode;
    onClick?: (e: React.MouseEvent) => void;
    disabled?: boolean;
    anchorTop?: boolean;
    anchorRight?: boolean;
    inheritDropdownWidthFromHeader?: boolean;
    className?: string;
    bodyClassName?: string;
    doNotCloseOnClickOutside?: boolean;
}) {
    const dropdown = React.useRef<Dropdown>();

    const close = () => {
        if (!dropdown.current) {
            return;
        }

        dropdown.current.close();
    };

    return (
        <Dropdown
            ref={dropdown as any}
            headerRenderer={() => props.children}
            headerArrow={false}
            body={props.dialogRenderer(close)}
            doNotCloseOnHeaderClick={true}
            onClick={props.onClick}
            disabled={props.disabled}
            anchorTop={props.anchorTop}
            anchorRight={props.anchorRight}
            inheritDropdownWidthFromHeader={props.inheritDropdownWidthFromHeader}
            className={props.className}
            bodyClassName={props.bodyClassName}
            doNotCloseOnClickOutside={props.doNotCloseOnClickOutside}
        />
    );
}
