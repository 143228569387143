import * as React from 'react';

import { Domain } from 'api';
import { Dropdown } from 'ui-components';

import Notification from '@/Pages/Dashboard/PlatformNotifications/Components/Notification';

import styles from './NotificationsDropdown.scss';

export default function NotificationRow(props: { notification: Domain.Notification; dropdown: React.RefObject<Dropdown> }) {
    return (
        <Notification
            notification={props.notification}
            className={`${styles.NotificationRow} ${styles.NotificationItem}`}
            onClick={async () => {
                if (props.dropdown.current) {
                    await props.dropdown.current.close(true);
                }
            }}
            showNotificationStatus
        />
    );
}
