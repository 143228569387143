import * as React from 'react';

import { Route, isCompanyManager, isBranchManager, CustomProduct } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/products/custom-products',
        main: () => import('./CustomProductsOverview'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: CustomProduct.overviewState.actions.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/products/custom-products/new',
        main: () => import('./CreateCustomProduct'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: CustomProduct.createState.load,
        preloading: () => (
            <FullPage
                hideMainActions={true}
                bodyType="form"
            />
        ),
    },
    {
        path: '/products/custom-products/:customProductId',
        main: () => import('./CustomProductDetails'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: CustomProduct.detailsState.actions.load,
        preloading: () => (
            <FullPage
                hideMainActions={true}
                bodyType="tabs-and-boxes"
            />
        ),
    },
    {
        path: '/products/custom-products/:customProductId/:tab',
        main: () => import('./CustomProductDetails'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: CustomProduct.detailsState.actions.load,
        preloading: () => (
            <FullPage
                hideMainActions={true}
                bodyType="tabs-and-boxes"
            />
        ),
    },
    {
        path: '/products/custom-products/edit/:customProductId',
        main: () => import('./UpdateCustomProduct'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: CustomProduct.updateState.actions.load,
        preloading: () => <FullPage bodyType="form" />,
    },
];

export default routes;
