import * as React from 'react';

import { Route, isSignedIn, DeviceOrderExport } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/vending-machines/order-export',
        main: () => import('./VendingMachineOrderExportOverview'),
        isAuthorized: isSignedIn,
        preloadData: DeviceOrderExport.overviewState.actions.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/vending-machines/order-export/new',
        main: () => import('./StartVendingMachineOrderExport'),
        isAuthorized: isSignedIn,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
];

export default routes;
