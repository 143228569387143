import * as React from 'react';

import { Route, isCompanyManager, isBranchManager, WebshopCartRestriction } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/webshops/cart-restrictions',
        main: () => import('./UpdateWebshopCartRestrictions'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopCartRestriction.updateState.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
];

export default routes;
