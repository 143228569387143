import * as React from 'react';

import { Route, anyAuthorization, isSuperUser, PlatformNotification } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/dashboard/all-notifications',
        main: () => import('./PlatformNotificationsOverview'),
        isAuthorized: anyAuthorization,
        preloadData: PlatformNotification.overviewState.actions.load,
        preloading: () => (
            <FullPage
                bodyType="data-table"
                hideDataTableToggleColumns={true}
                hideMainSearch={true}
            />
        ),
    },
    {
        path: '/dashboard/all-platform-notifications',
        main: () => import('./AdminPlatformNotificationsOverview'),
        isAuthorized: isSuperUser,
        preloadData: PlatformNotification.adminOverviewState.actions.load,
        preloading: () => (
            <FullPage
                bodyType="data-table"
                hideMainSearch={true}
            />
        ),
    },
];

export default routes;
