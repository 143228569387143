import * as React from 'react';

import { Route, isSuperUser, isCompanyManager, isBranchManager, Device } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/customers/devices',
        main: () => import('./DevicesOverview'),
        isAuthorized: isSuperUser,
        preloadData: Device.overviewState.actions.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/company/devices',
        main: () => import('./DevicesOverview'),
        isAuthorized: isCompanyManager,
        preloadData: Device.overviewState.actions.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/branch/devices',
        main: () => import('./DevicesOverview'),
        isAuthorized: isBranchManager,
        preloadData: Device.overviewState.actions.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/customers/devices/new',
        main: () => import('./CreateDevice'),
        isAuthorized: isSuperUser,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
    {
        path: '/customers/devices/edit/:deviceId',
        main: () => import('./UpdateDevice'),
        isAuthorized: isSuperUser,
        preloadData: Device.updateState.actions.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
    {
        path: '/customers/devices/:deviceId',
        main: () => import('./ViewDevice'),
        isAuthorized: [isSuperUser, isCompanyManager, isBranchManager],
        preloadData: Device.detailsState.actions.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
];

export default routes;
