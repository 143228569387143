import * as React from 'react';
import { useDropzone } from 'react-dropzone';

import styles from './FileInput.scss';

export interface FileInputProps {
    name: string;
    disabled?: boolean;
    error?: boolean;
    errorMessage?: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
    accept?: string;
    multiple?: boolean;
    children: React.ReactNode;
    onChange?: (selectedFiles: File[]) => void;
}

// TODO: make sure this still works after react-hook-form is removed
export default function FileInput(props: FileInputProps) {
    const onDrop = React.useCallback(
        (droppedFiles: File[]) => {
            if (props.onChange) {
                props.onChange(droppedFiles);
            }
        },
        [props.onChange],
    );

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: props.accept,
    });

    const inputProps = getInputProps();
    if (!props.multiple) {
        inputProps.multiple = false;
    }

    return (
        <div
            {...getRootProps()}
            className={styles.FileInput + ' ' + (props.className || '')}
            style={props.style}
        >
            <input
                name={props.name}
                accept={props.accept}
                disabled={props.disabled}
                {...inputProps}
            />
            {props.children}
        </div>
    );
}

export const toBase64 = (file: File, removePrefix = false) =>
    new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            if (!reader.result) {
                reject();
            } else {
                let data = reader.result.toString();
                if (removePrefix) {
                    data = data.replace(/^data:.*;base64,/, '');
                }

                resolve(data);
            }
        };
        reader.onerror = error => reject(error);
    });

export const toBase64WithMetadata = (file: File) =>
    new Promise<{
        data: string;
        metaData: string;
    }>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            if (!reader.result) {
                reject();
            } else {
                const base64 = reader.result.toString();
                const data = base64.replace(/^data:.*;base64,/, '');
                const metaData = base64.slice(0, base64.length - data.length - 1);

                resolve({
                    data,
                    metaData,
                });
            }
        };
        reader.onerror = error => reject(error);
    });
