import * as React from 'react';

import AppCore from '@/Infrastructure/App/AppCore';

interface IProps {
    children?: React.ReactNode;
    renderErrorPageContents?: (error: Error) => React.ReactNode | null;
}

interface IState {
    error?: Error;
}

export default class ErrorBoundary extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    static getDerivedStateFromError(error: Error) {
        return {
            error,
        };
    }

    componentDidMount() {
        window.addEventListener('unhandledrejection', this.onUnhandledRejection);
    }

    componentWillUnmount() {
        window.removeEventListener('unhandledrejection', this.onUnhandledRejection);
    }

    onUnhandledRejection = (event: PromiseRejectionEvent) => {
        event.promise.catch(error => {
            this.setState(ErrorBoundary.getDerivedStateFromError(error));
        });
    };

    // @ts-ignore
    componentDidCatch() {
        // do nothing
    }

    render() {
        if (this.state.error) {
            if (this.props.renderErrorPageContents) {
                return <AppCore>{this.props.renderErrorPageContents(this.state.error)}</AppCore>;
            } else {
                // eslint-disable-next-line local-rules/force-use-gettext
                return <span>An error occurred</span>;
            }
        }

        return this.props.children;
    }
}
