import { Domain } from 'api';
import { ISearchProvider } from 'utils';

import { webshopApi } from '@/api';

interface WebshopSearchProviderItem {
    value: Domain.Webshop['webshopId'];
    label: Domain.Webshop['name'];
    locales: Domain.Locale[];
}

export class WebshopSearchProvider implements ISearchProvider<WebshopSearchProviderItem> {
    private ownership: Domain.Ownership;
    private hasMoreResults = false;

    private baseByValueCache: {
        [key: string]: Domain.Webshop;
    } = {};

    private searchCache: {
        [key: string]: Promise<Domain.WebshopsPage>;
    } = {};

    private byValueCache: {
        [key: string]: Promise<Domain.Webshop>;
    } = {};

    private allWebshopsOption = false;
    private allWebshopsOptionLabel = '';

    resetCache() {
        this.searchCache = {};
        this.byValueCache = {};
    }

    reset() {
        this.resetCache();
    }

    setOwnership(ownership: Domain.Ownership) {
        this.ownership = ownership;
        this.resetCache();
    }

    setBaseByValueCache(cache: Domain.Webshop[]) {
        this.baseByValueCache = {};

        for (const item of cache) {
            this.baseByValueCache[item.webshopId] = item;
        }
    }

    setAllWebshopsOption(visibility: boolean) {
        this.allWebshopsOption = visibility;
        this.resetCache();
    }

    setAllWebshopsOptionLabel(label: string) {
        this.allWebshopsOptionLabel = label;
        this.resetCache();
    }

    async search(query: string) {
        if (!this.searchCache[query]) {
            this.searchCache[query] = webshopApi.GetWebshops(
                this.ownership,
                { page: 1, size: 10 },
                { field: 'name', direction: 'ascending' },
                query,
            );

            this.searchCache[query].then(cachedWebshopsPage => {
                cachedWebshopsPage.items.forEach(webshop => {
                    if (!this.byValueCache[webshop.webshopId]) {
                        this.byValueCache[webshop.webshopId] = Promise.resolve(webshop);
                    }
                });
            });
        }
        const webshopsPage = await this.searchCache[query];

        if (this.allWebshopsOption) {
            return [{ label: this.allWebshopsOptionLabel, value: 'all', locales: [] }, ...webshopsPage.items.map(this.mapWebshop)];
        }

        return webshopsPage.items.map(this.mapWebshop);
    }

    // we do not support loading more results
    async loadMoreResults() {
        return [];
    }

    // We do not have this implemented yet in this search provider
    getHasMoreResults() {
        return this.hasMoreResults;
    }

    async byValue(value: string) {
        if (!value) {
            return;
        }

        if (value === 'all') {
            return { label: this.allWebshopsOptionLabel, value: 'all', locales: [] };
        }
        if (this.baseByValueCache[value]) {
            return this.mapWebshop(this.baseByValueCache[value]);
        }

        if (!this.byValueCache[value]) {
            this.byValueCache[value] = webshopApi.GetWebshopDetails(value);
        }

        const webshop = await this.byValueCache[value];
        return this.mapWebshop(webshop);
    }

    async byValues(values: string[]) {
        return await Promise.all(values.filter(Boolean).map(value => this.byValue(value) as Promise<WebshopSearchProviderItem>));
    }

    private mapWebshop = (webshop: Domain.Webshop): WebshopSearchProviderItem => {
        return {
            value: webshop.webshopId,
            label: webshop.name,
            locales: webshop.locales,
        };
    };
}
