import * as React from 'react';

import { Route, isPlatformAdministrator, ProductSource } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/localisation/sources-and-settings',
        main: () => import('./ProductSourcesOverview'),
        isAuthorized: isPlatformAdministrator,
        preloadData: ProductSource.overviewState.actions.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="data-table"
            />
        ),
    },
    {
        path: '/localisation/sources-and-settings/:productSourceId',
        main: () => import('./ViewProductSource'),
        isAuthorized: isPlatformAdministrator,
        preloadData: ProductSource.detailsState.actions.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
];

export default routes;
