import * as React from 'react';

import { Domain } from 'api';
import { ISearchProvider } from 'utils';

import { webshopFaqApi } from '@/api';

interface WebshopFaqCategorySearchProviderItem {
    value: Domain.WebshopFaqCategory['faqCategoryId'];
    label: React.ReactNode;
}

type LabelRenderer = (faqCategory: Domain.WebshopFaqCategory) => React.ReactNode;

export class WebshopFaqCategorySearchProvider implements ISearchProvider<WebshopFaqCategorySearchProviderItem> {
    private webshopId: Domain.Webshop['webshopId'];
    private labelRenderer: LabelRenderer = faqCategory => faqCategory.faqCategoryId;
    private hasMoreResults = false;

    private baseByValueCache: {
        [key: string]: Domain.WebshopFaqCategory;
    } = {};

    private searchCache: {
        [key: string]: Promise<Domain.WebshopFaqCategoriesPage>;
    } = {};

    private byValueCache: {
        [key: string]: Promise<Domain.WebshopFaqCategory>;
    } = {};

    setWebshopId(webshopId: Domain.Webshop['webshopId']) {
        this.webshopId = webshopId;
        this.resetCache();
    }

    resetCache() {
        this.searchCache = {};
        this.byValueCache = {};
    }

    reset() {
        this.resetCache();
    }

    setLabelRenderer(labelRenderer: LabelRenderer) {
        this.labelRenderer = labelRenderer;
    }

    async search(query: string) {
        const cacheKey = query;

        if (!this.searchCache[cacheKey]) {
            this.searchCache[cacheKey] = webshopFaqApi.GetWebshopFaqCategories(
                { page: 1, size: 10 },
                { field: 'name', direction: 'ascending' },
                this.webshopId,
            );

            this.searchCache[cacheKey].then(cachedWebshopFaqCategoriesPage => {
                cachedWebshopFaqCategoriesPage.items.forEach(faqCategory => {
                    if (!this.byValueCache[faqCategory.faqCategoryId]) {
                        this.byValueCache[faqCategory.faqCategoryId] = Promise.resolve(faqCategory);
                    }
                });
            });
        }
        const webshopFaqCategoriesPage = await this.searchCache[cacheKey];

        return webshopFaqCategoriesPage.items.map(this.mapWebshopFaqCategory);
    }

    // we do not support loading more results
    async loadMoreResults() {
        return [];
    }

    // We do not have this implemented yet in this search provider
    getHasMoreResults() {
        return this.hasMoreResults;
    }

    async byValue(value: string) {
        if (!value) {
            return;
        }

        if (this.baseByValueCache[value]) {
            return this.mapWebshopFaqCategory(this.baseByValueCache[value]);
        }

        if (!this.byValueCache[value]) {
            this.byValueCache[value] = webshopFaqApi.GetWebshopFaqCategoryDetails(this.webshopId, value);
        }

        const faqCategory = await this.byValueCache[value];
        return this.mapWebshopFaqCategory(faqCategory);
    }

    async byValues(values: string[]) {
        return await Promise.all(values.filter(Boolean).map(value => this.byValue(value) as Promise<WebshopFaqCategorySearchProviderItem>));
    }

    private mapWebshopFaqCategory = (faqCategory: Domain.WebshopFaqCategory): WebshopFaqCategorySearchProviderItem => {
        return {
            value: faqCategory.faqCategoryId,
            label: this.labelRenderer(faqCategory),
            ...faqCategory,
        };
    };
}
