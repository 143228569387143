import * as lottie from 'lottie-web/build/player/lottie_light';
type LottiePlayer = typeof lottie.default;
const lottiePlayer = lottie as any as LottiePlayer;

import styles from './HeroBanner.scss';

type Layer = {
    src: string;
    width: number;
    height: number;
    left: number;
    top: number;
    scale?: number;
} & (
    | { static: true }
    | ({
          static?: never;
      } & (
          | {
                autoplay: true;
                loop?: boolean;
            }
          | {
                autoplay?: never;
                delayBefore?: number;
                delayAfter?: number;
            }
      ))
);

interface IProps {
    className?: string;
    layers: Layer[];
    originalWidth: number;
    originalHeight: number;
}

function HeroBanner(rootElement: HTMLElement | null, props: IProps) {
    if (!rootElement) {
        throw new DOMException('Root Element does not exist!');
    }

    const { className, originalWidth, originalHeight, layers } = props;

    rootElement.textContent = '';
    rootElement.classList.add(styles.HeroBanner);
    if (className) {
        rootElement.classList.add(className);
    }

    layers.forEach(layer => {
        let layerElement;

        if (!layer.static) {
            layerElement = document.createElement('div');
            layerElement.classList.add(styles.LottieLayer);
            layerElement.style.width = pxToPercent(layer.width, originalWidth);
            layerElement.style.height = pxToPercent(layer.height, originalHeight);
            layerElement.style.left = pxToPercent(layer.left, originalWidth);
            layerElement.style.top = pxToPercent(layer.top, originalHeight);

            animateLottieLayer(layerElement, layer);
        } else {
            layerElement = document.createElement('img');
            layerElement.alt = '';
            layerElement.src = layer.src;
        }

        rootElement.appendChild(layerElement);
    });
}

function pxToPercent(px: number, totalPx: number): string {
    return (px / totalPx) * 100 + '%';
}

function animateLottieLayer(layerElement: HTMLDivElement, layer: Layer) {
    if (layer.static) {
        return;
    }

    const animation = lottiePlayer.loadAnimation({
        name: layer.src,
        container: layerElement,
        renderer: 'svg',
        loop: (layer.autoplay && layer.loop) || false,
        autoplay: layer.autoplay || false,
        path: layer.src,
    });

    if (!layer.autoplay) {
        animation.addEventListener('data_ready', () => {
            if (layer.delayBefore) {
                setTimeout(() => {
                    animation.goToAndPlay(0);
                }, layer.delayBefore * 1000);
            } else {
                animation.goToAndPlay(0);
            }
        });

        animation.addEventListener('complete', () => {
            let delay = 0;

            if (layer.delayBefore) {
                delay += layer.delayBefore;
            }

            if (layer.delayAfter) {
                delay += layer.delayAfter;
            }

            setTimeout(() => {
                animation.goToAndPlay(0);
            }, delay * 1000);
        });
    }
}

export default HeroBanner;
