import * as React from 'react';

import { useGettext } from 'data-store';
import { Row, Col, LinkButton, Tx, Icon } from 'ui-components';

import styles from '../PublicAnnouncementsOverview.scss';

export default function ({ showAllButton }: { showAllButton?: boolean }) {
    const { gettext } = useGettext();
    return (
        <Row className={`${styles.PublicAnnouncementListHeader}`}>
            <Col>
                <Icon
                    type="action_megaphone"
                    iconSize="m"
                />
            </Col>
            <Col>
                <Tx
                    level="heading-4"
                    className="mb-0 ml-8"
                >
                    {gettext('Lochting News')}
                </Tx>
            </Col>
            {showAllButton && (
                <Col
                    hAlign="end"
                    className="ml-auto"
                >
                    <LinkButton
                        size="s"
                        variant="secondary"
                        to="/dashboard/lochting-news"
                        endIcon={<Icon type="action_arrow_right" />}
                    >
                        {gettext('See all')}
                    </LinkButton>
                </Col>
            )}
        </Row>
    );
}
