import * as React from 'react';

import color from '@/color';

import styles from './Preloader.scss';

export default function Preloader(props: {
    className?: string;
    width: number | string;
    height: number | string;
    varyWidth?: number;
    primary?: boolean;
}) {
    let width;
    if (typeof props.width === 'string') {
        width = props.width;
    } else if (!props.varyWidth) {
        width = props.width + 'px';
    } else {
        width = Math.floor(props.width + Math.random() * (props.varyWidth - props.width)) + 'px';
    }

    return (
        <div
            className={`${styles.Preloader} ${props.className} ${props.primary ? color.BG.Grey.Dark3 : color.BG.Grey.Light2}`}
            style={{
                width,
                height: typeof props.height === 'string' ? props.height : props.height + 'px',
            }}
        />
    );
}
