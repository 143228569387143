import * as React from 'react';

import { Route, isCompanyManager, isBranchManager, WebshopNewsletterSubscription } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/webshops/newsletter-subscriptions',
        main: () => import('./WebshopNewsletterSubscriptionOverview'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopNewsletterSubscription.overviewState.actions.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
];

export default routes;
