import { Domain } from 'api';
import { ISearchProvider } from 'utils';

import { resellerApi } from '@/api';

export interface ResellerSearchProviderItem {
    value: Domain.Reseller['resellerId'];
    label: Domain.Reseller['name'];
}

interface Filters {
    country?: Domain.Country;
}

export class ResellerSearchProvider implements ISearchProvider<ResellerSearchProviderItem> {
    private filters: Filters;
    private hasMoreResults = false;

    private searchCache: {
        [key: string]: Promise<Domain.ResellersPage>;
    } = {};

    private byValueCache: {
        [key: string]: Promise<Domain.Reseller>;
    } = {};

    resetCache() {
        this.searchCache = {};
        this.byValueCache = {};
    }

    reset() {
        this.resetCache();
    }

    setFilters(filters: Filters) {
        this.filters = filters;
        this.resetCache();
    }

    async search(query: string) {
        if (!this.searchCache[query]) {
            this.searchCache[query] = resellerApi.GetResellers(
                { page: 1, size: 10 },
                { field: 'name', direction: 'ascending' },
                query,
                this.filters,
            );

            this.searchCache[query].then(cachedResellersPage => {
                cachedResellersPage.items.forEach(reseller => {
                    if (!this.byValueCache[reseller.resellerId]) {
                        this.byValueCache[reseller.resellerId] = Promise.resolve(reseller);
                    }
                });
            });
        }
        const resellersPage = await this.searchCache[query];

        return resellersPage.items.map(this.mapItem);
    }

    // we do not support loading more results
    async loadMoreResults() {
        return [];
    }

    // We do not have this implemented yet in this search provider
    getHasMoreResults() {
        return this.hasMoreResults;
    }

    async byValue(value: string) {
        if (!value) {
            return;
        }

        if (!this.byValueCache[value]) {
            this.byValueCache[value] = resellerApi.GetResellerDetails(value);
        }

        const reseller = await this.byValueCache[value];
        return this.mapItem(reseller);
    }

    async byValues(values: string[]) {
        return await Promise.all(values.filter(Boolean).map(value => this.byValue(value) as Promise<ResellerSearchProviderItem>));
    }

    mapItem(reseller: Domain.Reseller) {
        return {
            value: reseller.resellerId,
            label: reseller.name,
            ...reseller,
        };
    }
}
