import * as React from 'react';

import { Route, isSuperUser, isCompanyManager, isBranchManager, WebshopPrescription } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/webshops/prescriptions',
        main: () => import('./WebshopPrescriptionsOverview'),
        isAuthorized: [isSuperUser, isCompanyManager, isBranchManager],
        preloadData: WebshopPrescription.overviewState.actions.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/webshops/prescriptions/:prescriptionId',
        main: () => import('./ViewPrescription'),
        isAuthorized: [isSuperUser, isCompanyManager, isBranchManager],
        preloadData: WebshopPrescription.detailsState.actions.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
];

export default routes;
