import * as React from 'react';

import { EditorState } from 'draft-js';

import { HTMLEditorValue } from 'utils';

import { Card } from '@/card';
import { Input } from '@/input';
import { Grid, Row, Col } from '@/layout';

import { HTMLEditor, HTMLEditorContext, HTMLEditorToolbar, HTMLEditorContextValue } from '.';

import styles from './HTMLEditor.scss';

export interface ItemTableCell {
    content: string;
}

export type ItemTableRow = ItemTableCell[];

export interface IHTMLEditorTableProps {
    gettext: (text: string) => string;
    onChange: (value: { numRows?: number; numCols?: number; rows: ItemTableRow[] }) => void;
    numRows: number;
    numCols: number;
    placeholder: string;
    rows: ItemTableRow[];
    disabled: boolean;
}

export const HTMLEditorTable = ({ gettext, numRows, numCols, rows, disabled, placeholder, onChange }: IHTMLEditorTableProps) => {
    const [focusedCell, setFocusedCell] = React.useState<
        | {
              row: number;
              col: number;
          }
        | undefined
    >(undefined);

    const [editorContextValue, setEditorContextValue] = React.useState<HTMLEditorContextValue>({
        editorState: EditorState.createEmpty(),
        editorValueSetter: () => undefined,
    });

    const onTableCellContentChange = (newValue: HTMLEditorValue, rowIndex: number, colIndex: number) => {
        const newRows = rows.map((searchedRow, searchedRowIndex) => {
            if (searchedRowIndex === rowIndex) {
                return searchedRow.map((searchedCol, searchedColIndex) => {
                    if (searchedColIndex === colIndex) {
                        return {
                            ...searchedCol,
                            content: newValue as any,
                        };
                    }
                    return searchedCol;
                });
            }
            return searchedRow;
        });
        onChange({
            rows: newRows,
        });
    };

    return (
        <Card
            elevated={true}
            className={styles.HTMLTableEditor}
        >
            <HTMLEditorContext.Provider
                value={{
                    ...editorContextValue,
                    set: setEditorContextValue,
                }}
            >
                <Grid gutter={16}>
                    <Row vAlign="end">
                        <Col span={2}>
                            <Input
                                label={gettext('Rows')}
                                type="number"
                                value={numRows.toString(10)}
                                onChange={newValue => {
                                    const newRows = [...rows];
                                    const columnsLength = newRows[0].length;
                                    const newRowsNumber = parseInt(newValue);
                                    if (newRowsNumber > newRows.length) {
                                        for (let i = newRows.length; i < newRowsNumber; i += 1) {
                                            const cols: ItemTableRow = [];
                                            for (let j = 0; j < columnsLength; j += 1) {
                                                cols.push({
                                                    content: HTMLEditorValue.createEmpty() as any,
                                                });
                                            }
                                            newRows.push(cols);
                                        }
                                    }
                                    onChange({
                                        numRows: newRowsNumber,
                                        rows: newRows,
                                    });
                                }}
                                min={1}
                                max={99}
                            />
                        </Col>

                        <Col span={2}>
                            <Input
                                label={gettext('Columns')}
                                type="number"
                                value={numCols.toString(10)}
                                onChange={newValue => {
                                    let newRows = [...rows];
                                    const columnsLength = newRows[0].length;
                                    const newColsNumber = parseInt(newValue);
                                    if (newColsNumber > columnsLength) {
                                        newRows = newRows.map(row => {
                                            const newRow = [...row];
                                            for (let j = columnsLength; j < newColsNumber; j += 1) {
                                                newRow.push({
                                                    content: HTMLEditorValue.createEmpty() as any,
                                                });
                                            }

                                            return newRow;
                                        });
                                    }
                                    onChange({
                                        numCols: newColsNumber,
                                        rows: newRows,
                                    });
                                }}
                                min={1}
                                max={99}
                            />
                        </Col>

                        <Col span={8}>
                            <div className="HTMLEditor">
                                <div className="EditorToolbar__x">
                                    <HTMLEditorToolbar
                                        gettext={gettext}
                                        refocus={() => undefined}
                                        handleChange={newValue => {
                                            if (!focusedCell) {
                                                return;
                                            }

                                            onTableCellContentChange(newValue, focusedCell.row, focusedCell.col);
                                        }}
                                        showColorControls={true}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col fullWidth={true}>
                            <table className={styles.CmsItemBlocksInputBlockTableEditorTable}>
                                <tbody>
                                    {rows.map((row, rowIndex) => {
                                        if (rowIndex >= numRows) {
                                            return null;
                                        }

                                        return (
                                            <tr key={rowIndex}>
                                                {row.map((col, colIndex) => {
                                                    if (colIndex >= numCols) {
                                                        return null;
                                                    }

                                                    return (
                                                        <td
                                                            key={colIndex}
                                                            style={{
                                                                width: 100 / numCols + '%',
                                                            }}
                                                        >
                                                            <HTMLEditor
                                                                gettext={gettext}
                                                                placeholder={placeholder}
                                                                disabled={disabled}
                                                                value={col.content as unknown as HTMLEditorValue}
                                                                onChange={newValue =>
                                                                    onTableCellContentChange(newValue, rowIndex, colIndex)
                                                                }
                                                                editorRef={editor => {
                                                                    if (editor) {
                                                                        (editor as any)._focus = () => {
                                                                            // disable autofocus
                                                                        };
                                                                    }
                                                                }}
                                                                hideBuiltInToolbar={true}
                                                                onClick={() => {
                                                                    console.log('qqq');
                                                                    setFocusedCell({
                                                                        row: rowIndex,
                                                                        col: colIndex,
                                                                    });
                                                                }}
                                                            />
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </Grid>
            </HTMLEditorContext.Provider>
        </Card>
    );
};
