import * as React from 'react';

import { Route, isSuperUser, WebshopLayout } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/webshops/available-color-themes',
        main: () => import('./WebshopLayoutColorThemesOverview'),
        isAuthorized: isSuperUser,
        preloadData: WebshopLayout.layoutState.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/webshops/available-color-themes/:themeId',
        main: () => import('./WebshopLayoutColorThemeDetails'),
        isAuthorized: isSuperUser,
        preloadData: WebshopLayout.layoutState.loadDetails,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/webshops/create-available-color-theme',
        main: () => import('./CreateColorTheme'),
        isAuthorized: isSuperUser,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
    {
        path: '/webshops/edit-available-color-theme/:themeId',
        main: () => import('./UpdateColorTheme'),
        isAuthorized: isSuperUser,
        preloadData: WebshopLayout.layoutState.loadDetails,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
];

export default routes;
