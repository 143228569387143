import * as React from 'react';
import { useSelector } from 'react-redux';

import { useThunkDispatch, Notifications as NotificationsDataStore } from 'data-store';
import { Notification, Portal } from 'ui-components';

import styles from './Notifications.scss';

export default function Notifications() {
    const notifications = useSelector(NotificationsDataStore.selectNotifications);

    return (
        <Portal aboveSidebar>
            {notifications.map(notification => {
                return (
                    <AppNotification
                        key={notification.notification.notificationId}
                        notificationId={notification.notification.notificationId}
                    />
                );
            })}
        </Portal>
    );
}

function AppNotification(props: { notificationId: string }) {
    const dispatch = useThunkDispatch();

    const finder = (searchedNotification: any) => searchedNotification.notification.notificationId === props.notificationId;

    const notifications = useSelector(NotificationsDataStore.selectNotifications);
    const notification = notifications.find(finder);
    const notificationIndex = notifications.findIndex(finder);

    if (!notification) {
        return null;
    }

    return (
        <Notification
            type={notification.notification.type}
            elevated={true}
            onClose={() => {
                dispatch(NotificationsDataStore.RemoveNotification(notification.notification.notificationId));
            }}
            className={styles.AppNotification + ' ' + notification.state + ' index-' + notificationIndex}
        >
            {notification.notification.message}
        </Notification>
    );
}
