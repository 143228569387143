import * as React from 'react';

import { Route, isCompanyManager, isBranchManager, WebshopAnnouncement } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/webshops/announcements',
        main: () => import('./WebshopAnnouncementsOverview'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopAnnouncement.overviewState.actions.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/webshops/announcements/:webshopId/new',
        main: () => import('./CreateWebshopAnnouncement'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopAnnouncement.createState.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
    {
        path: '/webshops/announcements/:webshopId/:announcementId',
        main: () => import('./UpdateWebshopAnnouncement'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopAnnouncement.updateState.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
];

export default routes;
