import { ThunkAction } from '@/action';
import { URLParams } from '@/routing';

import * as createShipmentState from './createShipmentState';
import * as detailsState from './detailsState';

export const load =
    (options: { urlParams: URLParams }): ThunkAction =>
    async dispatch => {
        await Promise.all([dispatch(createShipmentState.load(options)), dispatch(detailsState.actions.load(options))]);
    };
