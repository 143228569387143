import { Route } from 'data-store';

import backsideStockingRoutes from './BacksideStocking/routes';
import landingPagesRoutes from './LandingPages/routes';
import nightHatchV2Routes from './NightHatchV2/routes';
import overviewRoutes from './Overview/routes';
import pharmacistOnDutyRoutes from './PharmacistOnDutySchedule/routes';
import stockReplenishRoutes from './StockReplenish/routes';
import stockReplenishExportRoutes from './StockReplenishExport/routes';
import vendingMachineConfigurationRoutes from './VendingMachineConfiguration/routes';
import vendingMachineHomeScreenProductsRoutes from './VendingMachineHomeScreenProducts/routes';
import vendingMachineLayoutRoutes from './VendingMachineLayout/routes';
import vendingMachineNightHatchRoutes from './VendingMachineNightHatch/routes';
import vendingMachineOrderRoutes from './VendingMachineOrders/routes';
import vendingMachineOrderExportRoutes from './VendingMachineOrdersExport/routes';

const routes: Route[] = [
    ...overviewRoutes,
    ...vendingMachineOrderRoutes,
    ...vendingMachineLayoutRoutes,
    ...vendingMachineConfigurationRoutes,
    ...vendingMachineHomeScreenProductsRoutes,
    ...pharmacistOnDutyRoutes,
    ...vendingMachineNightHatchRoutes,
    ...nightHatchV2Routes,
    ...landingPagesRoutes,
    ...vendingMachineOrderExportRoutes,
    ...backsideStockingRoutes,
    ...stockReplenishRoutes,
    ...stockReplenishExportRoutes,
];

export default routes;
