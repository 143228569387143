import { Domain } from 'api';
import { ISearchProvider } from 'utils';

import { mediaItemApi } from '@/api';

export type MediaItemSearchProviderItem = Domain.MediaItem & {
    value: Domain.MediaItem['mediaItemId'];
    label: Domain.MediaItem['name'];
};

export class MediaItemSearchProvider implements ISearchProvider<MediaItemSearchProviderItem> {
    private ownership: Domain.Ownership;
    private type: Domain.MediaItemType | undefined;
    private query: string;
    private perPage = 10;
    private pageSize = 10;
    private hasMoreResults = false;
    private includeShared: 'true' | undefined;
    private includeOwnedByCompany: 'true' | undefined;
    private sorting: Domain.Sorting = { field: 'updatedAt', direction: 'descending' };

    private searchCache: {
        [key: string]: Promise<Domain.MediaItemsPage>;
    } = {};

    private byValueCache: {
        [key: string]: Promise<Domain.MediaItem>;
    } = {};

    resetCache() {
        this.searchCache = {};
        this.byValueCache = {};
    }

    reset() {
        this.resetCache();
    }
    setSorting(sorting: Domain.Sorting) {
        this.sorting = sorting;
    }
    setOwnership(ownership: Domain.Ownership) {
        if (JSON.stringify(ownership) === JSON.stringify(this.ownership)) {
            return;
        }
        this.ownership = ownership;
        this.resetCache();
    }

    setType(type: Domain.MediaItemType | undefined) {
        if (this.type === type) {
            return;
        }
        this.type = type;
        this.resetCache();
    }

    setIncludeShared(includeShared: 'true' | undefined) {
        if (this.includeShared === includeShared) {
            return;
        }
        this.includeShared = includeShared;
        this.resetCache();
    }

    setIncludeOwnedByCompany(includeOwnedByCompany: 'true' | undefined) {
        if (this.includeOwnedByCompany === includeOwnedByCompany) {
            return;
        }
        this.includeOwnedByCompany = includeOwnedByCompany;
        this.resetCache();
    }

    async search(query: string) {
        if (this.query !== query) {
            this.pageSize = this.perPage;
            this.hasMoreResults = true;
        }

        const cacheKey = query + '-' + this.includeShared + '-' + this.includeOwnedByCompany + '-' + this.pageSize;

        if (!this.searchCache[cacheKey]) {
            this.searchCache[cacheKey] = mediaItemApi.GetMediaItems(
                this.ownership,
                { page: 1, size: this.pageSize },
                this.sorting,
                this.type,
                query,
                {
                    includeShared: this.includeShared,
                    ownedByCompany: this.includeOwnedByCompany,
                },
            );

            this.searchCache[cacheKey].then(cachedPage => {
                cachedPage.items.forEach(item => {
                    if (!this.byValueCache[item.mediaItemId]) {
                        this.byValueCache[item.mediaItemId] = Promise.resolve(item);
                    }
                });

                if (cachedPage.total > cachedPage.items.length && this.pageSize < 1000) {
                    this.hasMoreResults = true;
                } else {
                    this.hasMoreResults = false;
                }
            });
        }

        this.query = query;

        const page = await this.searchCache[cacheKey];

        return page.items.map(this.mapItem);
    }

    async loadMoreResults() {
        if (this.hasMoreResults) {
            this.pageSize += this.perPage;
        }

        return await this.search(this.query);
    }

    getHasMoreResults() {
        return this.hasMoreResults;
    }

    async byValue(value: string) {
        if (!value) {
            return;
        }

        if (!this.byValueCache[value]) {
            this.byValueCache[value] = mediaItemApi.GetMediaItemDetails(value);
        }

        const item = await this.byValueCache[value];
        return this.mapItem(item);
    }

    // we do not support multi-selects
    async byValues(_values: string[]) {
        return [];
    }

    private mapItem = (item: Domain.MediaItem): MediaItemSearchProviderItem => {
        return {
            ...item,
            value: item.mediaItemId,
            label: item.name,
        };
    };
}
