import * as React from 'react';

import moment from 'moment-timezone';

import { Domain } from 'api';
import { Tx } from 'ui-components';

export default function NotificationTime(props: { notification: Domain.Notification; small?: boolean; color?: string }) {
    return (
        <Tx
            level={props.small ? 'body-xs' : 'body-md'}
            sx={{
                color: props.color || '--content-tertiary',
            }}
        >
            {moment(props.notification.createdOn).fromNow()}
        </Tx>
    );
}
