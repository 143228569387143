import { Domain } from 'api';
import { ISearchProvider } from 'utils';

import { companyApi } from '@/api';

export interface CompanySearchProviderItem {
    value: Domain.Company['companyId'];
    label: Domain.Company['name'];
}

export class CompanySearchProvider implements ISearchProvider<CompanySearchProviderItem> {
    private hasMoreResults = false;
    private country: string | undefined;
    private canUseSharingGroups: boolean | undefined;

    private searchCache: {
        [key: string]: Promise<Domain.CompaniesPage>;
    } = {};

    private byValueCache: {
        [key: string]: Promise<Domain.Company>;
    } = {};

    resetCache() {
        this.searchCache = {};
        this.byValueCache = {};
    }

    reset() {
        this.resetCache();
    }

    setCountry(country?: string) {
        if (country === this.country) {
            return;
        }
        this.country = country;
        this.resetCache();
    }

    setCanUseSharingGroups(canUseSharingGroups?: boolean) {
        if (canUseSharingGroups === this.canUseSharingGroups) {
            return;
        }
        this.canUseSharingGroups = canUseSharingGroups;
        this.resetCache();
    }

    async search(query: string) {
        if (!this.searchCache[query]) {
            this.searchCache[query] = companyApi.GetCompanies({ page: 1, size: 10 }, { field: 'name', direction: 'ascending' }, query, {
                country: this.country,
                canUseSharingGroups: this.canUseSharingGroups,
            });

            this.searchCache[query].then(cachedCompaniesPage => {
                cachedCompaniesPage.items.forEach(company => {
                    if (!this.byValueCache[company.companyId]) {
                        this.byValueCache[company.companyId] = Promise.resolve(company);
                    }
                });
            });
        }
        const companiesPage = await this.searchCache[query];

        return companiesPage.items.map(this.mapItem);
    }

    // we do not support loading more results
    async loadMoreResults() {
        return [];
    }

    // We do not have this implemented yet in this search provider
    getHasMoreResults() {
        return this.hasMoreResults;
    }

    async byValue(value: string) {
        if (!value) {
            return;
        }

        if (!this.byValueCache[value]) {
            this.byValueCache[value] = companyApi.GetCompanyDetails(value);
        }

        const company = await this.byValueCache[value];
        return this.mapItem(company);
    }

    async byValues(values: string[]) {
        return await Promise.all(values.filter(Boolean).map(value => this.byValue(value) as Promise<CompanySearchProviderItem>));
    }

    mapItem(company: Domain.Company) {
        return {
            value: company.companyId,
            label: company.name,
            ...company,
        };
    }
}
