import * as React from 'react';

import { Route, isBranchManager, AppointmentTypes } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/webshops/appointment-types/overview',
        main: () => import('./AppointmentTypesOverview'),
        isAuthorized: isBranchManager,
        preloadData: AppointmentTypes.overviewState.actions.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/webshops/appointment-types/config',
        main: () => import('./AppointmentTypeConfig'),
        isAuthorized: isBranchManager,
        preloadData: () => AppointmentTypes.overviewState.loadAppointmentTypes(999),
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/webshops/appointment-types/overview/:appointmentTypeId/:tab',
        main: () => import('./ViewAppointmentType'),
        isAuthorized: isBranchManager,
        preloadData: AppointmentTypes.updateState.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/webshops/appointment-types/new',
        main: () => import('./CreateAppointmentType'),
        isAuthorized: [isBranchManager],
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/webshops/appointment-types/edit/:appointmentTypeId/:clone?',
        main: () => import('./UpdateAppointmentType'),
        isAuthorized: [isBranchManager],
        preloadData: AppointmentTypes.updateState.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
];

export default routes;
