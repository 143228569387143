import * as React from 'react';

import { Preloader, Col, Container, Row } from 'ui-components';

export default function DataTable(props: { hideToggleColumns?: boolean }) {
    return (
        <>
            {!props.hideToggleColumns ? (
                <Container
                    fullWidth={true}
                    className="mt-3 mb-13"
                >
                    <Row justify="end">
                        <Col>
                            <Preloader
                                width={202}
                                height={42}
                            />
                        </Col>
                    </Row>
                </Container>
            ) : null}

            <Preloader
                width="100%"
                height={400}
            />
        </>
    );
}
