import * as React from 'react';

import { Grid, Row, Col } from 'ui-components';

import styles from './BasicPage.scss';

export default function BasicPage(props: {
    className?: string;
    header?: React.ReactNode;
    headerRight?: React.ReactNode;
    mainActions?: React.ReactNode;
    body: React.ReactNode;
    bodyRowClassName?: string;
}) {
    return (
        <Grid className={props.className}>
            {props.header ? (
                <Row>
                    <Col span={props.headerRight ? 9 : 12}>{props.header}</Col>

                    {props.headerRight ? (
                        <Col
                            span={3}
                            className={styles.BasicPageHeaderRight}
                        >
                            {props.headerRight}
                        </Col>
                    ) : null}
                </Row>
            ) : null}

            {props.mainActions ? (
                <Row>
                    <Col span={12}>{props.mainActions}</Col>
                </Row>
            ) : null}

            <Row className={props.bodyRowClassName !== undefined ? props.bodyRowClassName : 'mt-40 mt-sm-0'}>
                <Col span={12}>{props.body}</Col>
            </Row>
        </Grid>
    );
}
