import * as React from 'react';

import classNames from 'classnames';

import Tabs, { ITabItem } from './Tabs';

import styles from './Tabs.scss';

interface IColorfulTabItemProps {
    borderColor: string;
    backgroundColor: string;
}

export default class ColorfulTabs extends Tabs<IColorfulTabItemProps> {
    protected renderItemLabel(item: ITabItem<IColorfulTabItemProps>) {
        return (
            <span>
                {item.title}
                <span className={styles.ColorfulTabHeaderBorder} />
                <span
                    className={styles.ColorfulTabHeaderFill + ' ' + item.backgroundColor}
                    style={{
                        backgroundColor: item.backgroundColor,
                    }}
                />
            </span>
        );
    }

    protected renderActiveTab() {
        const activeTab = this.getActiveTab();

        if (!activeTab) {
            return null;
        }

        return (
            <div
                className={styles.TabBody + ' ' + activeTab.backgroundColor + ' ' + activeTab.borderColor}
                style={{
                    backgroundColor: activeTab.backgroundColor,
                    borderColor: activeTab.borderColor,
                }}
            >
                {activeTab.body}
            </div>
        );
    }

    protected getBaseClassName() {
        return classNames(super.getBaseClassName(), styles.ColorfulTabs);
    }

    protected getTabHeaderStyle(item: ITabItem<IColorfulTabItemProps>, index: number): React.CSSProperties {
        const { activeTabIndex } = this.props;

        const style = {
            ...super.getTabHeaderStyle(item, index),
            backgroundColor: item.backgroundColor,
        };

        if (activeTabIndex === index) {
            style.borderColor = item.borderColor;
        }

        return style;
    }

    protected getTabHeaderClassName(item: ITabItem<IColorfulTabItemProps>, index: number) {
        const { activeTabIndex } = this.props;

        return classNames(
            super.getTabHeaderClassName(item, index),
            item.backgroundColor,
            activeTabIndex === index ? item.borderColor : undefined,
        );
    }
}
