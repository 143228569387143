import * as React from 'react';

import { Domain } from 'api';
import { useGettext, localizeCountryLabel } from 'data-store';

export default function CountryLabel(props: { country: Domain.Country }) {
    const { gettext } = useGettext();

    return <span>{localizeCountryLabel(props.country, gettext)}</span>;
}
