import * as React from 'react';

import { createRoot } from 'react-dom/client';

import { setApplication } from 'logger';
import { browserStorage } from 'utils';

import App from '@/Infrastructure/App/App';
import Error from '@/Pages/Errors/Error';
import ErrorBoundary from '@/Pages/Errors/ErrorBoundary';

import routes from './platform-routes';

import './platform.scss';

export const render = () => {
    browserStorage.clearDeviceFingerprint();
    setApplication('platform');

    const container = document.getElementById('root');
    const root = createRoot(container!);
    root.render(
        <ErrorBoundary renderErrorPageContents={error => <Error error={error} />}>
            <App routes={routes} />
        </ErrorBoundary>,
    );
};
