import * as React from 'react';

import { Domain } from 'api';
import { ISearchProvider } from 'utils';

import { brandApi } from '@/api';

interface BrandSearchProviderItem {
    value: Domain.Brand['brandId'];
    label: React.ReactNode;
}

type LabelRenderer = (value: Domain.Brand) => React.ReactNode;

export class BrandSearchProvider implements ISearchProvider<BrandSearchProviderItem> {
    private locale: Domain.Locale;
    private labelRenderer: LabelRenderer = x => x.brandId;
    private hasMoreResults = false;

    private searchCache: {
        [key: string]: Promise<Domain.BrandsPage>;
    } = {};

    private byValueCache: {
        [key: string]: Promise<Domain.Brand>;
    } = {};

    resetCache() {
        this.searchCache = {};
        this.byValueCache = {};
    }

    reset() {
        this.resetCache();
    }

    setLocale(locale: Domain.Locale) {
        if (this.locale === locale) {
            return;
        }

        this.locale = locale;
        this.resetCache();
    }

    setLabelRenderer(labelRenderer: LabelRenderer) {
        this.labelRenderer = labelRenderer;
    }

    async search(query: string) {
        const cacheKey = query + '-' + this.locale;

        if (!this.searchCache[cacheKey]) {
            this.searchCache[cacheKey] = brandApi.GetBrands({ page: 1, size: 10 }, this.locale, query);

            this.searchCache[cacheKey].then(cachedBrandsPage => {
                cachedBrandsPage.items.forEach(brand => {
                    if (!this.byValueCache[brand.brandId]) {
                        this.byValueCache[brand.brandId] = Promise.resolve(brand);
                    }
                });
            });
        }
        const brandsPage = await this.searchCache[cacheKey];

        return brandsPage.items.map(brand => {
            return {
                value: brand.brandId,
                label: this.brandLabel(brand),
            };
        });
    }

    async byValue(value: string) {
        if (!value) {
            return;
        }

        if (!this.byValueCache[value]) {
            this.byValueCache[value] = brandApi.GetBrandDetails(value);
        }

        const brandDetails = await this.byValueCache[value];
        return {
            value: brandDetails.brandId,
            label: this.brandLabel(brandDetails),
        };
    }

    // we do not support loading more results
    async loadMoreResults() {
        return [];
    }

    // We do not have this implemented yet in this search provider
    getHasMoreResults() {
        return this.hasMoreResults;
    }

    async byValues(values: string[]) {
        if (values.length === 0 || !this.locale) {
            return [];
        }

        const brandIds = values.join(',');
        const cacheKey = brandIds + '-' + this.locale;

        if (!this.searchCache[cacheKey]) {
            this.searchCache[cacheKey] = brandApi.GetBrands({ page: 1, size: values.length }, this.locale, '', {
                brandIds,
            });

            this.searchCache[cacheKey].then(cachedBrandsPage => {
                cachedBrandsPage.items.forEach(brand => {
                    if (!this.byValueCache[brand.brandId]) {
                        this.byValueCache[brand.brandId] = Promise.resolve(brand);
                    }
                });
            });
        }
        const brandsPage = await this.searchCache[cacheKey];

        return brandsPage.items.map(brand => {
            return {
                value: brand.brandId,
                label: this.brandLabel(brand),
            };
        });
    }

    private brandLabel(brand: Domain.Brand) {
        if (brand.localizedNames) {
            return this.labelRenderer(brand);
        }

        return brand.name || brand.brandId;
    }
}
