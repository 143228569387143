import { Domain } from 'api';
import { ISearchProvider } from 'utils';

import { productWallApi } from '@/api';

export type ProductWallSearchProviderItem = Domain.ProductWall & {
    value: Domain.ProductWall['productWallId'];
    label: Domain.ProductWall['name'];
};

export class ProductWallSearchProvider implements ISearchProvider<ProductWallSearchProviderItem> {
    private ownership: Domain.Ownership;
    private screenResolution: Domain.DeviceScreenResolution | undefined;
    private locale: Domain.Locale | undefined;
    private query: string;
    private perPage = 10;
    private pageSize = 10;
    private hasMoreResults = false;
    private includeShared: 'true' | undefined;
    private includeOwnedByCompany: 'true' | undefined;
    private sorting: Domain.Sorting = { field: 'name', direction: 'ascending' };

    private searchCache: {
        [key: string]: Promise<Domain.ProductWallsPage>;
    } = {};

    private byValueCache: {
        [key: string]: Promise<Domain.ProductWall>;
    } = {};

    resetCache() {
        this.searchCache = {};
        this.byValueCache = {};
    }
    setSorting(sorting: Domain.Sorting) {
        this.sorting = sorting;
    }
    reset() {
        this.resetCache();
    }

    setOwnership(ownership: Domain.Ownership) {
        this.ownership = ownership;
        this.resetCache();
    }

    setScreenResolution(screenResolution: Domain.DeviceScreenResolution | undefined) {
        this.screenResolution = screenResolution;
        this.resetCache();
    }

    setLocale(locale: Domain.Locale | undefined) {
        this.locale = locale;
        this.resetCache();
    }

    setIncludeShared(includeShared: 'true' | undefined) {
        this.includeShared = includeShared;
        this.resetCache();
    }

    setIncludeOwnedByCompany(includeOwnedByCompany: 'true' | undefined) {
        if (this.includeOwnedByCompany === includeOwnedByCompany) {
            return;
        }
        this.includeOwnedByCompany = includeOwnedByCompany;
        this.resetCache();
    }

    // We do not have this implemented yet in this search provider
    getHasMoreResults() {
        return this.hasMoreResults;
    }

    async search(query: string) {
        if (this.query !== query) {
            this.pageSize = this.perPage;
            this.hasMoreResults = true;
        }

        const cacheKey = query + '-' + this.includeShared + '-' + this.includeOwnedByCompany + '-' + this.pageSize;

        if (!this.searchCache[cacheKey]) {
            this.searchCache[cacheKey] = productWallApi.GetProductWalls(
                this.ownership,
                { page: 1, size: this.pageSize },
                this.sorting,
                query,
                {
                    locale: this.locale,
                    includeShared: this.includeShared,
                    screenResolution: this.screenResolution,
                    ownedByCompany: this.includeOwnedByCompany,
                },
            );

            this.searchCache[cacheKey].then(cachedPage => {
                cachedPage.items.forEach(productWall => {
                    if (!this.byValueCache[productWall.productWallId]) {
                        this.byValueCache[productWall.productWallId] = Promise.resolve(productWall);
                    }
                });

                if (cachedPage.total > cachedPage.items.length && this.pageSize < 1000) {
                    this.hasMoreResults = true;
                } else {
                    this.hasMoreResults = false;
                }
            });
        }

        this.query = query;

        const productWallsPage = await this.searchCache[cacheKey];

        return productWallsPage.items.map(productWall => {
            return {
                ...productWall,
                value: productWall.productWallId,
                label: productWall.name,
            };
        });
    }

    async loadMoreResults() {
        if (this.hasMoreResults) {
            this.pageSize += this.perPage;
        }

        return await this.search(this.query);
    }

    async byValue(value: string) {
        if (!value) {
            return;
        }

        if (!this.byValueCache[value]) {
            this.byValueCache[value] = productWallApi.GetProductWallDetails(value);
        }

        const productWall = await this.byValueCache[value];
        return {
            ...productWall,
            value: productWall.productWallId,
            label: productWall.name,
        };
    }

    // we do not support multi-selects=
    async byValues(_values: string[]) {
        return [];
    }
}
