import * as React from 'react';

import { Domain } from 'api';
import { ISearchProvider } from 'utils';

import { productSelectionApi } from '@/api';

interface SelectedProductSearchProviderItem {
    value: string;
    label: React.ReactNode;
}

export interface ProductMapperInfo {
    stockLabel: string;
    robotStockLabel?: string;
    lockerStockLabel?: string;
    suitedForLabel?: string;
}

type ProductMapper<T> = (
    item: Domain.SelectedProduct,
    locale: Domain.Locale,
    info: ProductMapperInfo,
) => T & SelectedProductSearchProviderItem;

export class SelectedProductSearchProvider<T> implements ISearchProvider<T & SelectedProductSearchProviderItem> {
    private ownership: Domain.Ownership;
    private asSuperUser: boolean;
    private selectionType: Domain.ProductSelectionType;
    private completeness: Domain.ProductCompletenessFilter;
    private locale: Domain.Locale;
    private categories: string[] = [];
    private productMapperInfo: ProductMapperInfo;
    private productMapper: ProductMapper<T>;
    private hasMoreResults = false;
    private cacheVersion = 1;

    private searchCache: {
        [key: string]: Promise<Domain.SelectedProductsPage>;
    } = {};

    private byValueCache: {
        [key: string]: Promise<Domain.SelectedProduct>;
    } = {};

    public get cache() {
        return this.cacheVersion;
    }

    constructor(productMapper: ProductMapper<T>) {
        this.productMapper = productMapper;
        this.productMapperInfo = {
            stockLabel: ':quantity:',
            robotStockLabel: ':quantity:',
            lockerStockLabel: ':quantity:',
        };
    }

    resetCache() {
        this.searchCache = {};
        this.byValueCache = {};
        this.cacheVersion += 1;
    }

    reset() {
        this.resetCache();
    }

    resetByValueCacheFor(value: string) {
        delete this.byValueCache[value];
    }

    setOwnership(ownership: Domain.Ownership, asSuperUser: boolean) {
        if (JSON.stringify(this.ownership) === JSON.stringify(ownership) && this.asSuperUser === asSuperUser) {
            return;
        }
        this.ownership = ownership;
        this.asSuperUser = asSuperUser;
        this.resetCache();
    }

    setProductMapperInfo(info: ProductMapperInfo) {
        this.productMapperInfo = info;
    }

    setSelectionType(selectionType: Domain.ProductSelectionType) {
        if (this.selectionType === selectionType) {
            return;
        }
        this.selectionType = selectionType;
        this.resetCache();
    }

    setCompleteness(completeness: Domain.ProductCompletenessFilter) {
        if (this.completeness === completeness) {
            return;
        }
        this.completeness = completeness;
        this.resetCache();
    }

    public setCategories(categories: string[]) {
        if (this.categories.join('') === categories.join('')) {
            return;
        }
        this.categories = categories;
        this.resetCache();
    }

    public setLocale(locale: Domain.Locale) {
        if (this.locale === locale) {
            return;
        }
        this.locale = locale;
        this.resetCache();
    }

    public getLocale(): Domain.Locale {
        return this.locale;
    }

    public setProductMapper(productMapper: (item: Domain.SelectedProduct, locale: Domain.Locale) => T & SelectedProductSearchProviderItem) {
        this.productMapper = productMapper;
    }

    async search(query: string) {
        if (!this.searchCache[query]) {
            this.searchCache[query] = productSelectionApi.GetSelectedProducts(
                {
                    ...this.ownership,
                    asSuperUser: this.asSuperUser,
                },
                this.selectionType,
                { page: 1, size: 10 },
                this.locale,
                query,
                this.completeness,
                undefined,
                {
                    categoryIds: this.categories.join(','),
                },
            );
        }

        const page = await this.searchCache[query];
        return page.items.map(this.mapItem);
    }

    // we do not support loading more results
    async loadMoreResults() {
        return [];
    }

    // We do not have this implemented yet in this search provider
    getHasMoreResults() {
        return this.hasMoreResults;
    }

    async byValue(value: string) {
        const item = await this.byValueUnmapped(value);
        if (item) {
            return this.mapItem(item);
        }
    }

    async byValueUnmapped(value: string) {
        if (!value) {
            return;
        }

        if (!this.byValueCache[value]) {
            this.byValueCache[value] = productSelectionApi.GetSelectedProductDetails(
                this.ownership,
                value,
                this.selectionType,
                this.locale,
            );
        }

        return await this.byValueCache[value];
    }

    async byValues(values: string[]) {
        if (values.length === 0) {
            return [];
        }

        const productIds = values.join(',');
        const cacheKey = productIds + '-' + this.locale;

        if (!this.searchCache[cacheKey]) {
            this.searchCache[cacheKey] = productSelectionApi.GetSelectedProducts(
                {
                    ...this.ownership,
                    asSuperUser: this.asSuperUser,
                },
                this.selectionType,
                { page: 1, size: values.length },
                this.locale,
                '',
                this.completeness,
                undefined,
                {
                    productIds,
                },
            );

            this.searchCache[cacheKey].then(cachedProductsPage => {
                cachedProductsPage.items.forEach(product => {
                    if (!this.byValueCache[product.productId]) {
                        this.byValueCache[product.productId] = Promise.resolve(product);
                    }
                });
            });
        }

        const page = await this.searchCache[cacheKey];
        return page.items.map(this.mapItem);
    }

    private mapItem = (item: Domain.SelectedProduct): T & SelectedProductSearchProviderItem => {
        return this.productMapper(item, this.locale, this.productMapperInfo);
    };
}
