import * as React from 'react';

import { Domain } from 'api';
import { ISearchProvider } from 'utils';

import { productSourceApi, organisationApi } from '@/api';

interface OrganisationSearchProviderItem {
    value: Domain.Organisation['organisationId'];
    label: React.ReactNode;
    organisation: Domain.Organisation;
}

type LabelRenderer = (value: Domain.Organisation) => React.ReactNode;

export class ProductSourceOrganisationSearchProvider implements ISearchProvider<OrganisationSearchProviderItem> {
    private productSourceId: string;
    private locale: Domain.Locale;
    private labelRenderer: LabelRenderer = x => x.organisationId;

    private searchCache: {
        [key: string]: Promise<Domain.OrganisationsPage>;
    } = {};

    private byValueCache: {
        [key: string]: Promise<Domain.Organisation>;
    } = {};

    resetCache() {
        this.searchCache = {};
        this.byValueCache = {};
    }

    reset() {
        this.resetCache();
    }

    setProductSourceId(productSourceId: string) {
        if (this.productSourceId === productSourceId) {
            return;
        }

        this.productSourceId = productSourceId;
        this.resetCache();
    }

    setLocale(locale: Domain.Locale) {
        if (this.locale === locale) {
            return;
        }

        this.locale = locale;
        this.resetCache();
    }

    setLabelRenderer(labelRenderer: LabelRenderer) {
        this.labelRenderer = labelRenderer;
    }

    async search(query: string) {
        const cacheKey = query + '-' + this.locale;

        if (!this.searchCache[cacheKey]) {
            this.searchCache[cacheKey] = productSourceApi.GetOrganisations(this.productSourceId, this.locale, query);

            this.searchCache[cacheKey].then(cachedOrganisationsPage => {
                cachedOrganisationsPage.items.forEach(organisation => {
                    if (!this.byValueCache[organisation.organisationId]) {
                        this.byValueCache[organisation.organisationId] = Promise.resolve(organisation);
                    }
                });
            });
        }
        const organisationsPage = await this.searchCache[cacheKey];

        return organisationsPage.items.map(organisation => this.mapItem(organisation));
    }

    async byValue(value: string) {
        if (!value) {
            return;
        }

        if (!this.byValueCache[value]) {
            this.byValueCache[value] = organisationApi.GetOrganisationDetails(value);
        }

        const organisationDetails = await this.byValueCache[value];
        return this.mapItem(organisationDetails);
    }

    async byValueFromCacheOnly(value: string): Promise<undefined | OrganisationSearchProviderItem> {
        if (!this.byValueCache[value]) {
            return undefined;
        }

        const organisationDetails = await this.byValueCache[value];
        return this.mapItem(organisationDetails);
    }

    // we do not support loading more results
    async loadMoreResults() {
        return [];
    }

    async byValues(values: string[]) {
        if (values.length === 0 || !this.locale) {
            return [];
        }

        const organisationIds = values.join(',');
        const cacheKey = organisationIds + '-' + this.locale;

        if (!this.searchCache[cacheKey]) {
            this.searchCache[cacheKey] = organisationApi.GetOrganisations({ page: 1, size: values.length }, this.locale, '', {
                organisationIds,
            });

            this.searchCache[cacheKey].then(cachedOrganisationsPage => {
                cachedOrganisationsPage.items.forEach(organisation => {
                    if (!this.byValueCache[organisation.organisationId]) {
                        this.byValueCache[organisation.organisationId] = Promise.resolve(organisation);
                    }
                });
            });
        }
        const organisationsPage = await this.searchCache[cacheKey];

        return organisationsPage.items.map(organisation => this.mapItem(organisation));
    }

    private mapItem(item: Domain.Organisation): OrganisationSearchProviderItem {
        return {
            value: item.organisationId,
            label: this.organisationLabel(item),
            organisation: item,
        };
    }

    private organisationLabel(organisation: Domain.Organisation) {
        return this.labelRenderer(organisation);
    }

    getHasMoreResults(): boolean {
        return false;
    }
}
