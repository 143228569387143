import * as React from 'react';

import { Button } from '@/button';
import { Col, Container, Row } from '@/layout';

import { DataTableColumn } from './DataTable';
import DataTableFilterPill from './DataTableFilterPill';
import DataTablePillLabel from './DataTablePillLabel';

type KeyOrNothing<K> = K extends string ? K : never;

export type FiltersConfig<T, P = void> = {
    key: KeyOrNothing<P> | keyof T;
    labelRenderer?: (value: string) => React.ReactNode | string;
}[];

export default function DataTableFilterPills<T, P = void>(props: {
    filters: {
        [key in KeyOrNothing<P> | keyof T]?: string | undefined;
    };
    labelRenderers: FiltersConfig<T, P>;
    columns: (DataTableColumn<T, P> | false)[];
    onPillRemove: (key: KeyOrNothing<P> | keyof T | undefined) => void;
    gettext: (text: string) => string;
}) {
    const gettext = props.gettext;

    const columnKeys = props.columns.map(column => (column ? column.key : false)).filter(Boolean) as (KeyOrNothing<P> | keyof T)[];

    let hasAnyFilters = false;
    for (const filterKey in props.filters) {
        if (
            filterKey &&
            props.filters.hasOwnProperty(filterKey) &&
            props.filters[filterKey as KeyOrNothing<P> | keyof T] &&
            columnKeys.includes(filterKey as KeyOrNothing<P> | keyof T)
        ) {
            hasAnyFilters = true;
            break;
        }
    }

    if (!hasAnyFilters) {
        return null;
    }

    return (
        <Container fullWidth={true}>
            <Row vAlign="start">
                <Col
                    className="mr-8"
                    vAlign="center"
                >
                    {gettext('Filters')}
                </Col>

                {props.columns.map(column => {
                    if (!column || !column.filterable || !props.filters[column.key]) {
                        return null;
                    }

                    const labelRendererItem = props.labelRenderers.find(searchedItem => searchedItem.key === column.key);

                    return (
                        <Col key={column.key as string}>
                            <DataTableFilterPill
                                onRemove={() => {
                                    props.onPillRemove(column.key);
                                }}
                            >
                                <strong className="mr-4">{column.label}</strong>&nbsp;
                                <DataTablePillLabel
                                    searchProvider={column.filterSearchProvider}
                                    item={props.filters[column.key]}
                                    labelRenderer={labelRendererItem ? labelRendererItem.labelRenderer : undefined}
                                />
                            </DataTableFilterPill>
                        </Col>
                    );
                })}

                <Col>
                    <Button
                        variant="tertiary"
                        variantSize="s"
                        onClick={async () => {
                            props.onPillRemove(undefined);
                        }}
                        data-test-id="clear-all-grid-filters"
                    >
                        {gettext('Clear all')}
                    </Button>
                </Col>
            </Row>
        </Container>
    );
}
