import { Selector } from '@reduxjs/toolkit';

import { Domain } from 'api';

import { RootState } from '@/store';

export const selectUserUnreadNotificationsCount: Selector<RootState, Domain.NotificationsCount['unread']> = state =>
    state.platformNotification.unreadCount;
export const selectAllUserNotifications: Selector<RootState, Domain.NotificationsPage> = state =>
    state.platformNotification.allNotifications;
