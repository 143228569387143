import * as React from 'react';

import { Route, isSuperUser, WebshopPageLibrary } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/webshops/pages-library',
        main: () => import('./WebshopPagesLibraryOverview'),
        isAuthorized: isSuperUser,
        preloadData: WebshopPageLibrary.overviewState.actions.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/webshops/pages-library/:pageId/:locale',
        main: () => import('./UpdateLibraryPage'),
        isAuthorized: isSuperUser,
        preloadData: WebshopPageLibrary.updateState.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
];

export default routes;
