import * as React from 'react';

import { Route, isSuperUser, isCompanyManager, isBranchManager, AvailableProduct } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/products/available-products',
        main: () => import('./AvailableProductsOverview'),
        isAuthorized: [isSuperUser, isCompanyManager, isBranchManager],
        preloadData: AvailableProduct.overviewState.actions.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/products/available-products/:availableProductId',
        main: () => import('./AvailableProductDetails'),
        isAuthorized: [isSuperUser, isCompanyManager, isBranchManager],
        preloadData: AvailableProduct.detailsState.actions.load,
        preloading: () => (
            <FullPage
                hideMainActions={true}
                bodyType="tabs-and-boxes"
            />
        ),
    },
    {
        path: '/products/available-products/:availableProductId/:tab',
        main: () => import('./AvailableProductDetails'),
        isAuthorized: [isSuperUser, isCompanyManager, isBranchManager],
        preloadData: AvailableProduct.detailsState.actions.load,
        preloading: () => (
            <FullPage
                hideMainActions={true}
                bodyType="tabs-and-boxes"
            />
        ),
    },
];

export default routes;
