import * as React from 'react';

import { Route, isPlatformAdministrator, SearchSynonym } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/localisation/search-synonyms',
        main: () => import('./SearchSynonymsOverview'),
        isAuthorized: isPlatformAdministrator,
        preloadData: SearchSynonym.overviewState.actions.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="data-table"
            />
        ),
    },
    {
        path: '/localisation/search-synonyms/edit/:searchTerm/:locale',
        main: () => import('./UpdateSearchSynonyms'),
        isAuthorized: isPlatformAdministrator,
        preloadData: SearchSynonym.updateState.actions.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
];

export default routes;
