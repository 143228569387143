import { Route } from 'data-store';

import billableServiceRoutes from './Pages/BillableServices/routes';
import cmsRoutes from './Pages/Cms/routes';
import customersRoutes from './Pages/Customers/routes';
import dashboardRoutes from './Pages/Dashboard/routes';
import deviceMonitoringRoutes from './Pages/DeviceMonitoring/routes';
import errorRoutes from './Pages/Errors/routes';
import externalSourceOrderRoutes from './Pages/ExternalSourceOrders/routes';
import feedbackRoutes from './Pages/Feedback/routes';
import integrationsRoutes from './Pages/Integrations/routes';
import invoiceRoutes from './Pages/Invoices/routes';
import landingRoutes from './Pages/Landing/routes';
import localisationRoutes from './Pages/Localisation/routes';
import mediaRoutes from './Pages/Media/routes';
import pickupRoutes from './Pages/Pickups/routes';
import presentationsRoutes from './Pages/Presentations/routes';
import productsRoutes from './Pages/Products/routes';
import vendingMachineRoutes from './Pages/VendingMachine/routes';
import webhookRoutes from './Pages/Webhooks/routes';
import webshopsRoutes from './Pages/Webshops/routes';

const routes: Route[] = [
    ...landingRoutes,
    ...dashboardRoutes,
    ...localisationRoutes,
    ...customersRoutes,
    ...vendingMachineRoutes,
    ...webshopsRoutes,
    ...mediaRoutes,
    ...presentationsRoutes,
    ...feedbackRoutes,
    ...integrationsRoutes,
    ...productsRoutes,
    ...deviceMonitoringRoutes,
    ...invoiceRoutes,
    ...billableServiceRoutes,
    ...pickupRoutes,
    ...webhookRoutes,
    ...cmsRoutes,
    ...externalSourceOrderRoutes,

    // has to be last because it matches * (no other route matched)
    ...errorRoutes,
];

export default routes;
