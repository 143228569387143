import * as React from 'react';

import { Route, isPlatformAdministrator, isSupportAgent, Reseller } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/customers/resellers',
        main: () => import('./ResellersOverview'),
        isAuthorized: [isPlatformAdministrator, isSupportAgent],
        preloadData: Reseller.overviewState.actions.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/customers/resellers/new',
        main: () => import('./CreateReseller'),
        isAuthorized: [isPlatformAdministrator, isSupportAgent],
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
    {
        path: '/customers/resellers/edit/:resellerId',
        main: () => import('./UpdateReseller'),
        isAuthorized: [isPlatformAdministrator, isSupportAgent],
        preloadData: Reseller.updateState.actions.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
];

export default routes;
