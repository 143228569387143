import * as React from 'react';

import { Route, isCompanyManager, isBranchManager } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/vending-machines/layout/color-themes',
        main: () => import('./VendingMachineLayoutColorThemesOverview'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/vending-machines/layout/color-themes/:themeId',
        main: () => import('./VendingMachineLayoutColorThemeDetails'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/vending-machines/layout/typography',
        main: () => import('./VendingMachineLayoutTypographyOverview'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/vending-machines/layout/typography/:fontSetId',
        main: () => import('./VendingMachineLayoutTypographyDetails'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloading: () => <FullPage hideMainSearch={true} />,
    },
];

export default routes;
