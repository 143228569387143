import { uuid } from 'api';

import { ThunkAction } from '@/action';

import { selectMaxVisibleNotifications, selectNotifications } from './selectors';
import { addNotification, removeNotification, setNotificationState } from './state';
import { Notification } from './types';

const animateRemove =
    (notificationId: string, animationDuration = 300): ThunkAction =>
    async dispatch => {
        await dispatch(
            setNotificationState({
                notificationId,
                state: 'removing',
            }),
        );

        setTimeout(() => {
            dispatch(
                removeNotification({
                    notificationId,
                }),
            );
        }, animationDuration);
    };

export const RemoveNotification = animateRemove;

export const AddNotification =
    (notification: Omit<Notification, 'notificationId'>, displayTime = 10000, animationDuration = 300): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const maxVisibleNotifications = selectMaxVisibleNotifications(state);
        const notifications = selectNotifications(state);

        if (notifications.length >= maxVisibleNotifications) {
            const olderNotifications = notifications.slice(maxVisibleNotifications - 1);
            for (const olderNotification of olderNotifications) {
                await dispatch(animateRemove(olderNotification.notification.notificationId));
            }
        }

        const notificationId = uuid.notification();
        await dispatch(
            addNotification({
                notificationId,
                ...notification,
            }),
        );

        setTimeout(() => {
            dispatch(
                setNotificationState({
                    notificationId,
                    state: 'visible',
                }),
            );
        }, animationDuration);

        setTimeout(() => {
            dispatch(animateRemove(notificationId));
        }, displayTime);
    };
