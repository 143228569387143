import * as React from 'react';

import { Route, isSuperUser, isCompanyManager, isBranchManager, Webshop } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/webshops/overview',
        main: () => import('./WebshopsOverview'),
        isAuthorized: [isSuperUser, isCompanyManager, isBranchManager],
        preloadData: Webshop.overviewState.actions.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/webshops/overview/:webshopId',
        main: () => import('./ViewWebshop'),
        isAuthorized: [isSuperUser, isCompanyManager, isBranchManager],
        preloadData: Webshop.detailsState.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/webshops/overview/:webshopId/:tab',
        main: () => import('./ViewWebshop'),
        isAuthorized: [isSuperUser, isCompanyManager, isBranchManager],
        preloadData: Webshop.detailsState.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/webshops/overview/edit/:webshopId',
        main: () => import('./UpdateWebshop'),
        isAuthorized: isSuperUser,
        preloadData: Webshop.updateState.loadWebshopAndConfiguration,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
    {
        path: '/webshops/store-configuration',
        main: () => import('./UpdateWebshopConfiguration'),
        isAuthorized: [isSuperUser, isCompanyManager, isBranchManager],
        preloadData: Webshop.updateState.loadConfiguration,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
    {
        path: '/webshops/contact-information',
        main: () => import('./UpdateWebshopContactInformation'),
        isAuthorized: [isSuperUser, isCompanyManager, isBranchManager],
        preloadData: Webshop.updateState.loadConfiguration,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
    {
        path: '/webshops/payment-configuration',
        main: () => import('./UpdateWebshopPaymentConfiguration'),
        isAuthorized: [isSuperUser, isCompanyManager, isBranchManager],
        preloadData: Webshop.updateState.loadBillableServicesAndConfiguration,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
    {
        path: '/webshops/marketing',
        main: () => import('./UpdateWebshopMarketing'),
        isAuthorized: [isSuperUser, isCompanyManager, isBranchManager],
        preloadData: Webshop.updateState.loadQualityLabelsAndConfiguration,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
    {
        path: '/webshops/vacation',
        main: () => import('./UpdateWebshopVacationConfiguration'),
        isAuthorized: [isSuperUser, isCompanyManager, isBranchManager],
        preloadData: Webshop.updateState.loadConfiguration,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
    {
        path: '/webshops/layout/logos',
        main: () => import('./UpdateWebshopLogos'),
        isAuthorized: [isSuperUser, isCompanyManager, isBranchManager],
        preloadData: Webshop.updateState.loadColorThemesAndConfiguration,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
];

export default routes;
