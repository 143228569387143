import * as React from 'react';

import { Route, isSignedIn, SearchActionsExport } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/localisation/search-actions/export',
        main: () => import('./SearchActionsExportOverview'),
        isAuthorized: isSignedIn,
        preloadData: SearchActionsExport.overviewState.actions.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/localisation/search-actions/export/new',
        main: () => import('./StartSearchActionsExport'),
        isAuthorized: isSignedIn,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
];

export default routes;
