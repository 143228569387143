import moment from 'moment';

export interface DateRange {
    from: moment.Moment;
    to: moment.Moment;
}

export function dateRangeIsCollapsed(range: DateRange): boolean {
    return range.from.isSame(range.to, 'day');
}

export function rangeLenghtInDays(range: DateRange): number {
    return range.to.diff(range.from, 'day');
}

export function rangeLenghtInMonths(range: DateRange): number {
    return range.to.diff(range.from, 'month');
}

export function minDate(a?: moment.Moment, b?: moment.Moment): moment.Moment | undefined {
    if (!a && !b) {
        return undefined;
    }

    if (!a) {
        return b;
    }

    if (!b) {
        return a;
    }

    if (a.isBefore(b)) {
        return a;
    }

    return b;
}

export function maxDate(a?: moment.Moment, b?: moment.Moment): moment.Moment | undefined {
    if (!a && !b) {
        return undefined;
    }

    if (!a) {
        return b;
    }

    if (!b) {
        return a;
    }

    if (a.isBefore(b)) {
        return b;
    }

    return a;
}

export function rangeGranularity(range: DateRange): 'year' | 'month' | 'day' {
    const days = rangeLenghtInDays(range);
    const months = rangeLenghtInMonths(range);

    if (days <= 31) {
        return 'day';
    }

    if (months <= 12) {
        return 'month';
    }

    return 'year';
}
