import * as React from 'react';

import { Route, isSuperUser, isCompanyManager, isBranchManager, WebshopOrderShipment } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/webshops/shipments',
        main: () => import('./WebshopOrderShipmentsOverview'),
        isAuthorized: [isSuperUser, isCompanyManager, isBranchManager],
        preloadData: WebshopOrderShipment.overviewState.actions.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/webshops/shipments/:webshopId/:shipmentId',
        main: () => import('./ViewShipment'),
        isAuthorized: [isSuperUser, isCompanyManager, isBranchManager],
        preloadData: WebshopOrderShipment.detailsState.actions.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/webshops/orders/:webshopId/create-shipment',
        main: () => import('./CreateShipment'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/webshops/shipments/:webshopId/:shipmentId/edit',
        main: () => import('./UpdateShipment'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopOrderShipment.detailsState.actions.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/webshops/orders/:webshopId/:orderId/create-shipment',
        main: () => import('./CreateOrderShipment'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopOrderShipment.createShipmentState.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/webshops/shipments/:webshopId/:orderId/:shipmentId/edit',
        main: () => import('./UpdateOrderShipment'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopOrderShipment.updateShipmentState.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
];

export default routes;
