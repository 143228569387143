import * as React from 'react';

import { Domain } from 'api';
import { ISearchProvider } from 'utils';

import { timelineApi } from '@/api';

interface TimelineSearchProviderItem {
    value: Domain.Timeline['timelineId'];
    label: React.ReactNode;
    disabled: boolean;
}

type LabelRenderer = (timeline: Domain.Timeline) => React.ReactNode;

export class TimelineSearchProvider implements ISearchProvider<TimelineSearchProviderItem> {
    private searchCache: {
        [key: string]: Promise<Domain.TimelinesPage>;
    } = {};

    private baseByValueCache: {
        [key: string]: Domain.Timeline;
    } = {};

    private byValueCache: {
        [key: string]: Promise<Domain.Timeline>;
    } = {};

    private slideshowIdFilter: Domain.Slideshow['slideshowId'] | undefined;
    private requiredScreenResolution: Domain.DeviceScreenResolution;
    private labelRenderer: LabelRenderer = timeline => timeline.name;
    private hasMoreResults = false;

    resetCache() {
        this.searchCache = {};
        this.byValueCache = {};
    }

    reset() {
        this.slideshowIdFilter = undefined;
        this.requiredScreenResolution = '1080x1920';
        this.resetCache();
    }

    setBaseByValueCache(cache: Domain.Timeline[]) {
        this.baseByValueCache = {};

        for (const item of cache) {
            this.baseByValueCache[item.timelineId] = item;
        }
    }

    setSlideshowIdFilter(slideshowId: Domain.Slideshow['slideshowId']) {
        this.slideshowIdFilter = slideshowId;
        this.resetCache();
    }

    setRequiredScreenResolution(screenResolution: Domain.DeviceScreenResolution) {
        this.requiredScreenResolution = screenResolution;
        this.resetCache();
    }

    setLabelRenderer(labelRenderer: LabelRenderer) {
        this.labelRenderer = labelRenderer;
    }

    async search(query: string) {
        if (!this.slideshowIdFilter) {
            return [];
        }

        if (!this.searchCache[query + '-' + this.slideshowIdFilter]) {
            this.searchCache[query + '-' + this.slideshowIdFilter] = timelineApi.GetTimelines(
                this.slideshowIdFilter || '',
                { page: 1, size: 10 },
                { field: 'name', direction: 'ascending' },
                query,
            );

            this.searchCache[query + '-' + this.slideshowIdFilter].then(cachedTimelinesPage => {
                cachedTimelinesPage.items.forEach(timeline => {
                    if (!this.byValueCache[timeline.timelineId]) {
                        this.byValueCache[timeline.timelineId] = Promise.resolve(timeline);
                    }
                });
            });
        }
        const timelinesPage = await this.searchCache[query + '-' + this.slideshowIdFilter];

        return timelinesPage.items.sort((a, b) => a.positionInEditor - b.positionInEditor).map(this.mapTimeline);
    }

    // we do not support loading more results
    async loadMoreResults() {
        return [];
    }

    // We do not have this implemented yet in this search provider
    getHasMoreResults() {
        return this.hasMoreResults;
    }

    async byValue(value: string) {
        if (!value) {
            return;
        }

        if (this.baseByValueCache[value]) {
            return this.mapTimeline(this.baseByValueCache[value]);
        }

        if (!this.slideshowIdFilter) {
            return;
        }

        if (!this.byValueCache[value]) {
            this.byValueCache[value] = timelineApi.GetTimelineDetails(this.slideshowIdFilter, value);
        }

        const timeline = await this.byValueCache[value];
        return this.mapTimeline(timeline);
    }

    // we do not support multi-selects
    async byValues(_values: string[]) {
        return [];
    }

    private mapTimeline = (timeline: Domain.Timeline): TimelineSearchProviderItem => {
        return {
            value: timeline.timelineId,
            label: this.labelRenderer(timeline),
            disabled: timeline.screenResolution !== this.requiredScreenResolution,
        };
    };
}
