import React from 'react';

import moment, { Moment } from 'moment';

import { CalendarDayLabel } from './CalendarDayLabel';
import { ICalendarEvent } from './CalendarEvent';
import { EventsByDay } from './EventsByDay';
import { HoursColumn } from './HoursColumn';

import styles from './DayView.scss';

interface DayViewProps {
    currentDate: Moment;
    slotTimeInMinutes?: number;
    slotHeight?: number;
    events: ICalendarEvent[];
    gettext: (text: string) => string;
}

const DayView = ({ currentDate, events, slotTimeInMinutes = 15, slotHeight = 40 }: DayViewProps) => {
    const hours = Array.from({ length: 24 }, (_, i) => i);
    const slotNumber = 60 / slotTimeInMinutes;
    const slots = Array.from({ length: slotNumber }, (_, i) => i * slotTimeInMinutes);

    const scrollableRef = React.useRef<HTMLDivElement>(null);

    const eventsForDay = events.filter(event => moment(event.startDate).isSame(currentDate, 'day'));

    React.useEffect(() => {
        const currentHour = moment().hour();
        const isToday = currentDate.isSame(moment(), 'day');

        if (scrollableRef.current) {
            if (isToday) {
                const scrollPosition = currentHour * slotNumber * slotHeight;
                scrollableRef.current.scrollTop = scrollPosition;
            } else {
                //scroll to first appointment
                if (eventsForDay.length > 0) {
                    const firstAppointment = eventsForDay[0];
                    const startHour = moment.utc(firstAppointment.startDate).hour();
                    const scrollPosition = startHour * slotNumber * slotHeight;
                    scrollableRef.current.scrollTop = scrollPosition;
                }
            }
        }
    }, [slotNumber, slotHeight, slotTimeInMinutes, currentDate, eventsForDay]);
    return (
        <div
            className={styles.DayView}
            data-test-id="calendar-schedule-day-view"
        >
            <div className={styles.DayViewHeader}>
                <CalendarDayLabel date={currentDate} />
            </div>
            <div
                className={styles.DayColumns}
                ref={scrollableRef}
            >
                <HoursColumn
                    hours={hours}
                    slotNumber={slotNumber}
                    slotHeight={slotHeight}
                />
                <EventsByDay
                    day={currentDate}
                    hours={hours}
                    slotNumber={slotNumber}
                    slotHeight={slotHeight}
                    slots={slots}
                    slotTimeInMinutes={slotTimeInMinutes}
                    events={eventsForDay}
                />
            </div>
        </div>
    );
};

export default DayView;
