import * as React from 'react';

import { Route, isSuperUser, isPlatformAdministrator, isCompanyManager, isBranchManager, Media } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/media',
        main: () => import('./MediaItemsOverview'),
        isAuthorized: [isSuperUser, isCompanyManager, isBranchManager],
        preloadData: Media.overviewState.actions.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/media/new',
        main: () => import('./UploadMedia'),
        isAuthorized: [isPlatformAdministrator, isCompanyManager, isBranchManager],
        preloading: () => (
            <FullPage
                bodyType="data-table"
                hideDataTableToggleColumns={true}
                hideHeaderMenu={true}
                hideMainActions={true}
            />
        ),
    },
    {
        path: '/media/:mediaItemId',
        main: () => import('./EditMediaItem'),
        isAuthorized: [isSuperUser, isCompanyManager, isBranchManager],
        preloadData: Media.updateState.actions.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
];

export default routes;
