import * as React from 'react';

import { Preloader, Grid, Row, Col } from 'ui-components';

import BasicPagePreloader from '@/Layouts/BasicPage';

import BasicPageHeader from './BasicPageHeader';
import DataTable from './DataTable';
import Form from './Form';
import MainActions from './MainActions';

export default function BasicPage(props: {
    bodyType?: 'data-table' | 'form' | 'tabs-and-boxes';
    hideMainActions?: boolean;
    hideMainSearch?: boolean;
    hideDataTableToggleColumns?: boolean;
}) {
    let body;

    switch (props.bodyType) {
        case 'data-table':
            body = <DataTable hideToggleColumns={props.hideDataTableToggleColumns} />;
            break;
        case 'form':
            body = <Form />;
            break;
        case 'tabs-and-boxes':
            body = (
                <>
                    <div className="mb-9">
                        <Preloader
                            width="100%"
                            height={61}
                        />
                    </div>

                    <Grid
                        cols={10}
                        gutter={30}
                    >
                        <Row>
                            <Col span={4}>
                                <div className="mb-30">
                                    <Preloader
                                        width="100%"
                                        height={450}
                                    />
                                </div>

                                <div className="mb-30">
                                    <Preloader
                                        width="100%"
                                        height={150}
                                    />
                                </div>

                                <div className="mb-30">
                                    <Preloader
                                        width="100%"
                                        height={280}
                                    />
                                </div>

                                <div className="mb-30">
                                    <Preloader
                                        width="100%"
                                        height={280}
                                    />
                                </div>
                            </Col>

                            <Col span={6}>
                                <div className="mb-30">
                                    <Preloader
                                        width="100%"
                                        height={450}
                                    />
                                </div>

                                <div className="mb-30">
                                    <Preloader
                                        width="100%"
                                        height={250}
                                    />
                                </div>

                                <div className="mb-30">
                                    <Preloader
                                        width="100%"
                                        height={340}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Grid>
                </>
            );
            break;
        default:
            body = (
                <Preloader
                    width="100%"
                    height={600}
                />
            );
    }
    return (
        <BasicPagePreloader
            header={<BasicPageHeader />}
            mainActions={!props.hideMainActions ? <MainActions hideSearch={props.hideMainSearch} /> : null}
            body={body}
        />
    );
}
