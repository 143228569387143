import { Route } from 'data-store';

import appointmentTypeRoutes from './Appointment-types/routes';
import appointmentsRoutes from './Appointments/routes';
import landingPagesRoutes from './LandingPages/routes';
import orderReviewRoutes from './OrderReview/routes';
import unassignedWebshopRoutes from './UnassignedWebshops/routes';
import announcementRoutes from './WebshopAnnouncements/routes';
import availableColorThemesRoutes from './WebshopAvailableColorThemes/routes';
import webshopCartRestrictionsRoutes from './WebshopCartRestrictions/routes';
import webshopCustomerRoutes from './WebshopCustomers/routes';
import webshopDeliveryTimeRoutes from './WebshopDeliveryTimes/routes';
import webshopFaqCategoryRoutes from './WebshopFaqCategories/routes';
import webshopFaqCategoryLibraryRoutes from './WebshopFaqCategoriesLibrary/routes';
import webshopFaqItemRoutes from './WebshopFaqItems/routes';
import webshopFaqItemLibraryRoutes from './WebshopFaqItemsLibrary/routes';
import webshopLayoutRoutes from './WebshopLayout/routes';
import webshopLibraryNewsArticleRoutes from './WebshopLibraryNewsArticles/routes';
import webshopNewsArticleRoutes from './WebshopNewsArticles/routes';
import webshopNewsletterSubscriptionRoutes from './WebshopNewsletterSubscriptions/routes';
import webshopOrderRoutes from './WebshopOrders/routes';
import webshopOrdersExport from './WebshopOrdersExport/routes';
import webshopOrderShipmentRoutes from './WebshopOrderShipments/routes';
import webshopPageRoutes from './WebshopPages/routes';
import webshopPageLibraryRoutes from './WebshopPagesLibrary/routes';
import webshopPrescriptionRoutes from './WebshopPrescriptions/routes';
import webshopPromotionRoutes from './WebshopPromotions/routes';
import webshopRoutes from './Webshops/routes';
import webshopShippingMethodsRoutes from './WebshopShippingMethods/routes';
import webshopStockSubscriptionRoutes from './WebshopStockSubscriptions/routes';
import webshopTagRoutes from './WebshopTags/routes';

const routes: Route[] = [
    ...webshopRoutes,
    ...webshopPageRoutes,
    ...webshopPageLibraryRoutes,
    ...webshopPrescriptionRoutes,
    ...webshopOrderRoutes,
    ...webshopTagRoutes,
    ...webshopNewsArticleRoutes,
    ...webshopDeliveryTimeRoutes,
    ...webshopLayoutRoutes,
    ...webshopNewsletterSubscriptionRoutes,
    ...unassignedWebshopRoutes,
    ...webshopPromotionRoutes,
    ...webshopFaqCategoryRoutes,
    ...webshopFaqItemRoutes,
    ...webshopFaqItemLibraryRoutes,
    ...webshopFaqCategoryLibraryRoutes,
    ...webshopLibraryNewsArticleRoutes,
    ...webshopShippingMethodsRoutes,
    ...webshopCustomerRoutes,
    ...webshopOrderShipmentRoutes,
    ...webshopOrdersExport,
    ...landingPagesRoutes,
    ...webshopCartRestrictionsRoutes,
    ...orderReviewRoutes,
    ...announcementRoutes,
    ...availableColorThemesRoutes,
    ...webshopStockSubscriptionRoutes,
    ...appointmentsRoutes,
    ...appointmentTypeRoutes,
];

export default routes;
