import React from 'react';

import {
    DndContext,
    DragOverlay,
    closestCenter,
    useSensor,
    useSensors,
    PointerSensor,
    KeyboardSensor,
    UniqueIdentifier,
} from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates, SortableContext, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

interface SortableListProps<T> {
    items: T[];
    getItemId: (item: T) => UniqueIdentifier;
    renderItem: (item: T, index: number, isDragging: boolean) => React.ReactNode;
    onDragEnd: (newItems: T[]) => void;
}

function SortableList<T>({ items, getItemId, renderItem, onDragEnd }: SortableListProps<T>) {
    const [localItems, setLocalItems] = React.useState(items);
    const [activeId, setActiveId] = React.useState<UniqueIdentifier | null>(null);

    React.useEffect(() => {
        setLocalItems(items);
    }, [items]);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    const handleDragStart = (event: any) => {
        const { active } = event;
        setActiveId(active.id);
    };

    const handleDragEnd = (event: any) => {
        const { active, over } = event;
        setActiveId(null);
        console.log('Drag End - Active ID:', active.id, 'Over ID:', over?.id);

        if (active.id !== over?.id) {
            const oldIndex = items.findIndex(item => getItemId(item) === active.id);
            const newIndex = items.findIndex(item => getItemId(item) === over?.id);
            const newItems = arrayMove(items, oldIndex, newIndex);
            onDragEnd(newItems);
            setLocalItems(newItems);
        } else {
            setLocalItems(items);
        }
    };

    const handleDragCancel = () => {
        setActiveId(null);
        setLocalItems(items);
    };

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
        >
            <SortableContext
                items={localItems.map(getItemId)}
                strategy={verticalListSortingStrategy}
            >
                {localItems.map((item, index) => (
                    <SortableItem
                        key={getItemId(item)}
                        id={getItemId(item)}
                        index={index}
                        renderItem={renderItem}
                        data={item}
                    />
                ))}
            </SortableContext>
            <DragOverlay>
                {activeId ? (
                    <SortableItem
                        key={activeId}
                        id={activeId}
                        index={localItems.findIndex(item => getItemId(item) === activeId)}
                        renderItem={renderItem}
                        data={items.find(item => getItemId(item) === activeId)!}
                    />
                ) : null}
            </DragOverlay>
        </DndContext>
    );
}

interface SortableItemProps<T> {
    id: UniqueIdentifier;
    data: T;
    index: number;
    renderItem: (item: T, index: number, isDragging: boolean) => React.ReactNode;
}

function SortableItem<T>({ id, data, index, renderItem }: SortableItemProps<T>) {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id,
    });

    const style: React.CSSProperties = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0 : 1,
    };

    return (
        <div
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
            data-test-id={`sortable-item-${id}`}
        >
            {renderItem(data, index, false)}
        </div>
    );
}

export default SortableList;
