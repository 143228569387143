import * as React from 'react';

import { Route, isPlatformAdministrator, isReseller, Translation } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/localisation/translation-management/overview',
        main: () => import('./TranslationOverview'),
        isAuthorized: [isPlatformAdministrator, isReseller],
        preloadData: Translation.overviewState.load,
        preloading: () => (
            <FullPage
                bodyType="data-table"
                hideMainSearch={true}
                hideDataTableToggleColumns={true}
            />
        ),
    },
];

export default routes;
