/* eslint-disable local-rules/force-use-gettext */
import * as React from 'react';

import { Route, isSignedIn } from 'data-store';
import { Tx } from 'ui-components';

const routes: Route[] = [
    {
        path: '/feedback',
        main: async () => () => <Tx level="heading-2">feedback</Tx>,
        isAuthorized: isSignedIn,
    },
];

export default routes;
