import { ThunkAction } from '@/action';
import { webshopApi } from '@/api';
import * as categoryTreeState from '@/AvailableProduct/categoriesTreeState';
import { URLParams } from '@/routing';

export const load =
    (options: { urlParams: URLParams }): ThunkAction =>
    async dispatch => {
        const webshop = await webshopApi.GetWebshopDetails(options.urlParams.webshopId);
        await dispatch(
            categoryTreeState.actions.load({
                ...options,
                urlParams: {
                    locale: webshop.defaultLocale || webshop.locales[0],
                    ...options.urlParams,
                },
            }),
        );
    };
