import * as React from 'react';

import HeaderMenu from '@/Navigation/HeaderMenu';
import Notifications from '@/Navigation/Notifications';
import SidebarNavigation from '@/Navigation/SidebarNavigation';

import BasicPage from './BasicPage';
import Breadcrumbs from './Breadcrumbs';

import styles from '@/Layouts/MainNavigation.scss';

export default function FullPage(props: {
    bodyType?: 'data-table' | 'form' | 'tabs-and-boxes';
    hideSidebarMenu?: boolean;
    hideHeaderMenu?: boolean;
    hideBreadcrumbs?: boolean;
    hideMainActions?: boolean;
    hideMainSearch?: boolean;
    hideDataTableToggleColumns?: boolean;
}) {
    return (
        <>
            {!props.hideSidebarMenu ? <SidebarNavigation id="main-sidebar" /> : null}

            <div className={styles.MainNavigation}>
                {!props.hideHeaderMenu ? <HeaderMenu breadcrumbs={!props.hideBreadcrumbs ? <Breadcrumbs /> : null} /> : null}

                <BasicPage
                    bodyType={props.bodyType}
                    hideDataTableToggleColumns={props.hideDataTableToggleColumns}
                    hideMainActions={props.hideMainActions}
                    hideMainSearch={props.hideMainSearch}
                />
            </div>

            <Notifications />
        </>
    );
}
