import { Domain } from 'api';

import { webshopApi, deviceApi } from './api';

export interface WebshopOrDevice {
    webshopOrDeviceId: string;
    name: string;
    type: 'webshop' | 'vendingMachine';
    plausibleAnalyticsEmbedUrl?: string;
}

export async function loadWebshopOrDevice(
    id: Domain.Webshop['webshopId'] | Domain.Device['deviceId'],
): Promise<Domain.WebshopDetails | Domain.DeviceDetails> {
    if (id.indexOf('webshop-') === 0) {
        return webshopApi.GetWebshopDetails(id);
    } else {
        return deviceApi.GetDeviceDetails(id);
    }
}

export async function queryWebshopsAndDevices(
    ownership: Domain.Ownership,
    webshopsOnly = false,
    query: string | undefined,
    pageSize: number,
): Promise<(Domain.Webshop | Domain.Device)[]> {
    const webshopsQuery = webshopApi.GetWebshops(
        ownership,
        {
            page: 1,
            size: pageSize,
        },
        {
            field: 'name',
            direction: 'ascending',
        },
        query,
    );

    if (webshopsOnly) {
        return [...(await webshopsQuery).items];
    }

    const [webshops, devices] = await Promise.all([
        webshopsQuery,
        deviceApi.GetDevices(
            ownership,
            {
                page: 1,
                size: pageSize,
            },
            {
                field: 'name',
                direction: 'ascending',
            },
            query,
            {
                type: 'vending-machine',
            },
        ),
    ]);

    return [...webshops.items, ...devices.items];
}

export function mapWebshopOrDeviceDetailsToSalesChannel(item: Domain.WebshopDetails | Domain.DeviceDetails): WebshopOrDevice {
    if (isWebshop(item)) {
        return {
            webshopOrDeviceId: item.webshopId,
            name: item.name,
            type: 'webshop',
            plausibleAnalyticsEmbedUrl: item.analyticsEmbedUrl || undefined,
        };
    } else {
        return {
            webshopOrDeviceId: item.deviceId,
            name: item.name,
            type: 'vendingMachine',
        };
    }
}

export function mapWebshopOrDeviceToSalesChannel(item: Domain.Webshop | Domain.Device): WebshopOrDevice {
    if (isWebshop(item)) {
        return {
            webshopOrDeviceId: item.webshopId,
            name: item.name,
            type: 'webshop',
        };
    } else {
        return {
            webshopOrDeviceId: item.deviceId,
            name: item.name,
            type: 'vendingMachine',
        };
    }
}

export function isWebshop(salesChannel: Domain.Webshop | Domain.Device): salesChannel is Domain.Webshop {
    return (salesChannel as Domain.Webshop).hasOwnProperty('webshopId');
}
