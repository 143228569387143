import * as React from 'react';

import moment from 'moment';

import { DateRange, minDate, maxDate } from 'utils';

import { Calendar } from '@/dateTimePicker/datePicker/Calendar';
import { PrevNextMonth } from '@/dateTimePicker/datePicker/PrevNextMonth';
import { Grid, Row, Col } from '@/layout';

import styles from './DateRangePicker.scss';

export type DateRangePickerInteractionPhase = 'from' | 'to' | 'secondaryFrom' | 'secondaryTo';

export default function DateRangePicker(props: {
    value: DateRange;
    onChange: (value: DateRange) => void;
    secondaryValue?: DateRange;
    onSecondaryChange?: (value: DateRange) => void;
    interactionPhase: DateRangePickerInteractionPhase;
    onInteractionPhaseChange: (newInteractionPhase: DateRangePickerInteractionPhase) => void;
    min?: moment.Moment;
    max?: moment.Moment;
    selectedClassName?: string;
    secondarySelectedClassName?: string;
    overlappingSelectedClassName?: string;
}) {
    const [lastVisibleMonth, setLastVisibleMonth] = React.useState(maxDate(props.value.to, props.secondaryValue?.to)!.clone());

    const selectRange = (range: DateRange) => {
        props.onChange(range);
    };

    const selectSecondaryRange = (range: DateRange) => {
        if (props.onSecondaryChange) {
            props.onSecondaryChange(range);
        }
    };

    const selectEntireMonth = (monthDate: moment.Moment) => {
        if (props.max) {
            if (monthDate.clone().startOf('month').isAfter(props.max)) {
                return;
            }
        }

        if (props.interactionPhase === 'from' || props.interactionPhase === 'to') {
            selectRange({
                from: monthDate.clone().startOf('month'),
                to: minDate(monthDate.clone().endOf('month'), props.max)!,
            });

            if (props.secondaryValue) {
                props.onInteractionPhaseChange('secondaryFrom');
            } else {
                props.onInteractionPhaseChange('from');
            }
        } else {
            selectSecondaryRange({
                from: monthDate.clone().startOf('month'),
                to: minDate(monthDate.clone().endOf('month'), props.max)!,
            });
            props.onInteractionPhaseChange('from');
        }
    };

    const handleDatePick = (newDate: moment.Moment) => {
        if (props.interactionPhase === 'from') {
            selectRange({
                ...props.value,
                from: newDate,
            });
            props.onInteractionPhaseChange('to');
        } else if (props.interactionPhase === 'to') {
            selectRange({
                ...props.value,
                to: newDate,
            });

            if (!props.secondaryValue) {
                props.onInteractionPhaseChange('from');
            } else {
                props.onInteractionPhaseChange('secondaryFrom');
            }
        } else if (props.interactionPhase === 'secondaryFrom') {
            if (props.secondaryValue) {
                selectSecondaryRange({
                    ...props.secondaryValue,
                    from: newDate,
                });
            }
            props.onInteractionPhaseChange('secondaryTo');
        } else if (props.interactionPhase === 'secondaryTo') {
            if (props.secondaryValue) {
                selectSecondaryRange({
                    ...props.secondaryValue,
                    to: newDate,
                });
            }
            props.onInteractionPhaseChange('from');
        }
    };

    let min = props.min;
    const max = props.max;
    if (props.interactionPhase === 'to') {
        min = minDate(min, props.value.from);
    } else if (props.interactionPhase === 'secondaryTo') {
        min = minDate(min, props.secondaryValue ? props.secondaryValue.from : undefined);
    }

    return (
        <Grid
            cols={3}
            gutter={24}
            className={styles.DateRangePicker}
        >
            <Row>
                <Col span={3}>
                    <PrevNextMonth onChange={offset => setLastVisibleMonth(lastVisibleMonth.clone().add(offset, 'months'))} />
                </Col>
            </Row>
            <Row>
                {[2, 1, 0].map(months => (
                    <Col
                        span={1}
                        key={months}
                    >
                        <Calendar
                            visibleMonth={lastVisibleMonth.clone().subtract(months, 'month')}
                            selection={props.value}
                            selectedClassName={props.selectedClassName}
                            secondarySelection={props.secondaryValue}
                            secondarySelectedClassName={props.secondarySelectedClassName}
                            overlappingSelectedClassName={props.overlappingSelectedClassName}
                            onHeaderClick={selectEntireMonth}
                            min={min}
                            max={max}
                            onChange={handleDatePick}
                        />
                    </Col>
                ))}
            </Row>
        </Grid>
    );
}
