import * as React from 'react';

import { Route, isSignedIn, Invoice } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/invoices',
        main: () => import('./InvoicesOverview'),
        isAuthorized: isSignedIn,
        preloadData: Invoice.overviewState.actions.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
];

export default routes;
