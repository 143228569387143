import * as React from 'react';

import classNames from 'classnames';

import { chunkArray } from '@/core';
import { Grid, Row, Col } from '@/layout';
import { Tx } from '@/typography';

import styles from './DataGrid.scss';

export default function DataGrid<T = any>(props: {
    items: T[];
    className?: string;
    getItemKey: (item: T, index: number) => string | number;
    getItemDataTestId?: (item: T, index: number) => string;
    itemRenderer: (item: T) => React.ReactNode;
    onItemClick?: (event: React.MouseEvent, item: T, index: number) => void;
    itemIsActive?: (item: T, index: number) => boolean;
}) {
    if (props.items.length === 0) {
        return null;
    }

    const handleItemClick = (event: React.MouseEvent, item: T, index: number) => {
        if (props.onItemClick) {
            props.onItemClick(event, item, index);
        }
    };

    return (
        <Grid
            cols={4}
            className={styles.DataGrid + ' p-0'}
        >
            {chunkArray(props.items, 4).map((chunk, index) => {
                return (
                    <Row key={index}>
                        {chunk.map(item => {
                            const itemKey = props.getItemKey(item, index);
                            const itemTestId = props.getItemDataTestId ? props.getItemDataTestId(item, index) : itemKey;

                            return (
                                <Col
                                    key={itemKey}
                                    span={1}
                                >
                                    <Tx
                                        as="div"
                                        className={classNames(
                                            styles.DataGridItem,
                                            props.itemIsActive && props.itemIsActive(item, index) ? 'active' : undefined,
                                        )}
                                        onClick={e => handleItemClick(e, item, index)}
                                        data-test-id={itemTestId}
                                    >
                                        {props.itemRenderer(item)}
                                    </Tx>
                                </Col>
                            );
                        })}
                    </Row>
                );
            })}
        </Grid>
    );
}
