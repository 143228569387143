export function videoValidator(videoUrl: string): boolean {
    const youtubeMatcher =
        /(?:youtube(?:-nocookie)?\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=|shorts\/)|youtu\.be\/)([^"&?/\s]{11})/i;
    const vimeoMatcher = /vimeo\.com\/(\w+\s*\/?)*([0-9]+)*/i;
    return youtubeMatcher.test(videoUrl) || vimeoMatcher.test(videoUrl);
}

export function getEmbedUrlFromVideoUrl(videoUrl: string): string {
    let id: string | undefined;
    let type: 'vimeo' | 'youtube' | undefined;
    let vimeoRegex: RegExp;

    if (videoUrl.match(/youtube\.com|youtu\.be|youtube-nocookie.com/)) {
        id = videoUrl.match(/(?:youtube\.com\/(?:shorts\/|embed\/|v\/|e\/|vi\/|watch\?v=|&v=)|youtu\.be\/)([^"&?/\s]{11})/i)?.[1];
        type = 'youtube';
    } else if (videoUrl.match(/vimeo\.com/)) {
        type = 'vimeo';
        vimeoRegex = new RegExp(
            [
                'https?:\\/\\/(?:www\\.|player\\.)?vimeo.com\\/(?:channels\\/(?:\\w+\\/)',
                '?|groups\\/([^\\/]*)\\/videos\\/|album\\/(\\d+)\\/video\\/|video\\/|)(\\d+)(?:$|\\/|\\?)',
            ].join(''),
        );
        id = videoUrl.match(vimeoRegex)![3];
    }

    if (!id) {
        return '';
    }

    if (type === 'vimeo') {
        return `https://player.vimeo.com/video/${id}`;
    } else if (type === 'youtube') {
        return `https://www.youtube.com/embed/${id}`;
    }

    return '';
}
