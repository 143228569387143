import * as React from 'react';

import { Domain } from 'api';
import { ISearchProvider } from 'utils';

import { customerEmailAddressApi } from '@/api';

interface CustomerEmailAddressSearchProviderItem {
    value: string;
    label: React.ReactNode;
}

export class CustomerEmailAddressSearchProvider implements ISearchProvider<CustomerEmailAddressSearchProviderItem> {
    private branchId: string;

    private searchCache: {
        [key: string]: Promise<Domain.CustomerEmailAddressList>;
    } = {};

    resetCache() {
        this.searchCache = {};
    }

    reset() {
        this.resetCache();
    }

    setBranchId(branchId: string) {
        if (this.branchId === branchId) {
            return;
        }

        this.branchId = branchId;
        this.resetCache();
    }

    async search(query: string) {
        if (!this.branchId) {
            return [];
        }

        const cacheKey = query + '-' + this.branchId;

        if (!this.searchCache[cacheKey]) {
            this.searchCache[cacheKey] = customerEmailAddressApi.GetEmailAddresses(this.branchId, query);
        }
        const customerEmailAddresssPage = await this.searchCache[cacheKey];

        return customerEmailAddresssPage.map(customerEmailAddress => {
            return {
                value: customerEmailAddress,
                label: customerEmailAddress,
            };
        });
    }

    async byValue(value: string) {
        return {
            value,
            label: value,
        };
    }

    // we do not support loading more results
    async loadMoreResults() {
        return [];
    }

    // We do not have this implemented yet in this search provider
    getHasMoreResults() {
        return false;
    }

    async byValues(values: string[]) {
        return values.map(value => {
            return {
                value,
                label: value,
            };
        });
    }
}
