import { Route } from 'data-store';

import apiKeyRoutes from './ApiKeys/routes';
import branchRoutes from './Branches/routes';
import companyRoutes from './Companies/routes';
import deviceRoutes from './Devices/routes';
import resellerRoutes from './Resellers/routes';
import sharingGroupRoutes from './SharingGroups/routes';
import userRoutes from './Users/routes';

const routes: Route[] = [
    ...userRoutes,
    ...companyRoutes,
    ...branchRoutes,
    ...deviceRoutes,
    ...sharingGroupRoutes,
    ...resellerRoutes,
    ...apiKeyRoutes,
];

export default routes;
