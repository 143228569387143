import * as React from 'react';

import { Route, isCompanyManager, isBranchManager, anyAuthorization, Pickup } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/reservations',
        main: () => import('./PickupsOverview'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: Pickup.overviewState.actions.load,
        preloading: () => <FullPage />,
    },
    {
        path: '/reservations/incomplete-reservations-report',
        main: () => import('./IncompletePickupsReport'),
        isAuthorized: isBranchManager,
        preloadData: Pickup.incompleteReportState.load,
        preloading: () => <FullPage />,
    },
    {
        path: '/reservations/new',
        main: () => import('./CreatePickup'),
        isAuthorized: [isBranchManager],
        preloadData: Pickup.createState.actions.load,
        preloading: () => <FullPage />,
    },
    {
        path: '/reservations/new-for-order/:salesChannelId/:orderId',
        main: () => import('./CreatePickupForOrder'),
        isAuthorized: [isBranchManager],
        preloadData: Pickup.createState.actions.load,
        preloading: () => <FullPage />,
    },
    {
        path: '/reservations/overbox-label/:eanCode/:paperWidth/:paperHeight/:codeWidth/:codeHeight/:codeLeft/:codeTop',
        main: () => import('./OverboxLabel'),
        isAuthorized: anyAuthorization,
        preloading: () => <FullPage />,
    },
    {
        path: '/reservations/:pickupId',
        main: () => import('./ViewPickup'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: Pickup.detailsState.actions.load,
        preloading: () => <FullPage />,
    },
];

export default routes;
