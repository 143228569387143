import * as React from 'react';

import { Route, isBranchManager, Device } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/vending-machines/backside-stocking',
        main: () => import('./BacksideStocking'),
        isAuthorized: [isBranchManager],
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
        preloadData: Device.globalSelectionState.SelectMTCollectDevice,
    },
];

export default routes;
