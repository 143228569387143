import * as React from 'react';
import { NavLink as RouterNavLink, useLocation } from 'react-router-dom';

import classNames from 'classnames';
import * as Cookies from 'js-cookie';

import { Icon, Tx } from 'ui-components';

import MegaMenu from './MegaMenu';

import styles from './SidebarNavigation.scss';

interface BaseMenuItem {
    key: string;
    label: string | React.ReactNode;
    to: string;
    className?: string;
}

export type MegaMenuItem = BaseMenuItem & {
    icon: React.ReactNode;
    description: string | React.ReactNode;
};

type TopLevelMenuItem = BaseMenuItem & {
    icon: React.ReactNode;
    children?: MegaMenuItem[];
};

export type SidebarNavigationMenuItem = TopLevelMenuItem;

const COLLAPSED_VIEW_COOKIE_NAME = 'sidebarCollapsedView';

export default function SidebarNavigation(props: { menuItems: SidebarNavigationMenuItem[]; id?: string }) {
    const location = useLocation();
    const [hoveredMenu, setHoveredMenu] = React.useState<string | undefined>(undefined);
    const [menuTopPositions, setMenuTopPositions] = React.useState<{
        [key: string]: {
            top?: number;
            height?: number;
        };
    }>({});
    const [collapsedView, setCollapsedView] = React.useState(Cookies.get(COLLAPSED_VIEW_COOKIE_NAME) === 'yes');
    const [mobileViewOpened, setMobileViewOpened] = React.useState(false);

    React.useEffect(() => {
        setMobileViewOpened(false);
    }, [location]);

    const handleDetailedViewToggleClick = (event: React.MouseEvent): void => {
        event.preventDefault();

        setCollapsedView(!collapsedView);
        setMobileViewOpened(!mobileViewOpened);
        Cookies.set(COLLAPSED_VIEW_COOKIE_NAME, !collapsedView ? 'yes' : 'no');
    };

    const renderMenu = () => {
        return <div className={styles.TopNavigation}>{(props.menuItems as any).map(renderTopLevelMenuItem)}</div>;
    };

    const getTopOfChildrenMenu = (itemKey: string) => {
        const item = menuTopPositions[itemKey];

        if (!item || item.top === undefined || item.height === undefined) {
            return '0px';
        }

        const bottom = item.top + item.height + 20;
        if (bottom > window.innerHeight) {
            return window.innerHeight - bottom + 'px';
        }

        return '0px';
    };

    const renderTopLevelMenuItem = (item: TopLevelMenuItem): React.ReactNode => {
        return (
            <Tx
                key={item.key}
                className={`${styles.TopNavigationItem} ${hoveredMenu === item.key ? styles.TopNavigationItemActive : ''} ${item.className || ''}`}
                onMouseEnter={() => setHoveredMenu(item.key)}
                onClick={(e: React.MouseEvent) => {
                    if (item.children) {
                        e.preventDefault();
                    }
                    setHoveredMenu(item.key);
                }}
                onMouseLeave={() => setHoveredMenu(undefined)}
            >
                {!item.children ? (
                    <RouterNavLink to={item.to}>
                        {item.icon}

                        <div className={styles.TopNavigationItemLabel}>
                            <Tx>{item.label}</Tx>
                        </div>
                    </RouterNavLink>
                ) : (
                    <div
                        ref={element => {
                            if (element && (menuTopPositions[item.key] === undefined || menuTopPositions[item.key].top === undefined)) {
                                const box = element.getBoundingClientRect();
                                setMenuTopPositions({
                                    ...menuTopPositions,
                                    [item.key]: {
                                        ...menuTopPositions[item.key],
                                        top: box.top,
                                    },
                                });
                            }
                        }}
                    >
                        {item.icon}

                        <div className={styles.TopNavigationItemLabel}>
                            <Tx>{item.label}</Tx>
                            <Icon
                                type="action_arrow_right"
                                className={`${styles.TopNavigationItemIcon} hidden-sm`}
                            />
                        </div>

                        <div
                            className={styles.TopNavigationItemChildren}
                            style={{
                                top: getTopOfChildrenMenu(item.key),
                            }}
                            ref={element => {
                                if (
                                    element &&
                                    (menuTopPositions[item.key] === undefined || menuTopPositions[item.key].height === undefined)
                                ) {
                                    const box = element.getBoundingClientRect();
                                    setMenuTopPositions({
                                        ...menuTopPositions,
                                        [item.key]: {
                                            ...menuTopPositions[item.key],
                                            height: box.height,
                                        },
                                    });
                                }
                            }}
                        >
                            <MegaMenu
                                menuItems={item.children as MegaMenuItem[]}
                                className={styles.TopNavigationItemMegaMenu}
                            />
                        </div>
                    </div>
                )}
            </Tx>
        );
    };

    return (
        <div
            id={props.id}
            className={classNames(
                styles.SidebarNavigation,
                !collapsedView ? styles.DetailedViewOpened : null,
                mobileViewOpened ? styles.MobileViewOpened : null,
            )}
        >
            <RouterNavLink
                to="/"
                className={`${styles.AppIcon} hidden-sm`}
            >
                <img
                    src="/static/images/logo-1.svg"
                    alt=""
                />
            </RouterNavLink>

            {renderMenu()}

            <a
                href=""
                className={styles.DetailedViewToggleButton}
                onClick={handleDetailedViewToggleClick}
            >
                {!collapsedView ? (
                    <Icon
                        type="action_left"
                        className="hidden-sm"
                    />
                ) : (
                    <Icon
                        type="action_right"
                        className="hidden-sm"
                    />
                )}
                <span className={`${!mobileViewOpened ? 'collapsed' : ''} hidden-md`}>
                    <img
                        src="/static/images/logo-1.svg"
                        alt=""
                    />
                    <span>
                        <Icon type="action_remove" />
                    </span>
                </span>
            </a>

            <div className={`${styles.DetailedView} hidden-sm`}>
                <RouterNavLink
                    to="/"
                    className={`${styles.AppTitle} hidden-sm`}
                >
                    <img
                        src="/static/images/logo-2.svg?v=1.0"
                        alt="Lochting"
                    />
                </RouterNavLink>
            </div>
        </div>
    );
}
