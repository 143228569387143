import * as React from 'react';

import { Route, isCompanyManager, isBranchManager } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/vending-machines/configuration',
        main: () => import('./ConfigurationLandingPage'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloading: () => <FullPage hideMainSearch={true} />,
    },
];

export default routes;
