import React from 'react';

import moment, { Moment } from 'moment';

import { CalendarEvent, ICalendarEvent } from './CalendarEvent';
import { CurrentTimePosition } from './CurrentTimePosition';

import styles from './EventsByDay.scss';

export const EventsByDay = ({
    day,
    hours,
    slotNumber,
    slotHeight,
    slots,
    slotTimeInMinutes,
    events,
}: {
    day: Moment;
    hours: number[];
    slotNumber: number;
    slotHeight: number;
    slots: number[];
    slotTimeInMinutes: number;
    events: ICalendarEvent[];
}) => {
    const isToday = day.isSame(moment(), 'day');
    const overlappingEventsGroups = findOverlappingGroups(events);
    return (
        <div className={styles.DayColumn}>
            {hours.map(hour => (
                <div
                    key={hour}
                    className={styles.HourCell}
                    style={{ height: `${slotNumber * slotHeight}px` }}
                >
                    {isToday && hour === moment().hour() && (
                        <CurrentTimePosition position={(moment().minutes() / slotTimeInMinutes) * slotHeight} />
                    )}

                    {overlappingEventsGroups.map(events => {
                        return events.map((event, eventIndex) => {
                            const eventStart = moment(event.startDate);
                            const durationInSlots = event.duration / slotTimeInMinutes;
                            const height = durationInSlots * slotHeight - 2;
                            const eventWidth = 100 / events.length;
                            const eventLeft = (100 / events.length) * eventIndex;
                            const startPosition = (eventStart.minute() / slotTimeInMinutes) * slotHeight;
                            return (
                                moment.utc(event.startDate).hour() === hour && (
                                    <CalendarEvent
                                        event={event}
                                        key={eventIndex}
                                        height={height}
                                        eventWidth={eventWidth}
                                        eventLeft={eventLeft}
                                        startPosition={startPosition}
                                    />
                                )
                            );
                        });
                    })}
                    {slots.map((_, index) => {
                        return (
                            <div
                                key={index}
                                className={styles.Slot}
                                style={{ height: `${slotHeight}px` }}
                            />
                        );
                    })}
                </div>
            ))}
        </div>
    );
};

function findOverlappingGroups(events: ICalendarEvent[]) {
    const ranges = events.map(event => {
        const start = moment(event.startDate);
        const end = start.clone().add(event.duration, 'minutes');
        return {
            event,
            start,
            end,
        };
    });

    return ranges.reduce((groups, current) => {
        if (groups.flat().some(event => event.id === current.event.id)) {
            return groups;
        }

        const overlappingEvents = ranges
            .filter(item => item !== current && item.start.isBefore(current.end) && item.end.isAfter(current.start))
            .map(item => item.event);

        groups.push([current.event, ...overlappingEvents]);
        return groups;
    }, [] as ICalendarEvent[][]);
}
