import * as React from 'react';

import { Route, isBranchManager, Appointments } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/webshops/appointments/overview/calendar',
        main: () => import('./AppointmentsOverview'),
        isAuthorized: isBranchManager,
        preloadData: Appointments.calendarViewState.actions.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/webshops/appointments/overview/list',
        main: () => import('./AppointmentsOverview'),
        isAuthorized: isBranchManager,
        preloadData: Appointments.overviewState.actions.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/webshops/appointments/details/:appointmentId',
        main: () => import('./ViewAppointment'),
        isAuthorized: isBranchManager,
        preloadData: Appointments.detailsState.actions.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/webshops/appointments/new',
        main: () => import('./MakeAppointment'),
        isAuthorized: [isBranchManager],
        preloadData: Appointments.createState.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
];

export default routes;
