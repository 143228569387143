import * as React from 'react';

import { Card } from '@/card';
import color from '@/color';
import { Dropdown } from '@/core';
import { Icon } from '@/icon';
import { Menu, MenuItem } from '@/menu';

import Button, { ButtonVariant } from './Button';

export default function LinksDropdown(props: {
    className?: string;
    label: React.ReactNode;
    disabled?: boolean;
    items: {
        href: string;
        target?: string;
        label: React.ReactNode;
    }[];
    buttonVariant?: ButtonVariant;
    'data-test-id'?: string;
}) {
    const { items, className, label, disabled, ...rest } = props;
    const dropdown = React.useRef<Dropdown>(null);

    return (
        <Dropdown
            ref={dropdown}
            className={className}
            disabled={disabled}
            headerRenderer={(isOpen: boolean) => (
                <Button
                    variant={props.buttonVariant ? props.buttonVariant : 'tertiary'}
                    variantSize="s"
                    disabled={disabled}
                    endIcon={
                        <Icon
                            iconSize="m"
                            type={isOpen ? 'action_arrow_up' : 'action_arrow_down'}
                            className={props.buttonVariant && props.buttonVariant === 'primary' ? color.White : color.Grey.Dark2}
                        />
                    }
                    {...rest}
                >
                    {label}
                </Button>
            )}
            body={
                <Card
                    hSpacing="none"
                    vSpacing="none"
                    elevated={true}
                    className="mt-11"
                >
                    <Menu>
                        {items.map(item => (
                            <MenuItem key={item.href}>
                                <a
                                    href={item.href}
                                    target={item.target}
                                    onClick={() => (dropdown.current ? dropdown.current.close() : null)}
                                >
                                    {item.label}
                                </a>
                            </MenuItem>
                        ))}
                    </Menu>
                </Card>
            }
        />
    );
}
