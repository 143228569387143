import * as React from 'react';

import { Domain } from 'api';
import { ISearchProvider } from 'utils';

import { organisationApi } from '@/api';

interface OrganisationSearchProviderItem {
    value: Domain.Organisation['organisationId'];
    label: React.ReactNode;
}

type LabelRenderer = (value: Domain.Organisation) => React.ReactNode;

export class OrganisationSearchProvider implements ISearchProvider<OrganisationSearchProviderItem> {
    private locale: Domain.Locale;
    private labelRenderer: LabelRenderer = x => x.organisationId;
    private hasMoreResults = false;

    private searchCache: {
        [key: string]: Promise<Domain.OrganisationsPage>;
    } = {};

    private byValueCache: {
        [key: string]: Promise<Domain.Organisation>;
    } = {};

    resetCache() {
        this.searchCache = {};
        this.byValueCache = {};
    }

    reset() {
        this.resetCache();
    }

    setLocale(locale: Domain.Locale) {
        if (this.locale === locale) {
            return;
        }

        this.locale = locale;
        this.resetCache();
    }

    setLabelRenderer(labelRenderer: LabelRenderer) {
        this.labelRenderer = labelRenderer;
    }

    async search(query: string) {
        const cacheKey = query + '-' + this.locale;

        if (!this.searchCache[cacheKey]) {
            this.searchCache[cacheKey] = organisationApi.GetOrganisations({ page: 1, size: 10 }, this.locale, query);

            this.searchCache[cacheKey].then(cachedOrganisationsPage => {
                cachedOrganisationsPage.items.forEach(organisation => {
                    if (!this.byValueCache[organisation.organisationId]) {
                        this.byValueCache[organisation.organisationId] = Promise.resolve(organisation);
                    }
                });
            });
        }
        const organisationsPage = await this.searchCache[cacheKey];

        return organisationsPage.items.map(organisation => {
            return {
                value: organisation.organisationId,
                label: this.organisationLabel(organisation),
            };
        });
    }

    async byValue(value: string) {
        if (!value) {
            return;
        }

        if (!this.byValueCache[value]) {
            this.byValueCache[value] = organisationApi.GetOrganisationDetails(value);
        }

        const organisationDetails = await this.byValueCache[value];
        return {
            value: organisationDetails.organisationId,
            label: this.organisationLabel(organisationDetails),
        };
    }

    // we do not support loading more results
    async loadMoreResults() {
        return [];
    }

    // We do not have this implemented yet in this search provider
    getHasMoreResults() {
        return this.hasMoreResults;
    }

    async byValues(values: string[]) {
        if (values.length === 0 || !this.locale) {
            return [];
        }

        const organisationIds = values.join(',');
        const cacheKey = organisationIds + '-' + this.locale;

        if (!this.searchCache[cacheKey]) {
            this.searchCache[cacheKey] = organisationApi.GetOrganisations({ page: 1, size: values.length }, this.locale, '', {
                organisationIds,
            });

            this.searchCache[cacheKey].then(cachedOrganisationsPage => {
                cachedOrganisationsPage.items.forEach(organisation => {
                    if (!this.byValueCache[organisation.organisationId]) {
                        this.byValueCache[organisation.organisationId] = Promise.resolve(organisation);
                    }
                });
            });
        }
        const organisationsPage = await this.searchCache[cacheKey];

        return organisationsPage.items.map(organisation => {
            return {
                value: organisation.organisationId,
                label: this.organisationLabel(organisation),
            };
        });
    }

    private organisationLabel(organisation: Domain.Organisation) {
        return this.labelRenderer(organisation);
    }
}
