import React from 'react';

import styles from './CurrentTimePosition.scss';

export const CurrentTimePosition = ({ position }: { position: number }) => {
    return (
        <div
            className={styles.CurrentTimeLine}
            style={{
                top: `${position}px`,
            }}
        />
    );
};
