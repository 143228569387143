import * as React from 'react';

import { Domain } from 'api';
import { getCountryCodeFromLocale, COUNTRY_CONFIG } from 'data-store';
import { Menu, Card, Dropdown } from 'ui-components';

import CountryLabel from '@/Pages/Localisation/Components/CountryLabel';
import LanguageLabel from '@/Pages/Localisation/Components/LanguageLabel';

export function getLanguageSwitcherMenuItems(availableLocales: Domain.Locale[]) {
    const menuItems: Menu['props']['items'] = [];
    const availableCountriesAndLocales: Partial<Domain.AvailableCountries> = {};

    for (const availableLocale of availableLocales) {
        const country = getCountryCodeFromLocale(availableLocale);
        if (!availableCountriesAndLocales.hasOwnProperty(country)) {
            availableCountriesAndLocales[country] = {
                ...COUNTRY_CONFIG[country],
                locales: [],
            };
        }

        availableCountriesAndLocales[country]!.locales.push(availableLocale);
    }

    for (const country in availableCountriesAndLocales) {
        if (!availableCountriesAndLocales.hasOwnProperty(country)) {
            continue;
        }

        if (Object.keys(availableCountriesAndLocales).length > 1) {
            menuItems.push({
                label: <CountryLabel country={country as Domain.Country} />,
                value: country,
                disabled: true,
            });
        }

        const locales = availableCountriesAndLocales[country as Domain.Country]?.locales;

        if (locales) {
            for (const locale of locales) {
                if (locale === 'de_BE') {
                    // no de_BE aywhere
                    continue;
                }

                menuItems.push({
                    label: <LanguageLabel locale={locale} />,
                    value: locale,
                });
            }
        }
    }

    return menuItems;
}

export function getHeaderLanguageSwitcherMenuItems(
    availableLocales: Domain.Locale[],
    onPick: (locale: Domain.Locale) => void,
    dropdown: React.RefObject<Dropdown>,
) {
    const menuItems: Menu['props']['items'] = [];
    const availableCountriesAndLocales: Partial<Domain.AvailableCountries> = {};

    for (const availableLocale of availableLocales) {
        const country = getCountryCodeFromLocale(availableLocale);
        if (!availableCountriesAndLocales.hasOwnProperty(country)) {
            availableCountriesAndLocales[country] = {
                ...COUNTRY_CONFIG[country],
                locales: [],
            };
        }

        availableCountriesAndLocales[country]!.locales.push(availableLocale);
    }

    for (const country in availableCountriesAndLocales) {
        if (!availableCountriesAndLocales.hasOwnProperty(country)) {
            continue;
        }

        const locales = availableCountriesAndLocales[country as Domain.Country]!.locales;
        const subMenuItems: Menu['props']['items'] = [];

        if (locales) {
            for (const locale of locales) {
                if (locale === 'de_BE') {
                    continue;
                }

                if (Object.keys(availableCountriesAndLocales).length > 1) {
                    subMenuItems.push({
                        label: <LanguageLabel locale={locale} />,
                        value: locale,
                        action: (_1, item) => {
                            if (dropdown.current) {
                                dropdown.current.close(true);
                            }
                            onPick(item.value as Domain.Locale);
                        },
                    });
                } else {
                    menuItems.push({
                        label: <LanguageLabel locale={locale} />,
                        value: locale,
                        action: (_1, item) => {
                            if (dropdown.current) {
                                dropdown.current.close(true);
                            }
                            onPick(item.value as Domain.Locale);
                        },
                    });
                }
            }
        }

        if (Object.keys(availableCountriesAndLocales).length > 1) {
            menuItems.push({
                label: <CountryLabel country={country as Domain.Country} />,
                value: country,
                submenu: (
                    <Card
                        hSpacing="none"
                        vSpacing="none"
                        elevated={false}
                    >
                        <Menu items={subMenuItems} />
                    </Card>
                ),
            });
        }
    }

    return menuItems;
}
