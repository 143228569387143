import * as React from 'react';

import { Icon } from '@/icon';

import styles from './Modal.scss';

export default function ModalHeader({
    withCloseAction,
    onClose,
    children,
}: {
    withCloseAction?: boolean;
    onClose?: () => void;
    children?: React.ReactNode;
}) {
    const handleClose = (event: React.MouseEvent) => {
        event.preventDefault();
        if (onClose) {
            onClose();
        }
    };

    return (
        <div className={styles.ModalHeader}>
            {children}
            {withCloseAction ? (
                <a
                    href="#"
                    onClick={handleClose}
                    className={styles.ModalCloseAction}
                >
                    <Icon type="action_remove" />
                </a>
            ) : null}
        </div>
    );
}
