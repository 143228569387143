import * as React from 'react';

import { Domain } from 'api';
import { useGettext } from 'data-store';
import { Tx } from 'ui-components';

export default function NotificationType(props: { notification: Domain.Notification }) {
    const { gettext } = useGettext();

    let notificationType;

    switch (props.notification.notificationType) {
        case 'orderWasCreated':
        case 'webshopConfigWasUpdated':
        case 'webshopWasUpdated':
        case 'orderWasBackOrdered':
        case 'orderBackOrderStatusWasUpdated':
        case 'shipmentServiceWebhookDataWasUpdated':
        case 'invoiceWasNumbered':
        case 'orderRequiresPrescriptionReview':
        case 'appointmentWasCreated':
        case 'appointmentWasCanceled': {
            notificationType = gettext('Webshop notification');
            break;
        }
        case 'configuredIntegrationWasActivated':
        case 'configuredIntegrationWasDeactivated':
        case 'configuredIntegrationWasUpdated': {
            notificationType = gettext('Integration notification');
            break;
        }
        case 'scheduleWasUpdated': {
            notificationType = gettext('Schedule notification');
            break;
        }
        case 'branchSlideshowWasUpdated':
        case 'companySlideshowWasUpdated':
        case 'slideshowWasShared':
        case 'branchProductWallContentWasUpdated':
        case 'companyProductWallContentWasUpdated':
        case 'productWallWasShared':
        case 'branchCustomPageContentWasUpdated':
        case 'companyCustomPageContentWasUpdated':
        case 'customPageWasShared': {
            notificationType = gettext('Presentation notification');
            break;
        }
        case 'productsOutOfStock':
        case 'productsBackInStock':
        case 'availableProductWasUpdated':
        case 'productSelectionStatusWasUpdated':
        case 'productStockSubscriptionWasAdded':
        case 'lockerHasProductsThatCannotBeSold': {
            notificationType = gettext('Products notification');
            break;
        }
        case 'importedPriceWasUpdated': {
            notificationType = gettext('Price notification');
            break;
        }
        case 'orderExportToIntegrationDataWasUpdated':
        case 'exportWasCompleted':
        case 'prescriptionWasUploaded':
        case 'productExportWasCompleted': {
            notificationType = gettext('Export notification');
            break;
        }
        case 'sharingGroupCompanyWasInvited':
        case 'mediaWasShared': {
            notificationType = gettext('Media notification');
            break;
        }
        case 'vendingMachineHomeScreenProductWasRemoved':
        case 'reservationAddToRobotFailed':
        case 'lockerLeftUnlocked':
        case 'robotEmergencyStopWasTriggered': {
            notificationType = gettext('Matic notification');
            break;
        }
        case 'libraryArticleWasCreated': {
            notificationType = gettext('Book notification');
            break;
        }
        case 'userWasSubscribedToNewsletter': {
            notificationType = gettext('Subcription notification');
            break;
        }
    }
    return (
        <Tx
            level="body-xs"
            sx={{
                color: '--content-tertiary',
            }}
        >
            {notificationType}
        </Tx>
    );
}
