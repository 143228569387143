import { Domain } from 'api';

export function getPreviewScreenSizeInPX(screenResolution: Domain.DeviceScreenResolution): {
    width: number;
    height: number;
} {
    const size = {
        width: 414,
        height: 736,
    };

    if (screenResolution === '1920x1080') {
        size.width = 736;
        size.height = 490;
    }

    if (screenResolution === '1280x1024') {
        size.width = 490;
        size.height = 392;
    }
    if (screenResolution === '2112x1048') {
        size.width = 480;
        size.height = 240;
    }

    return size;
}
