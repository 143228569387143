import * as React from 'react';

import { Route, isCompanyManager, isBranchManager, isSuperUser, Fraank, Plausible } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/dashboard/analytics',
        main: () => import('./AnalyticsOverview'),
        isAuthorized: [isCompanyManager, isBranchManager, isSuperUser],
        preloadData: Fraank.overviewState.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/dashboard/analytics/print',
        main: () => import('./PrintAnalyticsOverview'),
        isAuthorized: [isCompanyManager, isBranchManager, isSuperUser],
        preloadData: Fraank.overviewState.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/dashboard/plausible-analytics',
        main: () => import('./PlausibleAnalyticsOverview'),
        isAuthorized: [isCompanyManager, isBranchManager, isSuperUser],
        preloadData: Plausible.overviewState.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
];

export default routes;
