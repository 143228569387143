import * as React from 'react';

import { Icon } from '@/icon';
import { Tx } from '@/typography';

import styles from './DataTableFilterPills.scss';

export default function DataTableFilterPill(props: { children: React.ReactNode; onRemove: () => void }) {
    return (
        <Tx
            as="a"
            href=""
            className={styles.DataTableFilterPill}
            onClick={e => {
                e.preventDefault();
                props.onRemove();
            }}
        >
            {props.children}

            <Icon type="action_remove" />
        </Tx>
    );
}
