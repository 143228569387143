import * as React from 'react';

import { Domain } from 'api';
import { ISearchProvider } from 'utils';

import { webshopTagApi } from '@/api';

type WebshopTagSearchProviderItem = {
    value: Domain.WebshopTag['tagId'];
    label: React.ReactNode;
} & Domain.WebshopTag;

interface Filters {
    authorUserId?: string;
}

type LabelRenderer = (tag: Domain.WebshopTag) => React.ReactNode;

export class WebshopTagSearchProvider implements ISearchProvider<WebshopTagSearchProviderItem> {
    private preloadedTags: Domain.WebshopTag[] = [];
    private locale: Domain.Locale;
    private webshopId: Domain.Webshop['webshopId'];
    private filters: Filters;
    private labelRenderer: LabelRenderer = tag => tag.tagId;
    private hasMoreResults = false;

    private baseByValueCache: {
        [key: string]: Domain.WebshopTag;
    } = {};

    private searchCache: {
        [key: string]: Promise<Domain.WebshopTagsPage>;
    } = {};

    private byValueCache: {
        [key: string]: Promise<Domain.WebshopTag>;
    } = {};

    resetCache() {
        this.searchCache = {};
        this.byValueCache = {};

        for (const tag of this.preloadedTags) {
            this.byValueCache[tag.tagId] = Promise.resolve(this.mapTag(tag));
        }
    }

    reset() {
        this.resetCache();
    }

    setPreloadedTags(tags: Domain.WebshopTag[]) {
        this.preloadedTags = tags;
    }

    setWebshopId(webshopId: Domain.Webshop['webshopId']) {
        this.webshopId = webshopId;
        this.resetCache();
    }

    setLocale(locale: Domain.Locale) {
        this.locale = locale;
        this.resetCache();
    }

    setFilters(filters: Filters) {
        this.filters = filters;
        this.resetCache();
    }

    setLabelRenderer(labelRenderer: LabelRenderer) {
        this.labelRenderer = labelRenderer;
    }

    async search(query: string) {
        if (!this.searchCache[query]) {
            this.searchCache[query] = webshopTagApi.GetWebshopTags(
                { page: 1, size: 5 },
                { field: 'name', direction: 'ascending' },
                this.webshopId,
                this.locale,
                query,
                this.filters,
            );

            this.searchCache[query].then(cachedPage => {
                cachedPage.items.forEach(item => {
                    if (!this.byValueCache[item.tagId]) {
                        this.byValueCache[item.tagId] = Promise.resolve(item);
                    }
                });
            });
        }
        const page = await this.searchCache[query];

        return page.items.map(this.mapTag);
    }

    // we do not support loading more results
    async loadMoreResults() {
        return [];
    }

    // We do not have this implemented yet in this search provider
    getHasMoreResults() {
        return this.hasMoreResults;
    }

    async byValue(value: string) {
        if (!value) {
            return;
        }

        if (this.baseByValueCache[value]) {
            return this.mapTag(this.baseByValueCache[value]);
        }

        if (!this.byValueCache[value]) {
            this.byValueCache[value] = webshopTagApi.GetWebshopTagDetails(this.webshopId, value);
        }

        const device = await this.byValueCache[value];
        return this.mapTag(device);
    }

    async byValues(values: string[]) {
        return await Promise.all(values.filter(Boolean).map(value => this.byValue(value) as Promise<WebshopTagSearchProviderItem>));
    }

    private mapTag = (tag: Domain.WebshopTag): WebshopTagSearchProviderItem => {
        return {
            value: tag.tagId,
            label: this.labelRenderer(tag),
            ...tag,
        };
    };
}
