import { Route } from 'data-store';

import analyticsRoutes from './Analytics/routes';
import auditLogRoutes from './AuditLog/routes';
import platformNotificationsRoutes from './PlatformNotifications/routes';
import publicAnnouncementsRoutes from './PublicAnnouncements/routes';

const routes: Route[] = [...platformNotificationsRoutes, ...analyticsRoutes, ...publicAnnouncementsRoutes, ...auditLogRoutes];

export default routes;
