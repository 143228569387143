import * as React from 'react';

import { Route, isCompanyManager, isBranchManager, WebshopDeliveryTime } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/webshops/delivery-times',
        main: () => import('./WebshopDeliveryTimesOverview'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopDeliveryTime.overviewState.actions.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/webshops/delivery-times/:webshopId/new',
        main: () => import('./CreateDeliveryTime'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
    {
        path: '/webshops/delivery-times/:webshopId/:deliveryTimeId',
        main: () => import('./UpdateDeliveryTime'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopDeliveryTime.updateState.actions.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
];

export default routes;
