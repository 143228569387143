import * as React from 'react';

import { Route, isSuperUser, isBranchManager, isCompanyManager, Schedule } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/presentations/schedules',
        main: () => import('./SchedulesOverview'),
        isAuthorized: [isSuperUser, isCompanyManager, isBranchManager],
        preloadData: Schedule.overviewState.actions.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/presentations/schedules/new',
        main: () => import('./CreateSchedule'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloading: () => <FullPage bodyType="form" />,
    },
    {
        path: '/presentations/schedules/:scheduleId',
        main: () => import('./UpdateSchedule'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: Schedule.updateState.actions.load,
        preloading: () => <FullPage bodyType="form" />,
    },
];

export default routes;
