import * as React from 'react';
import { useSelector } from 'react-redux';

import { Domain } from 'api';
import { I18n } from 'data-store';
import { formatPrice, isPriceWithLegacyCurrency } from 'utils';

export default function Price({
    value,
    options,
    hideCurrencySymbol,
    priceInCents = true,
}: {
    value: Domain.Price | Domain.PriceWithLegacyCurrency;
    options?: Intl.NumberFormatOptions;
    hideCurrencySymbol?: boolean;
    priceInCents?: boolean;
}) {
    const locale = useSelector(I18n.selectCurrentISOLocale);
    const amount = priceInCents ? value.amount / 100 : value.amount;
    return (
        <>
            {formatPrice(
                amount,
                locale,
                isPriceWithLegacyCurrency(value) ? value.currency : value.currency.name,
                false,
                hideCurrencySymbol,
                options,
            )}
        </>
    );
}
