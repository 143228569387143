import * as React from 'react';

import { Route, isPlatformAdministrator, Webhook } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/webhooks',
        main: () => import('./WebhooksOverview'),
        isAuthorized: isPlatformAdministrator,
        preloadData: Webhook.overviewState.actions.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/webhooks/new',
        main: () => import('./CreateWebhook'),
        isAuthorized: isPlatformAdministrator,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
    {
        path: '/webhooks/edit/:webhookId',
        main: () => import('./UpdateWebhook'),
        isAuthorized: isPlatformAdministrator,
        preloadData: Webhook.detailsState.actions.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/webhooks/:webhookId',
        main: () => import('./ViewWebhook'),
        isAuthorized: isPlatformAdministrator,
        preloadData: Webhook.detailsState.actions.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
];

export default routes;
