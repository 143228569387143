import { ThunkAction } from '@/action';
import * as categoriesTreeState from '@/AvailableProduct/categoriesTreeState';
import { loadConfiguredIntegrationsCountByType } from '@/Integration/configuredIntegrationsTypeState';
import { URLParams } from '@/routing';

import * as createAndUpdateState from './createAndUpdateState';

export const load =
    (options: { urlParams: URLParams }): ThunkAction =>
    async dispatch => {
        await Promise.all([
            dispatch(loadConfiguredIntegrationsCountByType()),
            dispatch(categoriesTreeState.actions.load(options)),
            dispatch(createAndUpdateState.loadBranch()),
        ]);
    };
