import * as React from 'react';

import { Route, isSignedIn, ProductExport } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/products/product-export',
        main: () => import('./ProductExportOverview'),
        isAuthorized: isSignedIn,
        preloadData: ProductExport.overviewState.actions.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/products/product-export/new',
        main: () => import('./StartProductExport'),
        isAuthorized: isSignedIn,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
];

export default routes;
