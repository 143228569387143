import * as React from 'react';

import { Route, isCompanyManager, isBranchManager, OrderReview } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/webshops/order-review',
        main: () => import('./OrderReviewConfiguration'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: OrderReview.updateState.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
];

export default routes;
