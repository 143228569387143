import * as React from 'react';

import { Route, isSuperUser, SharingGroup } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/customers/sharing-groups',
        main: () => import('./SharingGroupsOverview'),
        isAuthorized: isSuperUser,
        preloadData: SharingGroup.overviewState.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/customers/sharing-groups/new',
        main: () => import('./CreateSharingGroup'),
        isAuthorized: isSuperUser,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
    {
        path: '/customers/sharing-groups/:sharingGroupId',
        main: () => import('./ViewSharingGroup'),
        isAuthorized: isSuperUser,
        preloadData: SharingGroup.detailsState.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
    {
        path: '/customers/sharing-groups/edit/:sharingGroupId',
        main: () => import('./UpdateSharingGroup'),
        isAuthorized: isSuperUser,
        preloadData: SharingGroup.updateState.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
];

export default routes;
