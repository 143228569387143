import * as React from 'react';

import { DndContext, closestCenter, PointerSensor, useSensor, useSensors, DragOverlay } from '@dnd-kit/core';
import { SortableContext, arrayMove, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { Button, ButtonSize } from '@/button';
import { Card } from '@/card';
import { Checkbox } from '@/checkbox';
import { Dropdown } from '@/core';
import { Icon } from '@/icon';
import { Container, Row, Col } from '@/layout';

import styles from './ManageColumns.scss';

type Column = {
    label: React.ReactNode;
    value: any;
};

export default function ManageColumns(props: {
    className?: string;
    onApply?: (selectedCategoryIds: string | undefined) => Promise<void>;
    label?: React.ReactNode;
    buttonSize?: ButtonSize;
    gettext: (text: string) => string;
    onVisibleColumnsChange: (visibleColumns: string[]) => void;
    onSortableColumnsChange: (sortableColumns: string[]) => void;
    sortableColumns: string[];
    visibleColumns: string[];
    toggleColumnsOptions: Column[];
}) {
    const [localSortableColumns, setLocalSortableColumns] = React.useState(props.sortableColumns);

    const [activeColumn, setActiveColumn] = React.useState<Column>();

    const dropdown = React.useRef<Dropdown>(null);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 10,
            },
        }),
    );

    const handleDragEnd = (event: any) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            const oldIndex = localSortableColumns.indexOf(active.id);
            const newIndex = localSortableColumns.indexOf(over.id);
            const newOrder = arrayMove(localSortableColumns, oldIndex, newIndex);
            setLocalSortableColumns(newOrder);
            props.onSortableColumnsChange(newOrder);
        }
        setActiveColumn(undefined);
    };
    function handleDragStart(event: any) {
        const { active } = event;
        const activeColumn = props.toggleColumnsOptions.find(c => c.value === active.id);
        setActiveColumn(activeColumn);
    }
    React.useEffect(() => {
        setLocalSortableColumns(props.sortableColumns);
    }, [props.sortableColumns]);
    return (
        <Dropdown
            ref={dropdown as any}
            className={props.className}
            bodyClassName="pt-10"
            portalClassName="FixedCustom"
            headerRenderer={isOpen => {
                return (
                    <Button
                        variant="tertiary"
                        variantSize={props.buttonSize || 's'}
                        data-test-id="manage-columns-button"
                        startIcon={<Icon type="action_column" />}
                        endIcon={
                            <Icon
                                type={isOpen ? 'action_arrow_up' : 'action_arrow_down'}
                                iconSize="m"
                            />
                        }
                    >
                        {props.gettext('Manage columns')}
                    </Button>
                );
            }}
            body={
                <Card
                    hSpacing="none"
                    vSpacing="none"
                    elevated={true}
                >
                    <div style={{ maxHeight: '310px', overflowY: 'auto' }}>
                        <DndContext
                            sensors={sensors}
                            collisionDetection={closestCenter}
                            onDragEnd={handleDragEnd}
                            onDragStart={handleDragStart}
                        >
                            <SortableContext
                                items={localSortableColumns}
                                strategy={verticalListSortingStrategy}
                            >
                                {props.toggleColumnsOptions
                                    .sort((a, b) => localSortableColumns.indexOf(a.value) - localSortableColumns.indexOf(b.value))
                                    .map(column => (
                                        <SortableItem
                                            key={column.value}
                                            id={column.value}
                                            column={column}
                                            visibleColumns={props.visibleColumns}
                                            onVisibleColumnsChange={props.onVisibleColumnsChange}
                                        />
                                    ))}
                            </SortableContext>
                            <DragOverlay>
                                {activeColumn ? (
                                    <SortableItem
                                        key={activeColumn.value}
                                        id={activeColumn.value}
                                        column={activeColumn}
                                        visibleColumns={props.visibleColumns}
                                        onVisibleColumnsChange={props.onVisibleColumnsChange}
                                    />
                                ) : null}
                            </DragOverlay>
                        </DndContext>
                    </div>
                </Card>
            }
        />
    );
}

function SortableItem({
    id,
    column,
    visibleColumns,
    onVisibleColumnsChange,
}: {
    id: string;
    column: Column;
    visibleColumns: string[];
    onVisibleColumnsChange: (visibleColumns: string[]) => void;
}) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id,
        transition: {
            duration: 150,
            easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
        },
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: 'ns-resize',
    };
    return (
        <Container
            key={column.value}
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
            onPointerDown={e => {
                e.stopPropagation();
                listeners?.onPointerDown(e);
            }}
        >
            <Row className={styles.ManageColumnRow}>
                <Col>
                    <Checkbox
                        data-test-id={`column-${column.value}`}
                        value={column.value}
                        checked={visibleColumns.includes(column.value)}
                        onChange={checked => {
                            const newVisibleColumns = checked
                                ? [...visibleColumns, column.value]
                                : visibleColumns.filter(c => c !== column.value);
                            onVisibleColumnsChange(newVisibleColumns);
                        }}
                    >
                        <span
                            style={{
                                fontSize: '16px',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                maxWidth: '180px',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {column.label}
                        </span>
                    </Checkbox>
                </Col>
            </Row>
        </Container>
    );
}
