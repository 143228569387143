import * as React from 'react';

import { Route, URLParams, ThunkAction, isSuperUser, DeviceMonitoring } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const preloadData =
    (params: { urlParams: URLParams }): ThunkAction =>
    async dispatch => {
        await Promise.all([
            dispatch(DeviceMonitoring.overviewState.actions.load(params)),
            dispatch(DeviceMonitoring.teamViewerOverviewState.actions.load(params)),
        ]);
    };

const routes: Route[] = [
    {
        path: '/devices/monitoring',
        main: () => import('./DevicesMonitoringOverview'),
        isAuthorized: isSuperUser,
        preloadData,
        preloading: () => <FullPage bodyType="data-table" />,
    },
];

export default routes;
