import * as React from 'react';

import { Route, isSignedIn } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/dashboard/lochting-news',
        main: () => import('./PublicAnnouncementsOverview'),
        isAuthorized: isSignedIn,
        preloading: () => <FullPage bodyType="tabs-and-boxes" />,
    },
    {
        path: '/dashboard/lochting-news/:id',
        main: () => import('./PublicAnnouncementDetails'),
        isAuthorized: isSignedIn,
        preloading: () => <FullPage bodyType="tabs-and-boxes" />,
    },
];

export default routes;
