import * as React from 'react';

import { Domain } from 'api';
import { ISearchProvider } from 'utils';

import { cmsApi } from '@/api';

type CmsTagSearchProviderItem = Domain.CmsTag & {
    value: string;
    label: React.ReactNode;
};

type LabelRenderer = (tag: Domain.CmsTag) => React.ReactNode;

export class CmsTagSearchProvider implements ISearchProvider<CmsTagSearchProviderItem> {
    private preloadedTags: Domain.CmsTag[] = [];
    private locale: Domain.Locale;
    private labelRenderer: LabelRenderer = tag => tag.name;

    private baseByValueCache: {
        [key: string]: Domain.CmsTag;
    } = {};

    private searchCache: {
        [key: string]: Promise<Domain.CmsTagsPage>;
    } = {};

    private byValueCache: {
        [key: string]: Promise<Domain.CmsTag>;
    } = {};

    resetCache() {
        this.searchCache = {};
        this.byValueCache = {};

        for (const tag of this.preloadedTags) {
            this.byValueCache[tag.cmsTagId] = Promise.resolve(this.mapTag(tag));
        }
    }

    reset() {
        this.resetCache();
    }

    setPreloadedTags(tags: Domain.CmsTag[]) {
        this.preloadedTags = tags;
    }

    setLocale(locale: Domain.Locale) {
        if (this.locale === locale) {
            return;
        }

        this.locale = locale;
        this.resetCache();
    }

    setLabelRenderer(labelRenderer: LabelRenderer) {
        this.labelRenderer = labelRenderer;
    }

    async search(query: string) {
        if (!this.searchCache[query]) {
            this.searchCache[query] = cmsApi.GetCmsTags(
                { page: 1, size: 5 },
                { field: 'name', direction: 'ascending' },
                {
                    locale: this.locale,
                },
                query,
            );

            this.searchCache[query].then(cachedPage => {
                cachedPage.items.forEach(item => {
                    if (!this.byValueCache[item.cmsTagId]) {
                        this.byValueCache[item.cmsTagId] = Promise.resolve(item);
                    }
                });
            });
        }
        const page = await this.searchCache[query];

        return page.items.map(this.mapTag);
    }

    // we do not support loading more results
    async loadMoreResults() {
        return [];
    }

    // We do not have this implemented yet in this search provider
    getHasMoreResults() {
        return false;
    }

    async byValue(value: string) {
        if (!value) {
            return;
        }

        if (this.baseByValueCache[value]) {
            return this.mapTag(this.baseByValueCache[value]);
        }

        if (!this.byValueCache[value]) {
            this.byValueCache[value] = cmsApi.GetCmsTagDetails(value);
        }

        const device = await this.byValueCache[value];
        return this.mapTag(device);
    }

    async byValues(values: string[]) {
        return await Promise.all(values.filter(Boolean).map(value => this.byValue(value) as Promise<CmsTagSearchProviderItem>));
    }

    private mapTag = (tag: Domain.CmsTag): CmsTagSearchProviderItem => {
        return {
            value: tag.cmsTagId,
            label: this.labelRenderer(tag),
            ...tag,
        };
    };
}
