import * as React from 'react';

import { Route, isSuperUser, isCompanyManager, isBranchManager, Device } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/vending-machines/overview',
        main: () => import('./VendingMachinesOverview'),
        isAuthorized: [isSuperUser, isCompanyManager, isBranchManager],
        preloadData: Device.vendingMachineOverviewState.actions.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
];

export default routes;
