import React from 'react';

import styles from './HoursColumn.scss';

export const HoursColumn = ({ hours, slotNumber, slotHeight }: { hours: number[]; slotNumber: number; slotHeight: number }) => {
    return (
        <div className={styles.HoursColumn}>
            {hours.map(hour => (
                <div
                    key={hour}
                    className={styles.hour}
                    style={{ height: `${slotNumber * slotHeight}px` }}
                >
                    {`${hour}:00`}
                </div>
            ))}
        </div>
    );
};
