import * as React from 'react';

import { Domain } from 'api';
import { useGettext } from 'data-store';
import { Icon, IconSize, Button, color } from 'ui-components';

import NotificationIcon from './NotificationIcon';
import NotificationLink from './NotificationLink';
import NotificationMessage from './NotificationMessage';
import NotificationTime from './NotificationTime';
import NotificationType from './NotificationType';

import styles from './Notification.scss';
export default function Notification(props: {
    notification: Domain.Notification;
    className?: string;
    onClick?: () => Promise<void> | void;
    iconSize?: IconSize;
    onNotificationReadClick?: () => void;
    isDashboardNotification?: boolean;
    showNotificationStatus?: boolean;
}) {
    const { gettext } = useGettext();
    const { onNotificationReadClick } = props;
    return (
        <NotificationLink
            notification={props.notification}
            className={props.className}
            onClick={async () => {
                if (props.onClick) {
                    await props.onClick();
                }
            }}
        >
            <NotificationIcon
                notification={props.notification}
                iconSize={props.iconSize}
            />
            <div>
                <NotificationMessage
                    notification={props.notification}
                    isLight={!!props.notification.readOn}
                />
                <div>
                    <NotificationTime
                        notification={props.notification}
                        small
                    />
                    <Icon
                        iconSize="s"
                        type="nav_dot"
                    />
                    <NotificationType notification={props.notification} />
                </div>
            </div>
            {props.showNotificationStatus && (
                <div className={`${styles.NotificationIndicator} ${!props.notification.readOn ? styles.UnreadNotification : ''}`}>
                    <Icon
                        type="nav_dot"
                        iconSize="l"
                    />
                </div>
            )}
            {onNotificationReadClick ? (
                <span>
                    {!props.notification.readOn ? (
                        <Button
                            type="button"
                            variantSize="xxs"
                            onClick={async e => {
                                e.stopPropagation();
                                e.preventDefault();
                                onNotificationReadClick();
                            }}
                        >
                            {gettext('Mark as read')}
                        </Button>
                    ) : (
                        <Icon
                            iconSize="s"
                            type="action_check"
                            color={color.BG.Success}
                        />
                    )}
                </span>
            ) : null}
        </NotificationLink>
    );
}
