import * as React from 'react';

import { Route, isSuperUser, isCompanyManager, isBranchManager, Branch } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/customers/branches',
        main: () => import('./BranchesOverview'),
        isAuthorized: isSuperUser,
        preloadData: Branch.overviewState.actions.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/company/branches',
        main: () => import('./BranchesOverview'),
        isAuthorized: isCompanyManager,
        preloadData: Branch.overviewState.actions.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/customers/branches/new',
        main: () => import('./CreateBranch'),
        isAuthorized: isSuperUser,
        preloadData: Branch.createState.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
    {
        path: '/customers/branches/:branchId',
        main: () => import('./ViewBranch'),
        isAuthorized: [isSuperUser, isCompanyManager],
        preloadData: Branch.detailsState.actions.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/customers/branches/edit/:branchId',
        main: () => import('./UpdateBranch'),
        isAuthorized: isSuperUser,
        preloadData: Branch.updateState.actions.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
    {
        path: '/customers/branches/edit-opening-hours/:branchId',
        main: () => import('./UpdateOpeningHours'),
        isAuthorized: [isSuperUser, isCompanyManager, isBranchManager],
        preloadData: Branch.updateState.actions.loadBranch,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
];

export default routes;
