import * as React from 'react';

import { Button } from '@/button';
import { Icon } from '@/icon';
import { Flex } from '@/layout';
import { Tx } from '@/typography';

import styles from './Spinner.scss';

export default function Spinner({
    value,
    onChange,
    disabled,
    label,
    min,
    max,
}: {
    value: number;
    min: number;
    max: number;
    onChange: (newValue: number) => void;
    disabled?: boolean;
    label?: string;
}) {
    return (
        <Flex
            alignItems="center"
            gap="6px"
        >
            {!disabled ? (
                <Button
                    variant="plain"
                    variantSize="xs"
                    onClick={() => onChange(value - 1)}
                    disabled={value - 1 < min}
                    startIcon={<Icon type="action_minus_circle_outline" />}
                />
            ) : null}

            <Tx
                level="body-sm"
                sx={{ weight: 'light' }}
                className={styles.SpinnerValue}
            >
                {value}
            </Tx>

            {!disabled ? (
                <Button
                    variant="plain"
                    variantSize="xs"
                    onClick={() => onChange(value + 1)}
                    disabled={value + 1 > max}
                    startIcon={<Icon type="action_plus_circle_outline" />}
                />
            ) : null}

            {label ? (
                <Tx
                    level="body-lg"
                    className={styles.SpinnerLabel}
                >
                    {label}
                </Tx>
            ) : null}
        </Flex>
    );
}
