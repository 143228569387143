import * as React from 'react';

import { Route, isCompanyManager, isBranchManager, WebshopLayout } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/webshops/layout/color-themes',
        main: () => import('./WebshopLayoutColorThemesOverview'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopLayout.layoutState.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/webshops/layout/color-themes/:themeId',
        main: () => import('./WebshopLayoutColorThemeDetails'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopLayout.layoutState.loadDetails,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/webshops/layout/typography',
        main: () => import('./WebshopLayoutTypographyOverview'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopLayout.layoutState.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/webshops/layout/typography/:fontSetId',
        main: () => import('./WebshopLayoutTypographyDetails'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopLayout.layoutState.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/webshops/layout/shapes',
        main: () => import('./WebshopLayoutShapesOverview'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopLayout.layoutState.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/webshops/layout/navigation',
        main: () => import('./WebshopLayoutNavigationOverview'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopLayout.layoutState.loadLayoutSet,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/webshops/layout/categories-sequence',
        main: () => import('./WebshopCategoriesSequence'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopLayout.layoutState.loadCategoriesSequence,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
];

export default routes;
