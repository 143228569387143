import * as React from 'react';

import { Route, isCompanyManager, isBranchManager, WebshopTag } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/webshops/news-article-tags',
        main: () => import('./WebshopTagsOverview'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopTag.overviewState.actions.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/webshops/news-article-tags/:webshopId/new',
        main: () => import('./CreateTag'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
    {
        path: '/webshops/news-article-tags/:webshopId/:tagId',
        main: () => import('./UpdateTag'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopTag.updateState.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
];

export default routes;
