import * as React from 'react';

import { Route, anyAuthorization, AuditLog } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/dashboard/audit-log',
        main: () => import('./AuditLogOverview'),
        isAuthorized: anyAuthorization,
        preloadData: AuditLog.overviewState.actions.load,
        preloading: () => (
            <FullPage
                bodyType="data-table"
                hideDataTableToggleColumns={true}
                hideMainSearch={true}
            />
        ),
    },
];

export default routes;
