import React, { useEffect, useRef } from 'react';

import moment, { Moment } from 'moment';

import { CalendarDayLabel } from './CalendarDayLabel';
import { ICalendarEvent } from './CalendarEvent';
import { EventsByDay } from './EventsByDay';
import { HoursColumn } from './HoursColumn';

import styles from './WeeklyView.scss';

interface WeeklyViewProps {
    startDate?: Moment;
    events: ICalendarEvent[];
    slotTimeInMinutes?: number;
    slotHeight?: number;
    gettext: (text: string) => string;
}

const WeeklyView = ({ startDate, events, slotTimeInMinutes = 15, slotHeight = 40 }: WeeklyViewProps) => {
    const startOfWeek = startDate ? startDate.clone().startOf('isoWeek') : moment().startOf('isoWeek');
    const daysInWeek = Array.from({ length: 7 }, (_, i) => startOfWeek.clone().add(i, 'days'));
    const hours = Array.from({ length: 24 }, (_, i) => i);
    const slotNumber = 60 / slotTimeInMinutes;
    const slots = Array.from({ length: slotNumber }, (_, i) => i * slotTimeInMinutes);

    const scrollableRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const currentHour = moment().hour();
        if (scrollableRef.current) {
            const scrollPosition = currentHour * slotNumber * slotHeight;
            scrollableRef.current.scrollTop = scrollPosition;
        }
    }, [slotNumber, slotHeight, slotTimeInMinutes]);

    const getEventsForDay = (day: Moment) => {
        return events.filter(event => moment(event.startDate).isSame(day, 'day'));
    };

    return (
        <div
            className={styles.WeeklyView}
            data-test-id="calendar-schedule-weekly-view"
        >
            <div className={styles.CalendarHeader}>
                <div className={styles.dayHeader} />
                {daysInWeek.map(day => (
                    <div
                        key={day.format('dddd')}
                        className={styles.dayHeader}
                    >
                        <CalendarDayLabel date={day} />
                    </div>
                ))}
            </div>
            <div
                className={styles.dayColumns}
                ref={scrollableRef}
            >
                <HoursColumn
                    hours={hours}
                    slotNumber={slotNumber}
                    slotHeight={slotHeight}
                />

                {daysInWeek.map(day => {
                    const eventsForDay = getEventsForDay(day);
                    return (
                        <EventsByDay
                            key={day.format('YYYY-MM-DD')}
                            day={day}
                            hours={hours}
                            slotNumber={slotNumber}
                            slotHeight={slotHeight}
                            slots={slots}
                            slotTimeInMinutes={slotTimeInMinutes}
                            events={eventsForDay}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default WeeklyView;
