import * as React from 'react';

import { Domain } from 'api';
import { IconType, IconSize, Icon } from 'ui-components';

export default function NotificationIcon(props: { notification: Domain.Notification; iconSize?: IconSize; className?: string }) {
    let iconType: IconType = 'action_user';

    if (
        props.notification.notificationType === 'orderWasCreated' ||
        props.notification.notificationType === 'webshopConfigWasUpdated' ||
        props.notification.notificationType === 'webshopWasUpdated' ||
        props.notification.notificationType === 'orderWasBackOrdered' ||
        props.notification.notificationType === 'orderBackOrderStatusWasUpdated' ||
        props.notification.notificationType === 'shipmentServiceWebhookDataWasUpdated' ||
        props.notification.notificationType === 'invoiceWasNumbered' ||
        props.notification.notificationType === 'orderRequiresPrescriptionReview' ||
        props.notification.notificationType === 'appointmentWasCreated' ||
        props.notification.notificationType === 'appointmentWasCanceled'
    ) {
        iconType = 'nav_webshop';
    } else if (
        props.notification.notificationType === 'configuredIntegrationWasActivated' ||
        props.notification.notificationType === 'configuredIntegrationWasDeactivated' ||
        props.notification.notificationType === 'configuredIntegrationWasUpdated'
    ) {
        iconType = 'nav_integrations';
    } else if (props.notification.notificationType === 'scheduleWasUpdated') {
        iconType = 'nav_schedule';
    } else if (
        props.notification.notificationType === 'branchSlideshowWasUpdated' ||
        props.notification.notificationType === 'companySlideshowWasUpdated' ||
        props.notification.notificationType === 'slideshowWasShared'
    ) {
        iconType = 'nav_presentation';
    } else if (
        props.notification.notificationType === 'branchCustomPageContentWasUpdated' ||
        props.notification.notificationType === 'companyCustomPageContentWasUpdated' ||
        props.notification.notificationType === 'customPageWasShared'
    ) {
        iconType = 'nav_presentation';
    } else if (
        props.notification.notificationType === 'branchProductWallContentWasUpdated' ||
        props.notification.notificationType === 'companyProductWallContentWasUpdated' ||
        props.notification.notificationType === 'productWallWasShared'
    ) {
        iconType = 'nav_presentation';
    } else if (
        props.notification.notificationType === 'productsOutOfStock' ||
        props.notification.notificationType === 'productsBackInStock' ||
        props.notification.notificationType === 'availableProductWasUpdated' ||
        props.notification.notificationType === 'productSelectionStatusWasUpdated' ||
        props.notification.notificationType === 'productStockSubscriptionWasAdded' ||
        props.notification.notificationType === 'lockerHasProductsThatCannotBeSold'
    ) {
        iconType = 'nav_products';
    } else if (props.notification.notificationType === 'importedPriceWasUpdated') {
        iconType = 'nav_price';
    } else if (
        props.notification.notificationType === 'orderExportToIntegrationDataWasUpdated' ||
        props.notification.notificationType === 'exportWasCompleted' ||
        props.notification.notificationType === 'prescriptionWasUploaded' ||
        props.notification.notificationType === 'productExportWasCompleted'
    ) {
        iconType = 'nav_exports';
    } else if (
        props.notification.notificationType === 'sharingGroupCompanyWasInvited' ||
        props.notification.notificationType === 'mediaWasShared'
    ) {
        iconType = 'action_media';
    } else if (
        props.notification.notificationType === 'vendingMachineHomeScreenProductWasRemoved' ||
        props.notification.notificationType === 'reservationAddToRobotFailed' ||
        props.notification.notificationType === 'lockerLeftUnlocked' ||
        props.notification.notificationType === 'robotEmergencyStopWasTriggered'
    ) {
        iconType = 'nav_matic';
    } else if (props.notification.notificationType === 'libraryArticleWasCreated') {
        iconType = 'nav_book';
    } else if (props.notification.notificationType === 'userWasSubscribedToNewsletter') {
        iconType = 'nav_envelope';
    }

    return (
        <Icon
            type={iconType}
            iconSize={props.iconSize}
            className={props.className}
        />
    );
}
