import * as React from 'react';

import { Route, isCompanyManager, isBranchManager, WebshopFaqCategory } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/webshops/faq-categories',
        main: () => import('./WebshopFaqCategoriesOverview'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopFaqCategory.overviewState.actions.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/webshops/faq-categories/:webshopId/new',
        main: () => import('./CreateFaqCategory'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
    {
        path: '/webshops/faq-categories/:webshopId/:faqCategoryId',
        main: () => import('./UpdateFaqCategory'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopFaqCategory.updateState.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
];

export default routes;
