import * as React from 'react';

import classNames from 'classnames';

import { Domain } from 'api';

import { Icon } from '@/icon';

import styles from './DataTable.scss';

export default function SortGizmo<T = string>(props: {
    label: React.ReactNode;
    field: T;
    onClick: (fieldName: T) => void;
    sorting: Domain.Sorting<T>;
}) {
    return (
        <a
            href=""
            onClick={event => {
                event.preventDefault();
                props.onClick(props.field);
            }}
            className={classNames(
                styles.SortGizmo,
                props.field === props.sorting.field ? styles.SortGizmoActive : undefined,
                props.sorting.direction,
            )}
        >
            {props.label} <Icon type="action_swap" />
            <span className={styles.SortGizmoActiveIcon}>
                <Icon type="action_swap" />
            </span>
        </a>
    );
}
