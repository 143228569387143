import * as React from 'react';

import { Flex } from '@/layout';

import styles from './Modal.scss';

export default function ModalFooter({ children }: { children?: React.ReactNode }) {
    return (
        <Flex
            className={styles.ModalFooter}
            gap="8px"
            justifyContent="flex-end"
        >
            {children}
        </Flex>
    );
}
