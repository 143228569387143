import * as React from 'react';

import { Preloader, Col, Grid, Row } from 'ui-components';

export default function Form() {
    const aCol = (
        <Col span={6}>
            <Preloader
                width={60}
                varyWidth={120}
                height={16}
                className="mt-2 mb-7"
            />

            <Preloader
                width="100%"
                height={52}
            />
        </Col>
    );

    return (
        <Grid gutter={30}>
            <Row>
                {aCol}
                {aCol}
            </Row>
            <Row>{aCol}</Row>
        </Grid>
    );
}
