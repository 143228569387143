import * as React from 'react';

import { Route, isCompanyManager, isBranchManager, ProductSelection } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/products/selection/:type',
        main: () => import('./SelectionOverview'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: ProductSelection.overviewState.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/products/selection/selected-product/:type/:selectedProductId',
        main: () => import('./SelectedProductDetails'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: ProductSelection.detailsState.load,
        preloading: () => (
            <FullPage
                hideMainActions={true}
                bodyType="tabs-and-boxes"
            />
        ),
    },
    {
        path: '/products/selection/selected-product/:type/:selectedProductId/:tab',
        main: () => import('./SelectedProductDetails'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: ProductSelection.detailsState.load,
        preloading: () => (
            <FullPage
                hideMainActions={true}
                bodyType="tabs-and-boxes"
            />
        ),
    },
    {
        path: '/products/selection/edit/:type',
        main: () => import('./EditExclusionRules'),
        isAuthorized: [isBranchManager],
        preloadData: ProductSelection.updateState.load,
        preloading: () => (
            <FullPage
                bodyType="data-table"
                hideDataTableToggleColumns={true}
                hideMainSearch={true}
            />
        ),
    },
];

export default routes;
