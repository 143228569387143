import { Route } from 'data-store';

import customPagesRoutes from './CustomPages/routes';
import productWallsRoutes from './ProductWalls/routes';
import scheduleRoutes from './Schedule/routes';
import slideshowsRoutes from './Slideshows/routes';

const routes: Route[] = [...productWallsRoutes, ...customPagesRoutes, ...slideshowsRoutes, ...scheduleRoutes];

export default routes;
