import * as React from 'react';

import { Route, isSuperUser, isCompanyManager, isBranchManager, Slideshow } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/presentations/slideshows',
        main: () => import('./SlideshowsOverview'),
        isAuthorized: [isSuperUser, isCompanyManager, isBranchManager],
        preloadData: Slideshow.overviewState.actions.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/presentations/slideshows/new',
        main: () => import('./CreateSlideshow'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloading: () => <FullPage bodyType="form" />,
    },
    {
        path: '/presentations/slideshows/:slideshowId',
        main: () => import('./UpdateSlideshow'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: Slideshow.updateState.load,
        preloading: () => <FullPage bodyType="form" />,
    },
];

export default routes;
