import * as React from 'react';

import { Route, isCompanyManager, isBranchManager, WebshopFaqItem } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/webshops/faq-items',
        main: () => import('./WebshopFaqItemsOverview'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopFaqItem.overviewState.actions.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/webshops/faq-items/:webshopId/new',
        main: () => import('./CreateFaqItem'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopFaqItem.createState.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
    {
        path: '/webshops/faq-items/:webshopId/:faqItemId',
        main: () => import('./UpdateFaqItem'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopFaqItem.updateState.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
];

export default routes;
