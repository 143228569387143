import * as React from 'react';

import { Button } from '@/button';
import { Card, CardItem } from '@/card';
import { Container, Row, Col } from '@/layout';
import { Tx } from '@/typography';

import ActionWithDialog from './ActionWithDialog';

import styles from './ActionWithConfirmDialog.scss';

export default function ActionWithConfirmDialog(props: {
    children: React.ReactNode;
    confirmTitle?: React.ReactNode;
    confirmMessage: React.ReactNode;
    cancelLabel: React.ReactNode;
    confirmLabel: React.ReactNode;
    confirmDisabled?: boolean;
    disabled?: boolean;
    otherBody?: React.ReactNode;
    anchorTop?: boolean;
    inheritDropdownWidthFromHeader?: boolean;
    className?: string;
    onConfirm: (close: () => void) => void;
    onClick?: (e: React.MouseEvent) => void;
    shouldCloseOnConfirm?: boolean;
    doNotCloseOnClickOutside?: boolean;
}) {
    const shouldCloseOnConfirm = props.shouldCloseOnConfirm ?? true;
    return (
        <ActionWithDialog
            className={props.className}
            onClick={props.onClick}
            disabled={props.disabled}
            anchorTop={props.anchorTop}
            inheritDropdownWidthFromHeader={props.inheritDropdownWidthFromHeader}
            doNotCloseOnClickOutside={props.doNotCloseOnClickOutside}
            dialogRenderer={close => {
                return (
                    <Card
                        elevated={true}
                        className={styles.ActionWithConfirmDialog + ' mt-11'}
                    >
                        {props.confirmTitle ? (
                            <CardItem>
                                <Tx level="heading-5">{props.confirmTitle}</Tx>
                            </CardItem>
                        ) : null}

                        <CardItem>
                            <Tx as="p">{props.confirmMessage}</Tx>
                        </CardItem>

                        {props.otherBody ? <CardItem>{props.otherBody}</CardItem> : null}

                        <CardItem>
                            <Container
                                gutter={14}
                                fullWidth={true}
                            >
                                <Row>
                                    <Col hAlign="start">
                                        <Button
                                            variant="tertiary"
                                            variantSize="s"
                                            onClick={() => close()}
                                            data-test-id="confirm-dialog-close"
                                            disabled={props.disabled}
                                        >
                                            {props.cancelLabel}
                                        </Button>
                                    </Col>

                                    <Col hAlign="end">
                                        <Button
                                            variant="primary"
                                            variantSize="s"
                                            disabled={props.confirmDisabled || props.disabled}
                                            onClick={() => {
                                                if (shouldCloseOnConfirm) {
                                                    close();
                                                }
                                                props.onConfirm(close);
                                            }}
                                            data-test-id="confirm-dialog-confirm"
                                        >
                                            {props.confirmLabel}
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                        </CardItem>
                    </Card>
                );
            }}
        >
            {props.children}
        </ActionWithDialog>
    );
}
