import * as React from 'react';

import { Domain } from 'api';
import { ISearchProvider } from 'utils';

import { zipCodeApi } from '@/api';

interface ZipCodeSearchProviderItem {
    value: Domain.ZipCode['zipCode'];
    label: React.ReactNode;
}

type LabelRenderer = (value: Domain.ZipCode) => React.ReactNode;

export class ZipCodeSearchProvider implements ISearchProvider<ZipCodeSearchProviderItem> {
    private country: Domain.Country;
    private labelRenderer: LabelRenderer = x => `${x.name} (${x.zipCode})`;
    private hasMoreResults = false;

    private searchCache: {
        [key: string]: Promise<Domain.ZipCodesPage>;
    } = {};

    private byValueCache: {
        [key: string]: Promise<Domain.ZipCode>;
    } = {};

    resetCache() {
        this.searchCache = {};
        this.byValueCache = {};
    }

    setCountry(country: Domain.Country) {
        this.country = country;
        this.resetCache();
    }

    setLabelRenderer(labelRenderer: LabelRenderer) {
        this.labelRenderer = labelRenderer;
    }

    async search(query: string) {
        const cacheKey = query + '-' + this.country;

        if (!this.searchCache[cacheKey]) {
            this.searchCache[cacheKey] = zipCodeApi.GetZipCodes(this.country, { page: 1, size: 10 }, query);

            this.searchCache[cacheKey].then(cachedPage => {
                cachedPage.items.forEach(item => {
                    if (!this.byValueCache[item.zipCode]) {
                        this.byValueCache[item.zipCode] = Promise.resolve(item);
                    }
                });
            });
        }

        const page = await this.searchCache[cacheKey];

        return page.items.map(item => {
            return {
                value: item.zipCode,
                label: this.labelRenderer(item),
            };
        });
    }

    async byValue(value: string) {
        if (!value) {
            return;
        }

        if (!this.byValueCache[value]) {
            this.byValueCache[value] = zipCodeApi.GetZipCodes(this.country, { page: 1, size: 10 }, value).then(page => {
                const item = page.items.find(item => item.zipCode === value);

                if (!item) {
                    throw new Error('ZipCode not found');
                }

                return item;
            });
        }

        const item = await this.byValueCache[value];
        return {
            value: item.zipCode,
            label: this.labelRenderer(item),
        };
    }

    // we do not support loading more results
    async loadMoreResults() {
        return [];
    }

    // We do not have this implemented yet in this search provider
    getHasMoreResults() {
        return this.hasMoreResults;
    }

    async byValues(values: string[]) {
        return await Promise.all(values.filter(Boolean).map(value => this.byValue(value) as Promise<ZipCodeSearchProviderItem>));
    }
}
