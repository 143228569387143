import * as React from 'react';

import { Route, isSuperUser, isCompanyManager, isBranchManager, isSignedIn, CustomPage } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/presentations/custom-pages',
        main: () => import('./CustomPagesOverview'),
        isAuthorized: [isSuperUser, isCompanyManager, isBranchManager],
        preloadData: CustomPage.overviewState.actions.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/presentations/custom-pages/new',
        main: () => import('./CreateCustomPage'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: CustomPage.createState.load,
        preloading: () => <FullPage bodyType="form" />,
    },
    {
        path: '/presentations/custom-pages/:customPageId',
        main: () => import('./UpdateCustomPage'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: CustomPage.updateState.actions.load,
        preloading: () => <FullPage bodyType="form" />,
    },
    {
        path: '/preview/custom-page/:customPageId',
        main: () => import('./PreviewCustomPage'),
        preloadData: CustomPage.previewState.actions.load,
        isAuthorized: isSignedIn,
        isBrowsershot: true,
    },
];

export default routes;
