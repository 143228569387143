import * as React from 'react';
import { useSelector } from 'react-redux';

import moment from 'moment';

import { Domain } from 'api';
import { useThunkDispatch, I18n, CmsItem, useGettext } from 'data-store';
import { Col, Card, Tx, Icon, color } from 'ui-components';
import { DateTimeDisplay, LocalizedValue } from 'util-components';

import LocalizedCmsBlockRenderer from '@/Pages/Cms/Pages/Components/LocalizedCmsBlockRenderer';
import PublicTagsList from '@/Pages/Dashboard/PublicAnnouncements/Components/PublicTagsList';

import styles from '../PublicAnnouncementsOverview.scss';

export default function PublicAnnouncementItem(props: {
    publicAnnouncement: Domain.CmsItem;
    onReadAnnouncement?: () => Promise<void>;
    isUnread: boolean;
    allowClick: boolean;
    isWidget?: boolean;
    isPopup?: boolean;
    onItemClick?: () => void;
}) {
    const [isHovered, setIsHovered] = React.useState(false);

    const dispatch = useThunkDispatch();
    const locale = useSelector(I18n.selectCurrentLocale);
    const { gettext } = useGettext();

    const handleMouseEnter = async () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
        if (props.onReadAnnouncement) {
            props.onReadAnnouncement();
        }
    };
    const isPinned =
        moment(props.publicAnnouncement.pinnedFrom).startOf('day').isSameOrBefore(moment().startOf('day')) &&
        moment(props.publicAnnouncement.pinnedUntil).startOf('day').isSameOrAfter(moment().startOf('day'));

    return (
        <Col
            hAlign="center"
            className={`${styles.PublicAnnouncementItem} ${isHovered ? styles.UnreadAnnouncement : ''} ${props.onItemClick ? styles.PublicAnnouncementItemClickable : ''}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={props.onItemClick}
        >
            <Card>
                <div className={styles.PublicAnnouncementItemHeader}>
                    <Tx
                        as="p"
                        className="mb-0"
                        sx={{
                            color: '--content-tertiary',
                        }}
                    >
                        <DateTimeDisplay
                            date={props.publicAnnouncement.activeFrom}
                            formatDate="DD/MM/yyyy"
                        />
                    </Tx>
                    {props.isUnread ? (
                        isHovered &&
                        !props.isPopup && (
                            <Tx
                                sx={{
                                    color: '--content-tertiary',
                                }}
                                className="ml-auto"
                                level="body-sm"
                            >
                                {gettext('Reading..')}
                            </Tx>
                        )
                    ) : (
                        <div className={`flex flex-align-center  ml-auto ${styles.PublicAnnouncementItemRead}`}>
                            <Tx
                                sx={{
                                    color: '--content-success',
                                }}
                                className="mr-2 "
                                level="body-sm"
                            >
                                {gettext('Read')}
                            </Tx>
                            <Icon
                                type="action_check"
                                iconSize="s"
                            />
                        </div>
                    )}
                </div>
                <div className={styles.PublicAnnouncementItemHeader + ' mb-12'}>
                    <Tx
                        level="heading-6"
                        className={`mb-0 ${!props.isWidget ? color.Primary.Blue : ''}`}
                    >
                        <LocalizedValue value={props.publicAnnouncement.name} />
                    </Tx>
                    {isPinned && !props.isPopup && (
                        <Icon
                            type="nav_pushpin"
                            iconSize="s"
                            className="ml-8"
                        />
                    )}
                    <PublicTagsList
                        tags={props.publicAnnouncement.tags.filter(tag => tag.locale === locale)}
                        type="item-tags"
                        className="ml-auto"
                        allowClick={props.allowClick}
                        onTagClick={async tagId => {
                            if (!props.allowClick) {
                                return;
                            }
                            await dispatch(
                                CmsItem.publicAnnouncementsOverviewState.setFilters({
                                    tagId,
                                }),
                            );
                            await dispatch(CmsItem.publicAnnouncementsOverviewState.loadPublicAnnouncements());
                        }}
                    />
                </div>

                <LocalizedCmsBlockRenderer blocks={props.publicAnnouncement.blocks as Domain.LocalizedCmsItemBlock[]} />
            </Card>
        </Col>
    );
}
