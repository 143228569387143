import { Domain } from 'api';
import { ISearchProvider } from 'utils';

import { userApi } from '@/api';

interface UserSearchProviderItem {
    value: Domain.User['userId'];
    label: Domain.User['name'];
}

export class UserSearchProvider implements ISearchProvider<UserSearchProviderItem> {
    private ownership: Domain.Ownership;
    private branchIdFilter: string | undefined;
    private hasMoreResults = false;

    private searchCache: {
        [key: string]: Promise<Domain.UsersPage>;
    } = {};

    private byValueCache: {
        [key: string]: Promise<Domain.User>;
    } = {};

    resetCache() {
        this.searchCache = {};
        this.byValueCache = {};
    }

    reset() {
        this.branchIdFilter = undefined;
        this.resetCache();
    }

    setBranchIdFilter(branchId: string | undefined) {
        this.branchIdFilter = branchId;
        this.resetCache();
    }

    getBranchIdFilter() {
        return this.branchIdFilter;
    }

    setOwnership(ownership: Domain.Ownership) {
        this.ownership = ownership;
        this.resetCache();
    }

    async search(query: string) {
        const cacheKey = query + '-' + this.branchIdFilter;

        if (!this.searchCache[cacheKey]) {
            this.searchCache[cacheKey] = userApi.GetUsers(
                this.ownership,
                { page: 1, size: 10 },
                { field: 'name', direction: 'ascending' },
                query,
                {
                    branchId: this.branchIdFilter,
                },
            );

            this.searchCache[cacheKey].then(cachedUsersPage => {
                cachedUsersPage.items.forEach(user => {
                    if (!this.byValueCache[user.userId]) {
                        this.byValueCache[user.userId] = Promise.resolve(user);
                    }
                });
            });
        }
        const usersPage = await this.searchCache[cacheKey];

        return usersPage.items.map(user => {
            return {
                value: user.userId,
                label: user.name,
            };
        });
    }

    // we do not support loading more results
    async loadMoreResults() {
        return [];
    }

    // We do not have this implemented yet in this search provider
    getHasMoreResults() {
        return this.hasMoreResults;
    }

    async byValue(value: string) {
        if (!value) {
            return;
        }

        if (!this.byValueCache[value]) {
            this.byValueCache[value] = userApi.GetUserDetails(value);
        }

        const user = await this.byValueCache[value];
        return {
            value: user.userId,
            label: user.name,
        };
    }

    // we do not support multi-selects
    async byValues(_values: string[]) {
        return [];
    }
}
