import { Domain } from 'api';

import { getCountryCodeFromLocale } from './getCountryCodeFromLocale';
import { COUNTRY_CONFIG } from './Localisation/availableCountriesState';

const getLocalizedVideos = (
    videos: Domain.CustomProductVideo[],
    locale: Domain.Locale,
    noFallback?: boolean,
): Domain.CustomProductVideo[] => {
    let localizedVideos: Domain.CustomProductVideo[] = [];

    const getVideosForLocale = (searchedLocale: Domain.Locale) => {
        const searchedVideos: Domain.CustomProductVideo[] = [];
        let anyDesiredLocaleVideos = false;
        for (const video of videos) {
            if (video.locale === searchedLocale) {
                anyDesiredLocaleVideos = true;
                break;
            }
        }

        if (anyDesiredLocaleVideos) {
            for (const video of videos) {
                if (video.locale === searchedLocale) {
                    searchedVideos.push(video);
                }
            }
        }

        return searchedVideos;
    };

    if (videos.length > 0) {
        const foundVideos = getVideosForLocale(locale);
        if (foundVideos.length > 0) {
            localizedVideos = foundVideos;
        } else if (!noFallback) {
            const country = getCountryCodeFromLocale(locale);
            for (const fallbackLocale of COUNTRY_CONFIG[country].locales) {
                const foundVideos = getVideosForLocale(fallbackLocale);
                if (foundVideos.length > 0) {
                    localizedVideos = foundVideos;
                    break;
                }
            }
        }
    }

    return localizedVideos;
};

export default getLocalizedVideos;
