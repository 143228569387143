import * as React from 'react';

import { Button } from '@/button';
import { Card, CardItem } from '@/card';
import { Container, Row, Col } from '@/layout';
import { Portal } from '@/portal';
import { Tx } from '@/typography';

import styles from './DialogModal.scss';

export default function InfoModal(props: {
    title?: React.ReactNode;
    message: React.ReactNode;
    content: React.ReactNode;
    close: () => void;
    closeLabel: React.ReactNode;
}) {
    return (
        <Portal aboveSidebar>
            <div
                className={styles.DialogModal}
                onClick={event => {
                    event.preventDefault();
                    props.close();
                }}
            >
                <Card
                    elevated={true}
                    className={styles.DialogModalBody}
                >
                    {props.title ? (
                        <CardItem>
                            <Tx level="heading-4">{props.title}</Tx>
                        </CardItem>
                    ) : null}

                    <CardItem className="pb-30">
                        <Tx
                            as="p"
                            className="mb-30"
                        >
                            {props.message}
                        </Tx>
                        {props.content}
                    </CardItem>

                    <CardItem>
                        <Container
                            gutter={14}
                            fullWidth={true}
                        >
                            <Row>
                                <Col hAlign="end">
                                    <Button
                                        variant="primary"
                                        variantSize="s"
                                        onClick={() => {
                                            props.close();
                                        }}
                                    >
                                        {props.closeLabel}
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </CardItem>
                </Card>
            </div>
        </Portal>
    );
}
