import * as React from 'react';

import { Route, Localisation, isPlatformAdministrator } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/localisation/countries-and-languages',
        main: () => import('./LocalisationsOverview'),
        isAuthorized: isPlatformAdministrator,
        preloadData: Localisation.overviewState.load,
        preloading: () => (
            <FullPage
                bodyType="data-table"
                hideMainSearch={true}
                hideDataTableToggleColumns={true}
            />
        ),
    },
    {
        path: '/localisation/countries-and-languages/new',
        main: () => import('./CreateLocalisation'),
        isAuthorized: isPlatformAdministrator,
        preloadData: Localisation.overviewState.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
    {
        path: '/localisation/countries-and-languages/edit/:country',
        main: () => import('./UpdateLocalisation'),
        isAuthorized: isPlatformAdministrator,
        preloadData: Localisation.detailsState.actions.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
    {
        path: '/localisation/countries-and-languages/:country',
        main: () => import('./ViewLocalisation'),
        isAuthorized: isPlatformAdministrator,
        preloadData: Localisation.detailsState.actions.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
];

export default routes;
