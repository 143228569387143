import * as React from 'react';

import { Route, isSuperUser, WebshopFaqCategoryLibrary, Localisation } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/webshops/faq-categories-library',
        main: () => import('./WebshopFaqCategoriesLibraryOverview'),
        isAuthorized: [isSuperUser],
        preloadData: WebshopFaqCategoryLibrary.overviewState.actions.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/webshops/faq-categories-library/new',
        main: () => import('./CreateWebshopFaqLibraryCategory'),
        isAuthorized: [isSuperUser],
        preloadData: Localisation.overviewState.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
    {
        path: '/webshops/faq-categories-library/:faqCategoryId',
        main: () => import('./UpdateWebshopFaqLibraryCategory'),
        isAuthorized: [isSuperUser],
        preloadData: WebshopFaqCategoryLibrary.updateState.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
];

export default routes;
