import * as React from 'react';

import { Route, isCompanyManager, isBranchManager, WebshopPromotion } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/webshops/promotions',
        main: () => import('./WebshopPromotionsLandingPage'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/webshops/promotions/product-selection',
        main: () => import('./WebshopProductSelectionPromotionsOverview'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopPromotion.productSelectionPromotionOverviewState.actions.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/webshops/promotions/cart',
        main: () => import('./WebshopCartPromotionsOverview'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopPromotion.cartPromotionOverviewState.actions.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/webshops/promotions/priorities',
        main: () => import('./ManageWebshopPromotionPriorities'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopPromotion.managePrioritiesState.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/webshops/promotions/:webshopId/:promotionType/new',
        main: () => import('./CreatePromotion'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopPromotion.createState.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
    {
        path: '/webshops/promotions/:webshopId/:promotionId',
        main: () => import('./UpdatePromotion'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopPromotion.updateState.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
];

export default routes;
