// src/Calendar.tsx
import React, { useState } from 'react';

import moment, { Moment } from 'moment';

import { Button } from '@/button';
import { Icon } from '@/icon';
import { Container, Row, Col } from '@/layout';
import { Select } from '@/select';

import { ICalendarEvent } from './CalendarEvent';
import DayView from './DayView';
import MonthlyView from './MonthlyView';
import WeeklyView from './WeeklyView';

import styles from './Calendar.scss';

interface CalendarProps {
    disabled?: boolean;
    initialViewMode?: 'month' | 'week' | 'day';
    initialDate?: Moment;
    hasMoreActions?: boolean;
    events: ICalendarEvent[];
    customFilter?: React.ReactNode;
    renderViewMoreAction?: (selectedDate: string) => JSX.Element | null;
    gettext: (text: string) => string;
    onPeriodChange?: (startDate: Moment, endDate: Moment) => Promise<void> | void;
}

const periodChangeHandlers = {
    day: (currentDate: Moment) => [currentDate.clone().startOf('day'), currentDate.clone().endOf('day')],
    week: (currentDate: Moment) => [currentDate.clone().startOf('isoWeek'), currentDate.clone().endOf('isoWeek')],
    month: (currentDate: Moment) => [
        currentDate.clone().startOf('month').startOf('isoWeek'),
        currentDate.clone().endOf('month').endOf('isoWeek'),
    ],
};
const CalendarViewer = ({
    initialViewMode = 'month',
    initialDate = moment(),
    events = [],
    customFilter,
    disabled,
    renderViewMoreAction,

    gettext,
    onPeriodChange,
}: CalendarProps) => {
    const [currentDate, setCurrentDate] = useState(initialDate);
    const [viewMode, setViewMode] = useState(initialViewMode);

    const handlePeriodChange = () => {
        if (onPeriodChange) {
            const [startDate, endDate] = periodChangeHandlers[viewMode](currentDate);
            onPeriodChange(startDate, endDate);
        }
    };

    React.useEffect(() => {
        if (currentDate && viewMode) {
            handlePeriodChange();
        }
    }, [currentDate, viewMode]);

    const previousPeriod = () => {
        setCurrentDate(currentDate.clone().subtract(1, viewMode));
    };

    const nextPeriod = () => {
        setCurrentDate(currentDate.clone().add(1, viewMode));
    };
    const handleTodayClick = () => {
        setCurrentDate(moment());
    };
    const handleViewModeChange = (value: string) => {
        setViewMode(value as 'month' | 'week' | 'day');
        setCurrentDate(moment());
    };
    return (
        <div
            className={`${styles.CalendarSchedule}`}
            data-test-id="calendar-schedule"
        >
            <Container
                gutter={14}
                fullWidth
            >
                <Row vAlign="center">
                    <Col>
                        <Button
                            variant="tertiary"
                            variantSize="s"
                            onClick={handleTodayClick}
                            disabled={disabled}
                        >
                            {gettext('Today')}
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            onClick={previousPeriod}
                            variant="plain"
                            variantSize="xxs"
                            disabled={disabled}
                            startIcon={<Icon type="action_left" />}
                        />
                    </Col>
                    <Col>
                        <Button
                            onClick={nextPeriod}
                            variant="plain"
                            variantSize="xxs"
                            disabled={disabled}
                            startIcon={<Icon type="action_right" />}
                        />
                    </Col>
                    <Col>
                        <span className={styles.HeaderCurrentViewDate}>
                            {currentDate.format(
                                viewMode === 'month' ? 'MMMM YYYY' : viewMode === 'week' ? 'MMMM DD, YYYY' : ' DD MMMM  YYYY',
                            )}
                        </span>
                    </Col>
                    {customFilter && <Col hAlign="end">{customFilter && customFilter}</Col>}
                    <Col hAlign="end">
                        <Select
                            disabled={disabled}
                            value={viewMode}
                            options={[
                                { label: gettext('Week'), value: 'week' },
                                { label: gettext('Day'), value: 'day' },
                                { label: gettext('Month'), value: 'month' },
                            ]}
                            onChange={handleViewModeChange}
                            data-test-id="calendar-schedule-view-select"
                        />
                    </Col>
                </Row>
            </Container>
            <div className={`${styles.CalendarBody} ${disabled ? styles.disabled : ''}`}>
                {viewMode === 'month' ? (
                    <MonthlyView
                        currentDate={currentDate}
                        events={events}
                        renderViewMoreAction={renderViewMoreAction}
                        gettext={gettext}
                        onDateClick={date => {
                            setCurrentDate(date);
                            setViewMode('day');
                        }}
                    />
                ) : viewMode === 'week' ? (
                    <WeeklyView
                        startDate={currentDate}
                        events={events}
                        gettext={gettext}
                    />
                ) : (
                    <DayView
                        currentDate={currentDate}
                        events={events}
                        gettext={gettext}
                    />
                )}
            </div>
        </div>
    );
};

export default CalendarViewer;
