import { Route } from 'data-store';

import availableProductsRoutes from './AvailableProducts/routes';
import customProductsRoutes from './CustomProducts/routes';
import productExportRoutes from './ProductExport/routes';
import productFeedbackRoutes from './ProductFeedback/routes';
import productImportRoutes from './ProductImport/routes';
import productSelectionRoutes from './ProductSelection/routes';

const routes: Route[] = [
    ...availableProductsRoutes,
    ...productFeedbackRoutes,
    ...customProductsRoutes,
    ...productSelectionRoutes,
    ...productImportRoutes,
    ...productExportRoutes,
];

export default routes;
