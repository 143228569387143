import { Domain } from 'api';
import { ISearchProvider } from 'utils';

import { integrationApi } from '@/api';

export type AllIntegrationsSearchProviderItem = Domain.AvailableIntegration & {
    value: Domain.Integration['availableIntegrationId'];
    label: Domain.Integration['name'];
};

export class AllIntegrationsSearchProvider implements ISearchProvider<AllIntegrationsSearchProviderItem> {
    private locale: Domain.Locale;
    private query: string;
    private perPage = 10;
    private pageSize = 10;
    private hasMoreResults = false;

    private searchCache: {
        [key: string]: Promise<Domain.AvailableIntegrationsPage>;
    } = {};

    private byValueCache: {
        [key: string]: Promise<Domain.AvailableIntegration>;
    } = {};

    resetCache() {
        this.searchCache = {};
        this.byValueCache = {};
    }

    reset() {
        this.resetCache();
    }

    setLocale(locale: Domain.Locale) {
        if (this.locale === locale) {
            return;
        }
        this.locale = locale;
        this.resetCache();
    }

    async search(query: string) {
        if (this.query !== query) {
            this.pageSize = this.perPage;
            this.hasMoreResults = true;
        }

        const cacheKey = query + '-' + this.pageSize + '-' + this.locale;

        if (!this.searchCache[cacheKey]) {
            this.searchCache[cacheKey] = integrationApi.GetAllAvailableIntegrations(
                { page: 1, size: this.pageSize },
                { field: 'name', direction: 'ascending' },
                query,
            );

            this.searchCache[cacheKey].then(cachedPage => {
                cachedPage.items.forEach(item => {
                    if (!this.byValueCache[item.availableIntegrationId]) {
                        this.byValueCache[item.availableIntegrationId] = Promise.resolve(item);
                    }
                });

                if (cachedPage.total > cachedPage.items.length) {
                    this.hasMoreResults = true;
                } else {
                    this.hasMoreResults = false;
                }
            });
        }

        this.query = query;

        const page = await this.searchCache[cacheKey];

        return page.items.map(this.mapItem);
    }

    async loadMoreResults() {
        if (this.hasMoreResults) {
            this.pageSize += this.perPage;
        }

        return await this.search(this.query);
    }

    // We do not have this implemented yet in this search provider
    getHasMoreResults() {
        return this.hasMoreResults;
    }

    async byValue(value: string) {
        if (!value) {
            return;
        }

        if (!this.byValueCache[value]) {
            this.byValueCache[value] = integrationApi.GetAvailableIntegrationDetail(value);
        }

        const item = await this.byValueCache[value];
        return this.mapItem(item);
    }

    async byValues(values: string[]) {
        return await Promise.all(values.filter(Boolean).map(value => this.byValue(value) as Promise<AllIntegrationsSearchProviderItem>));
    }

    private mapItem = (item: Domain.AvailableIntegration): AllIntegrationsSearchProviderItem => {
        return {
            ...item,
            value: item.availableIntegrationId,
            label: item.name,
        };
    };
}
