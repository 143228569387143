import * as React from 'react';

import { Route, isSuperUser, isCompanyManager, isBranchManager, DeviceOrder } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/vending-machines/orders',
        main: () => import('./VendingMachineOrdersOverview'),
        isAuthorized: [isSuperUser, isCompanyManager, isBranchManager],
        preloadData: DeviceOrder.overviewState.actions.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/vending-machines/orders/:deviceId/:orderId',
        main: () => import('./ViewOrder'),
        isAuthorized: [isSuperUser, isCompanyManager, isBranchManager],
        preloadData: DeviceOrder.detailsState.actions.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
];

export default routes;
