import * as React from 'react';

import { Route, isCompanyManager, isBranchManager, WebshopNewsArticle } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/webshops/news-articles',
        main: () => import('./WebshopNewsArticlesOverview'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopNewsArticle.overviewState.actions.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/webshops/news-articles/:webshopId/new',
        main: () => import('./CreateNewsArticle'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
    {
        path: '/webshops/news-articles/:webshopId/:articleId',
        main: () => import('./UpdateNewsArticle'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopNewsArticle.updateState.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
];

export default routes;
