import React from 'react';

import moment from 'moment';

import styles from './CalendarDayLabel.scss';

export const CalendarDayLabel = ({
    date,
    hideDayName,
    className,
    onDateClick,
}: {
    date: moment.Moment;
    hideDayName?: boolean;
    className?: string;
    onDateClick?: () => void;
}) => {
    return (
        <div className={`${styles.CalendarDayContainer} ${className ? className : ''}`}>
            {hideDayName ? null : <span>{date.format('ddd').toUpperCase()}</span>}
            <span
                className={`${styles.CalendarDayLabel} ${date.isSame(moment(), 'day') ? styles.isToday : ''} ${hideDayName ? styles.hideDayName : ''} ${onDateClick ? styles.clickable : ''}`}
                onClick={() => (onDateClick ? onDateClick() : null)}
            >
                {date.format('DD').toUpperCase()}
            </span>
        </div>
    );
};
