import * as React from 'react';

import { Preloader, Container, Grid, Row, Col } from 'ui-components';

export default function MainActions(props: { hideSearch?: boolean }) {
    return (
        <Grid>
            <Row>
                <Col span={4}>
                    {!props.hideSearch ? (
                        <Preloader
                            width={380}
                            height={52}
                        />
                    ) : null}
                </Col>

                <Col
                    span={2}
                    offset={6}
                >
                    <Container fullWidth={true}>
                        <Row justify="end">
                            <Col>
                                <Preloader
                                    primary={true}
                                    width={90}
                                    varyWidth={140}
                                    height={52}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Grid>
    );
}
