import * as React from 'react';

import { Route, isPlatformAdministrator, ApiKey } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/customers/api-keys',
        main: () => import('./ApiKeysOverview'),
        isAuthorized: isPlatformAdministrator,
        preloadData: ApiKey.overviewState.actions.load,
        preloading: () => <FullPage bodyType="data-table" />,
    },
    {
        path: '/customers/api-keys/new',
        main: () => import('./CreateApiKey'),
        isAuthorized: isPlatformAdministrator,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
    {
        path: '/customers/api-keys/:apiKeyId',
        main: () => import('./UpdateApiKey'),
        isAuthorized: isPlatformAdministrator,
        preloadData: ApiKey.updateState.actions.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
];

export default routes;
