import * as React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';

import { Tx } from 'ui-components';

import { MegaMenuItem } from './SidebarNavigation';

import styles from './SidebarNavigation.scss';

export default function MegaMenu(props: { menuItems: (MegaMenuItem | boolean)[]; close?: () => void; className?: string }) {
    const renderMegaMenuItem = (item: MegaMenuItem): React.ReactNode => {
        return (
            <div key={item.key}>
                <RouterNavLink
                    to={item.to}
                    onClick={props.close}
                >
                    {item.icon}
                    <Tx>{item.label}</Tx>
                    <Tx
                        as="div"
                        level="body-sm"
                    >
                        {item.description}
                    </Tx>
                </RouterNavLink>
            </div>
        );
    };

    return (
        <div className={`${styles.MegaMenu}  ${props.className || ''} ${props.menuItems.length > 1 ? styles.MegaMenuWide : ''}`}>
            {props.menuItems.map(item => (item !== true && item !== false ? renderMegaMenuItem(item) : null))}
        </div>
    );
}
