import { Route, anyAuthorization } from 'data-store';

import Error from './Error';
import NotFound from './NotFound';

const routes: Route[] = [
    {
        path: '/error/:error',
        main: async () => Error,
        isAuthorized: anyAuthorization,
    },
    {
        path: '*',
        main: async () => NotFound,
        isAuthorized: anyAuthorization,
    },
];

export default routes;
