import * as React from 'react';

import { Route, isCompanyManager, isBranchManager } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/vending-machines/pharmacist-on-duty-schedule',
        main: () => import('./UpdatePharmacistOnDutySchedule'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
];

export default routes;
