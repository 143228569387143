import { Domain } from 'api';
import { ISearchProvider } from 'utils';

import { slideshowApi } from '@/api';

type SlideshowSearchProviderItem = Domain.Slideshow & {
    value: Domain.Slideshow['slideshowId'];
    label: Domain.Slideshow['name'];
};

export class SlideshowSearchProvider implements ISearchProvider<SlideshowSearchProviderItem> {
    private ownership: Domain.Ownership;
    private includeShared: 'true' | undefined;
    private hasMoreResults = false;

    private baseByValueCache: {
        [key: string]: Domain.Slideshow;
    } = {};

    private searchCache: {
        [key: string]: Promise<Domain.SlideshowsPage>;
    } = {};

    private byValueCache: {
        [key: string]: Promise<Domain.Slideshow>;
    } = {};

    resetCache() {
        this.searchCache = {};
        this.byValueCache = {};
    }

    reset() {
        this.resetCache();
    }

    setIncludeShared(includeShared: 'true' | undefined) {
        this.includeShared = includeShared;
        this.resetCache();
    }

    setBaseByValueCache(cache: Domain.Slideshow[]) {
        this.baseByValueCache = {};

        for (const item of cache) {
            this.baseByValueCache[item.slideshowId] = item;
        }
    }

    setOwnership(ownership: Domain.Ownership) {
        this.ownership = ownership;
        this.resetCache();
    }

    async search(query: string) {
        if (!this.searchCache[query]) {
            this.searchCache[query] = slideshowApi.GetSlideshows(
                this.ownership,
                { page: 1, size: 10 },
                { field: 'name', direction: 'ascending' },
                query,
                { includeShared: this.includeShared },
            );

            this.searchCache[query].then(cachedSlideshowsPage => {
                cachedSlideshowsPage.items.forEach(slideshow => {
                    if (!this.byValueCache[slideshow.slideshowId]) {
                        this.byValueCache[slideshow.slideshowId] = Promise.resolve(slideshow);
                    }
                });
            });
        }
        const slideshowsPage = await this.searchCache[query];

        return slideshowsPage.items.map(this.mapSlideshow);
    }

    // we do not support loading more results
    async loadMoreResults() {
        return [];
    }

    getHasMoreResults() {
        return this.hasMoreResults;
    }

    async byValue(value: string) {
        if (!value) {
            return;
        }

        if (this.baseByValueCache[value]) {
            return this.mapSlideshow(this.baseByValueCache[value]);
        }

        if (!this.byValueCache[value]) {
            this.byValueCache[value] = slideshowApi.GetSlideshowDetails(value);
        }

        const slideshow = await this.byValueCache[value];
        return this.mapSlideshow(slideshow);
    }

    // we do not support multi-selects
    async byValues(_values: string[]) {
        return [];
    }

    private mapSlideshow = (slideshow: Domain.Slideshow): SlideshowSearchProviderItem => {
        return {
            ...slideshow,
            value: slideshow.slideshowId,
            label: slideshow.name,
        };
    };
}
