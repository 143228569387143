import * as React from 'react';

import { useGettext } from 'data-store';
import { Container, LinkButton, Tx } from 'ui-components';

import LandingPage from '@/Layouts/LandingPage';

export default function NotFound() {
    const { gettext } = useGettext();

    return (
        <div className="MainNavigation hidden-overflow-sm">
            <Container
                fullWidth={true}
                limitedWidth={true}
            >
                <LandingPage
                    title={gettext('Oops! Page not found')}
                    description={gettext('We tried really hard, but couldn’t found the page you were looking for.')}
                >
                    <Tx as="p">
                        <LinkButton to="/">{gettext('Go to platform')}</LinkButton>
                    </Tx>
                </LandingPage>
            </Container>
        </div>
    );
}
