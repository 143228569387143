import React from 'react';

import { Pill, IconType } from '..';
import { ChipColor, ChipType } from '../pill/Pill';

export type StatusType = 'pending' | 'success' | 'failed' | 'canceled' | 'payment' | 'incomplete' | 'custom';
type StatusProps = {
    label: string;
    prefix?: React.ReactNode;
    type?: StatusType;
    variant?: ChipType;
    small?: boolean;
    onDelete?: React.MouseEventHandler<HTMLSpanElement>;
    iconType?: IconType;
    color?: ChipColor;
    className?: string;
};

export default function Status({ type, prefix, label, variant, small, iconType, color, className, onDelete }: StatusProps) {
    let backgroundColor: ChipColor | undefined;
    let startIconType: IconType | undefined;
    switch (type) {
        case 'pending':
            backgroundColor = 'primary';
            startIconType = 'status_pending';
            break;
        case 'success':
            backgroundColor = 'success';
            startIconType = 'status_success';
            break;
        case 'failed':
            backgroundColor = 'error';
            startIconType = 'status_failed';
            break;
        case 'canceled':
            backgroundColor = 'secondary';
            startIconType = 'status_canceled';
            break;
        case 'payment':
            backgroundColor = 's1';
            startIconType = 'status_unpaid';
            break;
        case 'incomplete':
            backgroundColor = 's1';
            startIconType = 'status_incomplete';
            break;
        default:
            break;
    }
    return (
        <Pill
            size={small ? 'sm' : 'md'}
            color={color || backgroundColor}
            variant={variant}
            startIconType={iconType || startIconType}
            onDelete={onDelete}
            className={className}
        >
            {prefix}
            {label}
        </Pill>
    );
}
