import * as React from 'react';

import { Domain } from 'api';
import { ISearchProvider } from 'utils';

import { webshopLibraryNewsArticleApi } from '@/api';

type WebshopLibraryNewsArticleSearchProviderItem = {
    value: Domain.WebshopLibraryNewsArticle['articleId'];
    label: React.ReactNode;
} & Domain.WebshopLibraryNewsArticle;

interface Filters {
    activeStatus?: string;
}

type LabelRenderer = (article: Domain.WebshopLibraryNewsArticle) => React.ReactNode;

export class WebshopLibraryNewsArticleSearchProvider implements ISearchProvider<WebshopLibraryNewsArticleSearchProviderItem> {
    private locale: Domain.Locale;
    private filters: Filters;
    private labelRenderer: LabelRenderer = article => article.articleId;
    private hasMoreResults = false;

    private baseByValueCache: {
        [key: string]: Domain.WebshopLibraryNewsArticle;
    } = {};

    private searchCache: {
        [key: string]: Promise<Domain.WebshopLibraryNewsArticlesPage>;
    } = {};

    private byValueCache: {
        [key: string]: Promise<Domain.WebshopLibraryNewsArticle>;
    } = {};

    resetCache() {
        this.searchCache = {};
        this.byValueCache = {};
    }

    reset() {
        this.resetCache();
    }

    setLocale(locale: Domain.Locale) {
        this.locale = locale;
        this.resetCache();
    }

    setFilters(filters: Filters) {
        this.filters = filters;
        this.resetCache();
    }

    setLabelRenderer(labelRenderer: LabelRenderer) {
        this.labelRenderer = labelRenderer;
    }

    async search(query: string) {
        if (!this.searchCache[query]) {
            this.searchCache[query] = webshopLibraryNewsArticleApi.GetWebshopLibraryNewsArticles(
                { page: 1, size: 10 },
                { field: 'name', direction: 'ascending' },
                this.locale,
                query,
                this.filters,
            );

            this.searchCache[query].then(cachedPage => {
                cachedPage.items.forEach(item => {
                    if (!this.byValueCache[item.articleId]) {
                        this.byValueCache[item.articleId] = Promise.resolve(item);
                    }
                });
            });
        }
        const page = await this.searchCache[query];

        return page.items.map(this.mapNewsArticle);
    }

    // we do not support loading more results
    async loadMoreResults() {
        return [];
    }

    // We do not have this implemented yet in this search provider
    getHasMoreResults() {
        return this.hasMoreResults;
    }

    async byValue(value: string) {
        if (!value) {
            return;
        }

        if (this.baseByValueCache[value]) {
            return this.mapNewsArticle(this.baseByValueCache[value]);
        }

        if (!this.byValueCache[value]) {
            this.byValueCache[value] = webshopLibraryNewsArticleApi.GetWebshopLibraryNewsArticleDetails(value);
        }

        const device = await this.byValueCache[value];
        return this.mapNewsArticle(device);
    }

    async byValues(values: string[]) {
        return await Promise.all(
            values.filter(Boolean).map(value => this.byValue(value) as Promise<WebshopLibraryNewsArticleSearchProviderItem>),
        );
    }

    private mapNewsArticle = (article: Domain.WebshopLibraryNewsArticle): WebshopLibraryNewsArticleSearchProviderItem => {
        return {
            value: article.articleId,
            label: this.labelRenderer(article),
            ...article,
        };
    };
}
