import * as React from 'react';

import { Route, isCompanyManager, isBranchManager, isPlatformAdministrator, isSupportAgent, ProductFeedback } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/products/product-feedback',
        main: () => import('./ProductFeedbackOverview'),
        isAuthorized: [isCompanyManager, isBranchManager, isPlatformAdministrator, isSupportAgent],
        preloadData: ProductFeedback.overviewState.actions.load,
        preloading: () => (
            <FullPage
                bodyType="data-table"
                hideMainSearch={true}
                hideMainActions={true}
            />
        ),
    },
];

export default routes;
