import * as React from 'react';
import { useSelector } from 'react-redux';

import { Domain } from 'api';
import { useGettext, useLocalize, I18n, selectLoggedInUser } from 'data-store';
import { Tooltip, Tx } from 'ui-components';
import { formateDateTime, localizeWithFallback } from 'util-components';

export default function NotificationMessage(props: { notification: Domain.Notification; isLight?: boolean; hasTooltip?: boolean }) {
    const localize = useLocalize();
    const { gettext } = useGettext();
    const locale = useSelector(I18n.selectCurrentLocale);
    let message = '';
    const loggedInUser = useSelector(selectLoggedInUser);
    const branchTimezone = loggedInUser?.branchTimezone;
    if (props.notification.notificationType === 'orderWasCreated') {
        message = gettext('A new order (:external_reference_id:) has been created on branch :branch_name: by :customer_name:')
            .replace(':branch_name:', props.notification.parameters['%_BRANCH_NAME_%'])
            .replace(
                ':external_reference_id:',
                props.notification.parameters['%_ORDER_NUMBER_%'] ||
                    props.notification.parameters['%_EXTERNAL_REFERENCE_ID_%'] ||
                    props.notification.parameters['%_ORDER_ID_%'],
            )
            .replace(
                ':customer_name:',
                props.notification.parameters['%_CUSTOMER_NAME_%']
                    ? props.notification.parameters['%_CUSTOMER_NAME_%']
                    : gettext('vending machine'),
            );
    } else if (props.notification.notificationType === 'orderWasBackOrdered') {
        message = gettext('Order :external_reference_id: was backordered on branch :branch_name:, company: :company_name:')
            .replace(':branch_name:', props.notification.parameters['%_BRANCH_NAME_%'])
            .replace(':company_name:', props.notification.parameters['%_COMPANY_NAME_%'])
            .replace(
                ':external_reference_id:',
                props.notification.parameters['%_ORDER_NUMBER_%'] ||
                    props.notification.parameters['%_EXTERNAL_REFERENCE_ID_%'] ||
                    props.notification.parameters['%_ORDER_ID_%'],
            );
    } else if (props.notification.notificationType === 'configuredIntegrationWasActivated') {
        message = gettext('Integration :integration_name: has been activated on branch :branch_name:')
            .replace(':integration_name:', props.notification.parameters['%_INTEGRATION_NAME_%'])
            .replace(':branch_name:', props.notification.parameters['%_BRANCH_NAME_%']);
    } else if (props.notification.notificationType === 'configuredIntegrationWasDeactivated') {
        message = gettext('Integration :integration_name: has been deactivated on branch :branch_name:, company :company_name:')
            .replace(':integration_name:', props.notification.parameters['%_INTEGRATION_NAME_%'])
            .replace(':branch_name:', props.notification.parameters['%_BRANCH_NAME_%'])
            .replace(':company_name:', props.notification.parameters['%_COMPANY_NAME_%']);
    } else if (props.notification.notificationType === 'configuredIntegrationWasUpdated') {
        message = gettext('Integration :integration_name: has been updated on branch :branch_name:, company :company_name:')
            .replace(':integration_name:', props.notification.parameters['%_INTEGRATION_NAME_%'])
            .replace(':branch_name:', props.notification.parameters['%_BRANCH_NAME_%'])
            .replace(':company_name:', props.notification.parameters['%_COMPANY_NAME_%']);
    } else if (props.notification.notificationType === 'scheduleWasUpdated') {
        message = gettext('Schedule :schedule_name: has been updated by user :user_name: on branch :branch_name:, company :company_name:')
            .replace(':schedule_name:', props.notification.parameters['%_SCHEDULE_NAME_%'])
            .replace(':user_name:', props.notification.parameters['%_USER_NAME_%'])
            .replace(':branch_name:', props.notification.parameters['%_BRANCH_NAME_%'])
            .replace(':company_name:', props.notification.parameters['%_COMPANY_NAME_%']);
    } else if (props.notification.notificationType === 'branchSlideshowWasUpdated') {
        message = gettext('Slideshow :slideshow_name: has been updated by user :user_name: on branch :branch_name:, company :company_name:')
            .replace(':slideshow_name:', props.notification.parameters['%_SLIDESHOW_NAME_%'])
            .replace(':user_name:', props.notification.parameters['%_USER_NAME_%'])
            .replace(':branch_name:', props.notification.parameters['%_BRANCH_NAME_%'])
            .replace(':company_name:', props.notification.parameters['%_COMPANY_NAME_%']);
    } else if (props.notification.notificationType === 'companySlideshowWasUpdated') {
        message = gettext('Slideshow :slideshow_name: has been updated by user :user_name: on company :company_name:')
            .replace(':slideshow_name:', props.notification.parameters['%_SLIDESHOW_NAME_%'])
            .replace(':user_name:', props.notification.parameters['%_USER_NAME_%'])
            .replace(':company_name:', props.notification.parameters['%_COMPANY_NAME_%']);
    } else if (props.notification.notificationType === 'branchCustomPageContentWasUpdated') {
        message = gettext(
            'Custom page :custom_page_name: has been updated by user :user_name: on company :company_name:, branch: :branch_name:',
        )
            .replace(':custom_page_name:', props.notification.parameters['%_CUSTOM_PAGE_NAME_%'])
            .replace(':user_name:', props.notification.parameters['%_USER_NAME_%'])
            .replace(':company_name:', props.notification.parameters['%_COMPANY_NAME_%'])
            .replace(':branch_name:', props.notification.parameters['%_BRANCH_NAME_%']);
    } else if (props.notification.notificationType === 'companyCustomPageContentWasUpdated') {
        message = gettext('Custom page :custom_page_name: has been updated by user :user_name: on company :company_name:')
            .replace(':custom_page_name:', props.notification.parameters['%_CUSTOM_PAGE_NAME_%'])
            .replace(':user_name:', props.notification.parameters['%_USER_NAME_%'])
            .replace(':company_name:', props.notification.parameters['%_COMPANY_NAME_%']);
    } else if (props.notification.notificationType === 'customPageWasShared') {
        message = gettext(':count: new custom pages were shared with you.').replace(
            ':count:',
            props.notification.parameters['%_COUNT_%'].toString(),
        );
    } else if (props.notification.notificationType === 'branchProductWallContentWasUpdated') {
        message = gettext(
            'Product wall :product_wall_name: has been updated by user :user_name: on company :company_name:, branch: :branch_name:',
        )
            .replace(':product_wall_name:', props.notification.parameters['%_PRODUCT_WALL_NAME_%'])
            .replace(':user_name:', props.notification.parameters['%_USER_NAME_%'])
            .replace(':company_name:', props.notification.parameters['%_COMPANY_NAME_%'])
            .replace(':branch_name:', props.notification.parameters['%_BRANCH_NAME_%']);
    } else if (props.notification.notificationType === 'companyProductWallContentWasUpdated') {
        message = gettext('Product wall :product_wall_name: has been updated by user :user_name: on company :company_name:')
            .replace(':product_wall_name:', props.notification.parameters['%_PRODUCT_WALL_NAME_%'])
            .replace(':user_name:', props.notification.parameters['%_USER_NAME_%'])
            .replace(':company_name:', props.notification.parameters['%_COMPANY_NAME_%']);
    } else if (props.notification.notificationType === 'productsOutOfStock') {
        message = gettext(
            ':count: products went out of stock in stocksource :stock_source:, stock type : :stock_type:, company: :company_name:',
        )
            .replace(':stock_source:', props.notification.parameters['%_STOCK_SOURCE_%'])
            .replace(':stock_type:', props.notification.parameters['%_STOCK_TYPE_%'])
            .replace(':company_name:', props.notification.parameters['%_COMPANY_NAME_%'])
            .replace(':count:', props.notification.parameters['%_COUNT_%'].toString());
    } else if (props.notification.notificationType === 'productsBackInStock') {
        message = gettext(
            ':count: products are back in stock in stocksource :stock_source:, stock type : :stock_type:, company: :company_name:',
        )
            .replace(':stock_source:', props.notification.parameters['%_STOCK_SOURCE_%'])
            .replace(':stock_type:', props.notification.parameters['%_STOCK_TYPE_%'])
            .replace(':company_name:', props.notification.parameters['%_COMPANY_NAME_%'])
            .replace(':count:', props.notification.parameters['%_COUNT_%'].toString());
    } else if (props.notification.notificationType === 'importedPriceWasUpdated') {
        message = gettext('New prices imported by integration :integration_name: on branch :branch_name:, company: :company_name:')
            .replace(':integration_name:', props.notification.parameters['%_INTEGRATION_NAME_%'])
            .replace(':branch_name:', props.notification.parameters['%_BRANCH_NAME_%'])
            .replace(':company_name:', props.notification.parameters['%_COMPANY_NAME_%']);
    } else if (props.notification.notificationType === 'orderExportToIntegrationDataWasUpdated') {
        message = gettext(
            'Order :order_number: was exported to integration :integration_name: on branch :branch_name:, company: :company_name:',
        )
            .replace(
                ':order_number:',
                props.notification.parameters['%_ORDER_NUMBER_%'] ||
                    props.notification.parameters['%_EXTERNAL_REFERENCE_ID_%'] ||
                    props.notification.parameters['%_ORDER_ID_%'],
            )
            .replace(':integration_name:', props.notification.parameters['%_INTEGRATION_NAME_%'])
            .replace(':branch_name:', props.notification.parameters['%_BRANCH_NAME_%'])
            .replace(':company_name:', props.notification.parameters['%_COMPANY_NAME_%']);
    } else if (props.notification.notificationType === 'webshopWasUpdated') {
        message = gettext('Webshop :webshop_name: was updated by :user_name: on branch :branch_name:, company: :company_name:')
            .replace(':webshop_name:', props.notification.parameters['%_WEBSHOP_NAME_%'])
            .replace(':company_name:', props.notification.parameters['%_COMPANY_NAME_%'])
            .replace(':branch_name:', props.notification.parameters['%_BRANCH_NAME_%'])
            .replace(':user_name:', props.notification.parameters['%_USER_NAME_%']);
    } else if (props.notification.notificationType === 'webshopConfigWasUpdated') {
        message = gettext(
            'Webshop :webshop_name: configuration was updated by :user_name: on branch :branch_name:, company: :company_name:',
        )
            .replace(':webshop_name:', props.notification.parameters['%_WEBSHOP_NAME_%'])
            .replace(':company_name:', props.notification.parameters['%_COMPANY_NAME_%'])
            .replace(':branch_name:', props.notification.parameters['%_BRANCH_NAME_%'])
            .replace(':user_name:', props.notification.parameters['%_USER_NAME_%']);
    } else if (props.notification.notificationType === 'availableProductWasUpdated') {
        message = gettext(':count: products were updated from source :product_source:.')
            .replace(':product_source:', props.notification.parameters['%_PRODUCT_SOURCE_%'])
            .replace(':count:', props.notification.parameters['%_COUNT_%'].toString());
    } else if (props.notification.notificationType === 'productSelectionStatusWasUpdated') {
        message = gettext('Product selection was updated on branch: :branch_name:').replace(
            ':branch_name:',
            props.notification.parameters['%_BRANCH_NAME_%'],
        );
    } else if (props.notification.notificationType === 'sharingGroupCompanyWasInvited') {
        message = gettext('You have been invited to a sharing group by :sender_user:, company: :sender_company:')
            .replace(':sender_user:', props.notification.parameters['%_SENDER_USER_%'])
            .replace(':sender_company:', props.notification.parameters['%_SENDER_COMPANY_%']);
    } else if (props.notification.notificationType === 'productWallWasShared') {
        message = gettext(':count: new product walls were shared with you.').replace(
            ':count:',
            props.notification.parameters['%_COUNT_%'].toString(),
        );
    } else if (props.notification.notificationType === 'slideshowWasShared') {
        message = gettext(':count: new slideshows were shared with you.').replace(
            ':count:',
            props.notification.parameters['%_COUNT_%'].toString(),
        );
    } else if (props.notification.notificationType === 'orderBackOrderStatusWasUpdated') {
        message = gettext(
            'Backorder :external_reference_id: for order :order_d:  has changed status to :backorder_status: on branch :branch_name:, company: :company_name:',
        )
            .replace(':branch_name:', props.notification.parameters['%_BRANCH_NAME_%'])
            .replace(':company_name:', props.notification.parameters['%_COMPANY_NAME_%'])
            .replace(':external_reference_id:', props.notification.parameters['%_EXTERNAL_REFERENCE_ID_%'])
            .replace(':order_d:', props.notification.parameters['%_ORDER_NUMBER_%'] || props.notification.parameters['%_ORDER_ID_%'])
            .replace(':backorder_status:', props.notification.parameters['%_BACK_ORDER_STATUS_%']);
    } else if (props.notification.notificationType === 'vendingMachineHomeScreenProductWasRemoved') {
        message = gettext(
            'Home screen products for vending machine :device_name: are no longer available: :product_names: on branch :branch_name:, company: :company_name:',
        )
            .replace(':branch_name:', props.notification.parameters['%_BRANCH_NAME_%'])
            .replace(':company_name:', props.notification.parameters['%_COMPANY_NAME_%'])
            .replace(':device_name:', props.notification.parameters['%_DEVICE_NAME_%'])
            .replace(
                ':product_names:',
                props.notification.parameters['%_PRODUCT_NAMES_%']
                    .map(productName =>
                        localizeWithFallback({
                            value: productName,
                            localize,
                            toLocale: locale,
                            fallback: true,
                            addFallbackLangPrefix: false,
                            plaintext: true,
                        }),
                    )
                    .join(', '),
            );
    } else if (props.notification.notificationType === 'shipmentServiceWebhookDataWasUpdated') {
        message = gettext(
            'New information for shipment :external_reference_id:, status :status: on branch :branch_name:, company: :company_name:',
        )
            .replace(':branch_name:', props.notification.parameters['%_BRANCH_NAME_%'])
            .replace(':company_name:', props.notification.parameters['%_COMPANY_NAME_%'])
            .replace(':external_reference_id:', props.notification.parameters['%_EXTERNAL_REFERENCE_ID_%'])
            .replace(':status:', props.notification.parameters['%_EXTERNAL_STATUS_%']);
    } else if (props.notification.notificationType === 'invoiceWasNumbered') {
        message = gettext('Invoice :invoice_number: was numbered, on branch :branch_name:, company: :company_name:')
            .replace(':branch_name:', props.notification.parameters['%_BRANCH_NAME_%'])
            .replace(':company_name:', props.notification.parameters['%_COMPANY_NAME_%'])
            .replace(':invoice_number:', props.notification.parameters['%_INVOICE_NUMBER_%']);
    } else if (props.notification.notificationType === 'libraryArticleWasCreated') {
        message = gettext('A new News Library Article was added: :article_title:').replace(
            ':article_title:',
            localizeWithFallback({
                value: props.notification.parameters['%_ARTICLE_TITLE_%'],
                localize,
                toLocale: locale,
                fallback: true,
                addFallbackLangPrefix: false,
                plaintext: true,
            }) as string,
        );
    } else if (props.notification.notificationType === 'reservationAddToRobotFailed') {
        message = gettext('A reservation could not be added to the Robot');
    } else if (
        props.notification.notificationType === 'exportWasCompleted' ||
        props.notification.notificationType === 'productExportWasCompleted'
    ) {
        message = gettext('Export :name: is ready to be downloaded').replace(':name:', props.notification.parameters['%_NAME_%']);
    } else if (props.notification.notificationType === 'prescriptionWasUploaded') {
        message = gettext(':name: has uploaded a new subscription with id: :prescription_id:.')
            .replace(':name:', props.notification.parameters['%_NAME_%'])
            .replace(':prescription_id:', props.notification.parameters['%_PRESCRIPTION_ID_%']);
    } else if (props.notification.notificationType === 'userWasSubscribedToNewsletter') {
        message = gettext(':count: user(s) has/have subscribed to your newsletter.').replace(
            ':count:',
            props.notification.parameters['%_COUNT_%'].toString(),
        );
    } else if (props.notification.notificationType === 'orderRequiresPrescriptionReview') {
        message = gettext('Order :order_id: on branch :branch_name: and company :company_name: requires a prescription review.')
            .replace(':order_id:', props.notification.parameters['%_ORDER_ID_%'])
            .replace(':company_name:', props.notification.parameters['%_COMPANY_NAME_%'])
            .replace(':branch_name:', props.notification.parameters['%_BRANCH_NAME_%']);
    } else if (props.notification.notificationType === 'mediaWasShared') {
        message = gettext(':count: media items were shared with you.').replace(
            ':count:',
            props.notification.parameters['%_COUNT_%'].toString(),
        );
    } else if (props.notification.notificationType === 'lockerLeftUnlocked') {
        message = gettext('A Locker on MT.Collect :device_name: has been left unlocked.').replace(
            ':device_name:',
            props.notification.parameters['%_DEVICE_NAME_%'],
        );
    } else if (props.notification.notificationType === 'productStockSubscriptionWasAdded') {
        message = gettext(
            'A stock subscription for product :product_name: has been added for :email_address: on :company_name:, :branch_name:.',
        )
            .replace(
                ':product_name:',
                localizeWithFallback({
                    value: props.notification.parameters['%_PRODUCT_NAME_%'],
                    localize,
                    toLocale: locale,
                    fallback: true,
                    addFallbackLangPrefix: false,
                    plaintext: true,
                }) as string,
            )

            .replace(':email_address:', props.notification.parameters['%_EMAIL_ADDRESS_%'])
            .replace(':company_name:', props.notification.parameters['%_COMPANY_NAME_%'])
            .replace(':branch_name:', props.notification.parameters['%_BRANCH_NAME_%']);
    } else if (props.notification.notificationType === 'robotEmergencyStopWasTriggered') {
        message = gettext('The emergency circuit was interrupted on your Robot. Please press the blue buttons.');
    } else if (props.notification.notificationType === 'appointmentWasCanceled') {
        const type = props.notification.parameters['%_TYPE_%'];
        const localizedValue = localizeWithFallback({
            value: type,
            localize,
            toLocale: locale,
            fallback: true,
            addFallbackLangPrefix: false,
            plaintext: true,
        }) as string;

        const formatedTime = formateDateTime({
            date: props.notification.parameters['%_STARTS_AT_%'],
            timezone: branchTimezone,
            showDate: true,
            showTime: true,
            formatDate: 'DD-MM-YYYY',
            formatTime: 'HH:mm',
        });

        message = gettext('Appointment for :type: on :starts_at: with patient :patient_name: was cancelled.')
            .replace(':appointment_id:', props.notification.parameters['%_APPOINTMENT_ID_%'])
            .replace(':type:', localizedValue)
            .replace(':starts_at:', formatedTime)
            .replace(':patient_name:', props.notification.parameters['%_PATIENT_NAME_%']);
    } else if (props.notification.notificationType === 'appointmentWasCreated') {
        const type = props.notification.parameters['%_TYPE_%'];
        const localizedValue = localizeWithFallback({
            value: type,
            localize,
            toLocale: locale,
            fallback: true,
            addFallbackLangPrefix: false,
            plaintext: true,
        }) as string;
        const formatedTime = formateDateTime({
            date: props.notification.parameters['%_STARTS_AT_%'],
            timezone: branchTimezone,
            showDate: true,
            showTime: true,
            formatDate: 'DD-MM-YYYY',
            formatTime: 'HH:mm',
        });

        message = gettext('A new appointment for :type: was scheduled at :starts_at: with patient :patient_name:')
            .replace(':appointment_id:', props.notification.parameters['%_APPOINTMENT_ID_%'])
            .replace(':type:', localizedValue)
            .replace(':starts_at:', formatedTime)
            .replace(':patient_name:', props.notification.parameters['%_PATIENT_NAME_%']);
    } else if (props.notification.notificationType === 'lockerHasProductsThatCannotBeSold') {
        message = gettext('Locker :locker_id: contains a product that cannot be sold').replace(
            ':locker_id:',
            props.notification.parameters['%_LOCKER_ID_%'],
        );
    }

    return (
        <Tooltip
            text={message}
            disabled={!props.hasTooltip}
        >
            <Tx
                level="body-md"
                sx={{
                    weight: props.isLight ? 'regular' : 'medium',
                }}
            >
                {message}
            </Tx>
        </Tooltip>
    );
}
