import * as React from 'react';

import { Route, isCompanyManager, isBranchManager, WebshopPage } from 'data-store';

import FullPage from '@/Layouts/Preloaders/FullPage';

const routes: Route[] = [
    {
        path: '/webshops/pages',
        main: () => import('./WebshopPagesOverview'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopPage.overviewState.actions.load,
        preloading: () => <FullPage hideMainSearch={true} />,
    },
    {
        path: '/webshops/pages/new/:locale',
        main: () => import('./CreatePage'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopPage.createState.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
    {
        path: '/webshops/pages/:webshopId/:pageId/:locale',
        main: () => import('./UpdatePage'),
        isAuthorized: [isCompanyManager, isBranchManager],
        preloadData: WebshopPage.updateState.load,
        preloading: () => (
            <FullPage
                hideMainSearch={true}
                bodyType="form"
            />
        ),
    },
];

export default routes;
