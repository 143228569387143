import * as React from 'react';

import { Domain } from 'api';
import { ISearchProvider } from 'utils';

import { integrationApi } from '@/api';

export type ConfiguredIntegrationsSearchProviderItem = Domain.Integration & {
    value: Domain.Integration['configuredIntegrationId'];
    label: React.ReactNode;
};

type LabelRenderer = (value: Domain.Integration) => React.ReactNode;

interface Filters {
    branchId?: string;
    companyId?: string;
    integrationType?: Domain.IntegrationCapability;
}

export class ConfiguredIntegrationsSearchProvider implements ISearchProvider<ConfiguredIntegrationsSearchProviderItem> {
    private ownership: Domain.Ownership;
    private filters: Filters;
    private labelRenderer: LabelRenderer = x => x.name;

    private searchCache: {
        [key: string]: Promise<Domain.ConfiguredIntegrationsPage>;
    } = {};

    private byValueCache: {
        [key: string]: Promise<Domain.Integration>;
    } = {};

    resetCache() {
        this.searchCache = {};
        this.byValueCache = {};
    }

    reset() {
        this.resetCache();
    }

    setLabelRenderer(labelRenderer: LabelRenderer) {
        this.labelRenderer = labelRenderer;
    }

    setOwnership(ownership: Domain.Ownership) {
        if (JSON.stringify(ownership) === JSON.stringify(this.ownership)) {
            return;
        }
        this.ownership = ownership;
        this.resetCache();
    }

    setFilters(filters: Filters) {
        if (JSON.stringify(filters) === JSON.stringify(this.filters)) {
            return;
        }
        this.filters = filters;
        this.resetCache();
    }

    async search(query: string) {
        const cacheKey = query + JSON.stringify(this.ownership) + JSON.stringify(this.filters);

        if (!this.searchCache[cacheKey]) {
            this.searchCache[cacheKey] = integrationApi.GetConfiguredIntegrations(
                this.ownership,
                { page: 1, size: 999 },
                { field: 'name', direction: 'ascending' },
                query,
                this.filters,
            );

            this.searchCache[cacheKey].then(cachedPage => {
                cachedPage.items.forEach(item => {
                    if (!this.byValueCache[item.configuredIntegrationId]) {
                        this.byValueCache[item.configuredIntegrationId] = Promise.resolve(item);
                    }
                });
            });
        }

        const page = await this.searchCache[cacheKey];
        return page.items.map(this.mapItem);
    }

    async byValue(value: string) {
        if (!value) {
            return;
        }

        if (!this.byValueCache[value]) {
            this.byValueCache[value] = integrationApi.GetIntegrationDetail(value);
        }

        return this.mapItem(await this.byValueCache[value]);
    }

    async byValues(values: string[]) {
        return await Promise.all(
            values.filter(Boolean).map(value => this.byValue(value) as Promise<ConfiguredIntegrationsSearchProviderItem>),
        );
    }

    async loadMoreResults() {
        // not implemented
        return [];
    }

    getHasMoreResults() {
        // not implemented
        return false;
    }

    private mapItem = (item: Domain.Integration): ConfiguredIntegrationsSearchProviderItem => {
        return {
            ...item,
            value: item.configuredIntegrationId,
            label: this.labelRenderer(item),
        };
    };
}
